import { LeftOutlined } from "@ant-design/icons"
import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { useChatContext } from "../../.."

export interface ChatHeaderProps {
  title?: string
  extra: React.ReactNode
  showBack?: boolean
  isGuest?: boolean
  updateCurrentUserProfile?: (user: any) => void
  updateChatHeaderHeight?: (height: number) => void
  clickableTitle?: React.ReactNode
}

const Wrapper = styled.div`
  padding: 8px 0 8px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #afafaf;
  & .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    @media (max-width: 460px) {
      flex-direction: column;
      .extra-wrapper {
        margin-top: -8px;
        margin-bottom: 8px;
      }
    }
    .anticon {
      margin-right: 10px;
      cursor: pointer;
    }
    h1 {
      font-size: 16px;
      font-weight: 700;
      margin: 11px 0;
      flex-grow: 1;
      cursor: pointer;
    }
  }
`
const ChatHeader: React.FC<ChatHeaderProps> = ({
  title,
  clickableTitle,
  extra,
  showBack,
  isGuest,
  updateCurrentUserProfile,
  updateChatHeaderHeight,
}) => {
  const { state, dispatch, actions } = useChatContext()
  const leaveRoom = () => {
    dispatch(actions.setCurrentRoomId(null))
  }
  const showProfile = () => {
    !isGuest &&
      updateCurrentUserProfile({ ...state.rooms[state.currentRoomId]?.guest })
  }
  const chatheaderWrapperRef = useRef(null)
  useEffect(() => {
    updateChatHeaderHeight &&
      updateChatHeaderHeight(chatheaderWrapperRef?.current?.clientHeight)
  }, [chatheaderWrapperRef?.current?.clientHeight])
  return (
    <Wrapper ref={chatheaderWrapperRef}>
      <div className="container">
        {showBack && <LeftOutlined onClick={leaveRoom} />}
        {clickableTitle ? (
          <h1>{clickableTitle}</h1>
        ) : (
          <h1 onClick={showProfile}>{title}</h1>
        )}
        <div className="extra-wrapper">{extra}</div>
      </div>
    </Wrapper>
  )
}

export { ChatHeader }
