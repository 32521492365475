import { API } from "../src"

export interface IReturnJobSeekerList {
  count?: number
  data?: any
}

interface ISearchJobSeekerQuery {
  page: string
  size: string
  keyword: string
  status?: string
  agent_member_id?: string
  all?: number
  job_id: string
}

export const fetchJobSeeker = async ({
  queryKey,
}): Promise<IReturnJobSeekerList> => {
  const params = {} as ISearchJobSeekerQuery
  params.page = queryKey[1].page
  params.size = queryKey[1].size
  params.keyword = queryKey[1].keyword
  params.agent_member_id = queryKey[1].agentMemberId
  params.all = queryKey[1].all
  params.job_id = queryKey[1].job_id
  return API.get(`/applicants/`, { params })
}

export const referJobSeeker = async (values): Promise<any> => {
  return API.post(`/job-applicants`, values)
}

export const fetchJobSeekerById = async (id): Promise<any> => {
  return API.get(`/applicants/${id}`)
}
