const typographyH1 = {
  fontWeight: "bold",
  fontSize: "44px",
  lineHeight: "73px",
  letterSpacing: "0.04em",
  fontFamily: "Roboto",
}

const typographyMap = {
  fontWeight: "bold",
  fontSize: "14px",
  lineHeight: "30px",
  letterSpacing: "0.02em",
  fontFamily: "Roboto,'Noto Sans JP'",
}

const typographyH2 = {
  fontWeight: 900,
  fontSize: 30,
  lineHeight: "38px",
  letterSpacing: "0.04em",
  fontFamily: "Roboto",
}

const typographyH2EN = {
  fontWeight: 900,
  fontSize: 18,
  lineHeight: "16px",
  letterSpacing: "0.05em",
  fontFamily: "Roboto",
}

const typographyH3 = {
  fontSize: "20px",
  fontWeight: "bold",
  lineHeight: "15px",
  letterSpacing: "0.04em",
  fontFamily: "Roboto",
}

const typographyPrimary = {
  fontSize: 16,
  lineHeight: "30px",
  letterSpacing: "0.03em",
  fontFamily: "Roboto",
}
const typographyPrimaryBold = {
  fontSize: 16,
  lineHeight: "30px",
  fontWeight: "bold",
  letterSpacing: "0.03 em",
  fontFamily: "Roboto",
}

const typographySecondary = {
  fontSize: 14,
  lineHeight: "20px",
  letterSpacing: "0.03em",
  fontFamily: "Roboto",
}

const typographySecondaryBold = {
  fontSize: 14,
  fontWeight: "bold",
  lineHeight: "20px",
  letterSpacing: "0.03em",
  fontFamily: "Roboto",
}

const typographyTertiary = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily: "Roboto",
}

const typographyTertiaryBold = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily: "Roboto",
  fontWeight: "bold",
}
const typographyTag = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily: "Roboto",
  fontWeight: "normal",
}
const typographyMapTag = {
  fontSize: 12,
  lineHeight: "30px",
  fontFamily: "Roboto,'Noto Sans JP'",
  fontWeight: "bold",
}
const label = {
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "23.17px",
  fontFamily: "'Noto Sans JP',sans-serif",
}

const typographyMB = {
  fontWeight: 900,
  fontSize: 26,
  lineHeight: "28px",
  letterSpacing: "0.02em",
  fontFamily: "Roboto",
}
const typographyMBH2 = {
  fontWeight: 900,
  fontSize: 22,
  lineHeight: "35px",
  letterSpacing: "0.01em",
  fontFamily: "Roboto",
}
const PackDesc = {
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  fontFamily: "'Noto Sans JP',sans-serif",
}

export const theme = {
  mainBlue: "#1890FF",
  green: "#67A868",
  borderColorBase: "#d9d9d9",
  gray7: "#8C8C8C",
  gray8: "#595959",
  gray9: "#262626",
  primary: "#121212",
  link: "#1890ff",
  signupLink: "#19389C",
  secondaryLight: "#C2C2C2",
  secondaryDark: "#8E8E8E",
  yellow1: "#F7CD48",
  yellow2: "#EAB82D",
  blue1: "#19389C",
  blue2: "#182a4b",
  blue4: "#2B478B",
  accentMid: "#F5FAFF",
  base: "#FFFFFF",
  primaryLine: "#DCDCDC",
  face1: "#FFB27D",
  face2: "#E2915A",
  gray: "#F8F8F8",
  grey: "#F8F8F8",
  alert: "#EB5757",
  bgDisabled: "#f5f5f5",
  lightBlue: "#B9CBF2",
  lightGrey1: "#F2F1EE",
  lightGrey: "#F4F6FA",
  darkGrey: "#E7E9ED",
  placeholder: "#9f9f9f",
  main: "#1890FF",
  bgColor: "#f3f4f6",
  blueColouredText: "#2B57A5",
  cardGreyBg: "#EEF1F5",
  infromationCardBG: "#eef1f5",
  linkColor: "#2b57a5",
  cardDescriptionColor: "#3c70cd",
  darkBlueColouredText: "#23385D",
  blackTextColor: "#000000",
  darkBlue: "#23385d",
  black: "#000000",
  lightBlueInTable: "rgba(120, 162, 236, 0.3)",
  fadedWhite: "#eef1f5",
  smokeyWhite: "#e5e5e5",
  skyBlue: "#78a2ec",
  fadedGrey: "#AAAAAA",
  sideNavColor: "#EFF4FD",
  typography: {
    typographyH1,
    typographyH2,
    typographyH2EN,
    typographyH3,
    typographyPrimary,
    typographyPrimaryBold,
    typographySecondary,
    typographySecondaryBold,
    typographyTertiary,
    typographyTertiaryBold,
    typographyMB,
    typographyMBH2,
    typographyTag,
    typographyMapTag,
    typographyMap,
    PackDesc,
    label,
  },
}
