import axios from "axios"
import { firebase } from "./firebase"
import * as Sentry from "@sentry/node"

/**
 * axios instance
 */

axios.defaults.responseType = "json"

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_API_URL,
})

let token = ""
let tokenSetTime = Date.now()

API.interceptors.request.use(
  async (config) => {
    if (typeof sessionStorage !== "undefined") {
      if (sessionStorage.getItem("X-Agent-User")) {
        config.headers["X-Agent-User"] = sessionStorage?.getItem("X-Agent-User")
      }
    }

    const tokenRefreshTime = Date.now() - 1800000 // 30 minutes
    if (token == "" || tokenSetTime < tokenRefreshTime) {
      token = await firebase.auth().currentUser?.getIdToken(true)
      tokenSetTime = Date.now()
    }

    if (token) {
      config.headers["Authorization"] = "Bearer " + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

API.interceptors.response.use(
  (response) => {
    return response?.data
  },
  (error) => {
    if (error?.response?.status === 401) {
      firebase.auth().signOut()
    }
    Sentry.captureException(error)
    return Promise.reject(error.response)
  }
)

export { API }
