import { pdf } from "@react-pdf/renderer"

const generatePdfDocument = async (docComponent, fileName) => {
  const blob = await pdf(docComponent).toBlob()
  const url = await URL.createObjectURL(blob)

  const a = document.createElement("a")
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()

  URL.revokeObjectURL(url)
}

export default generatePdfDocument
