const statusOptions = [
  { label: "応募確認", value: "confirming_application" },
  { label: "書類確認", value: "confirming_documents" },
  { label: "書類選考", value: "document_screening" },
  { label: "企業面談", value: "interviewing" },
  { label: "内定", value: "offer_letter" },
  { label: "内定承諾", value: "accepted_offer_letter" },
  { label: "入社", value: "joined" },
  { label: "辞退", value: "decline" },
  { label: "書類NG", value: "documentNG" },
  { label: "面接NG", value: "interviewNG" },
]

const referalStatusOption = [
  { label: "応募確認", value: "応募確認" },
  { label: "書類確認", value: "書類確認" },
  { label: "1次面接", value: "1次面接" },
  { label: "2次面接", value: "2次面接" },
  { label: "3次面接", value: "3次面接" },
  { label: "最終面接", value: "最終面接" },
  { label: "オファー", value: "オファー" },
  { label: "内定", value: "内定" },
  { label: "内定承諾", value: "内定承諾" },
  { label: "入社", value: "入社" },
  { label: "企業NG ", value: "企業NG" },
  { label: "求職者NG", value: "求職者NG" },
]

const referalStatusReasons = [
  { label: "学歴", value: "Education history" },
  { label: "年齢", value: "Age" },
  { label: "転職回数", value: "Career changing time" },
  { label: "スキル", value: "Skills" },
  { label: "経験", value: "Experience" },
  { label: "企業文化", value: "Company culture" },
  { label: "年収", value: "Annual salary" },
]

const jobseekerStatusList = [
  "応募確認",
  "書類確認",
  "1次面接",
  "2次面接",
  "3次面接",
  "最終面接",
  "オファー",
  "内定",
  "内定承諾",
  "入社",
  "企業NG",
  "求職者NG",
]
const showStatusOptions = [{ label: "All", value: "all" }].concat(
  referalStatusOption
)
export {
  statusOptions,
  showStatusOptions,
  referalStatusOption,
  jobseekerStatusList,
  referalStatusReasons,
}
