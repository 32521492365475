import React from "react"
import { t } from "i18next"
import { View, Text, StyleSheet } from "@react-pdf/renderer"
import { PdfTableSection } from "./PdfTableSection"

const PdfTopSectionCardSecond = ({ jobData }: any) => {
  return (
    <View style={styles.section}>
      <View style={styles.overview}>
        <View style={styles.iconLabel}>
          <Text style={styles.label}>{t("Company Overview")}</Text>
        </View>
      </View>
      <PdfTableSection
        label={t("Address")}
        value={
          jobData?.company_id
            ? jobData?.company?.address
            : jobData?.agent_company?.address
        }
      />
      <PdfTableSection
        label={t("Established")}
        value={
          jobData?.company_id
            ? jobData?.company?.established_date
            : jobData?.agent_company?.established_date
        }
      />
      <PdfTableSection
        label={t("No. of employees")}
        value={
          jobData?.company_id
            ? jobData?.company?.number_of_employees
            : jobData?.agent_company?.number_of_employees
        }
      />
    </View>
  )
}
const styles = StyleSheet.create({
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "8pt 6pt",
    marginBottom: "14pt",
    border: "1pt solid #ececec",
    borderRadius: "4pt",
    marginLeft: "16pt",
    width: "320px",
  },
  overview: {
    display: "flex",
    fontWeight: "bold",
    fontSize: 10,
    paddingBottom: 6,
  },
  item: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  iconLabel: {
    display: "flex",
    alignItems: "flex-start",
    width: "28%",
  },
  label: {
    fontWeight: "bold",
    fontSize: 8,
  },
})
export { PdfTopSectionCardSecond }
