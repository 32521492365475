export const pagesname = []

export const setPage = (key, val = 1) => {
  let pages = localStorage.getItem("page")
  pages = pages ? JSON.parse(pages) : {}
  pages[`${key}`] = val
  localStorage.setItem("page", JSON.stringify(pages))
  return true
}

export const setKeyword = (key, val = "") => {
  let keyword = localStorage.getItem("keyword")
  keyword = keyword ? JSON.parse(keyword) : {}
  keyword[`${key}_key`] = val
  localStorage.setItem("keyword", JSON.stringify(keyword))
  return true
}

export const getKeyword = (key) => {
  let keyword = localStorage.getItem("keyword")
  keyword = keyword ? JSON.parse(keyword) : {}
  return keyword[`${key}_key`] || ""
}

export const getOnePageWithKeyword = (page) => {
  let keywords = localStorage.getItem("keyword")
  keywords = keywords ? JSON.parse(keywords) : {}
  let pages = localStorage.getItem("page")
  pages = pages ? JSON.parse(pages) : {}
  let url = `/${page}?page=${pages[page] || 1}`
  Object.keys(keywords)?.forEach((key) => {
    if (keywords[`${key}`]) {
      url += `&${key}=${escape(keywords[`${key}`])}`
    }
  })
  return url
}

export const setAllKeyword = (keyobj = {}, key = "", val = 1) => {
  localStorage.setItem("keyword", JSON.stringify(keyobj))
  if (key) {
    setPage(key, val)
  }
  return true
}

export const setSessionRoute = (pagename = "company", query) => {
  if (pagename == "/") {
    pagename = "company"
  }
  if (pagename !== "") {
    let url = `${pagename}?page=${query?.page || 1}`
    for (const page of pagesname) {
      if (query && query[`${page}`]) {
        url += `&${page}=${query[page]}`
      }
    }
    sessionStorage.setItem("page", url)
  }
  return true
}

export const getSessionRoute = () => {
  let route = sessionStorage.getItem("page")
  if (!route) {
    route = "company?page=1"
  }
  return route
}
