import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import dynamic from "next/dynamic"
import { t } from "i18next"

const Button = dynamic(
  () => import("../../atom/Button/index").then((mod) => mod.ButtonComponent),
  { ssr: false }
)

export interface ChatHeaderProps {
  title: string
  is_user_blocked: boolean
  blocked_by: string
  authUserId: string
  isChatDeleted: boolean
  updateChatHeaderHeight?: (height: number) => void
  handleBlockUserClick: () => void
}

const Wrapper = styled.div`
  padding: 12px 0 8px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #afafaf;
  & .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    @media (max-width: 460px) {
      flex-direction: column;
    }
    h1 {
      font-size: 16px;
      font-weight: 700;
      margin: 11px 0;
      flex-grow: 1;
      cursor: pointer;
    }
    .end-conversation-button {
      background-color: #6b0e19;
    }
    .resume-conversation-button {
      background-color: #18294b;
    }
  }
`
const JobChatHeader: React.FC<ChatHeaderProps> = ({
  title,
  is_user_blocked,
  blocked_by,
  authUserId,
  updateChatHeaderHeight,
  handleBlockUserClick,
  isChatDeleted = false,
}) => {
  const chatheaderWrapperRef = useRef(null)
  useEffect(() => {
    updateChatHeaderHeight &&
      updateChatHeaderHeight(chatheaderWrapperRef?.current?.clientHeight)
  }, [chatheaderWrapperRef?.current?.clientHeight])
  return (
    <Wrapper ref={chatheaderWrapperRef}>
      <div className="container">
        <h1>{title}</h1>
        {!isChatDeleted && !blocked_by && (
          <Button
            className="end-conversation-button"
            onClick={handleBlockUserClick}
          >
            {t("End the chat")}
          </Button>
        )}
        {!isChatDeleted && blocked_by == authUserId && is_user_blocked && (
          <Button
            className="resume-conversation-button"
            onClick={handleBlockUserClick}
            htmlType="submit"
          >
            {t("Resume chat")}
          </Button>
        )}
      </div>
    </Wrapper>
  )
}

export { JobChatHeader }
