import React from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { t } from "i18next"

interface iJobOfferPointBlock {
  headingOne?: string
  headingTwo?: string
  bodyOne?: any
  bodyTwo?: any
  tags?: string[]
  isCenter?: boolean
  hideFull?: boolean
  hideRight?: boolean
  hideLeft?: boolean
  subHeadingOne?: string
  subHeadingTwo?: string
  subHeadingThree?: string
  bodyThree?: string
}

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 12,
    width: "100%",
    padding: 10,
  },
  head: {
    flexDirection: "row",
    alignItems: "center",
    pageBreakInside: "avoid",
    breakInside: "avoid",
    marginBottom: 10,
  },
  headLeft: {
    width: "100%",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    backgroundColor: "#f0f2f5",
    color: "#000",
    paddingLeft: 10,
    fontSize: 8,
    fontWeight: "bold",
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 0,
  },
  headRight: {
    width: "100%",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    backgroundColor: "#f0f2f5",
    color: "#000",
    paddingLeft: 10,
    fontSize: 8,
    fontWeight: "bold",
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 0,
  },
  body: {
    flexDirection: "row",
    width: "100%",
    fontSize: 8,
    paddingHorizontal: 10,
    marginTop: 5,
  },
  bodyLeft: {
    width: "50%",
    marginRight: 8,
    display: "flex",
    flexDirection: "column",
  },
  tags: {
    marginBottom: 10,
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "4pt",
  },
  tagGreen: {
    padding: "0 2pt",
    color: "#08979c",
    backgroundColor: "#e6fffb",
    border: "1pt solid #87e8de",
    borderRadius: "2pt",
    fontSize: 6,
  },
  subTitle: {
    fontWeight: "bold",
  },
  bodyRight: {
    width: "50%",
  },
})

const PdfJobOfferPointBlock = ({
  headingOne,
  headingTwo,
  subHeadingOne,
  subHeadingTwo,
  subHeadingThree,
  bodyOne,
  bodyTwo,
  bodyThree,
  tags,
}: iJobOfferPointBlock) => (
  <View style={styles.wrapper}>
    <View style={styles.head}>
      <View style={styles.headLeft} wrap={false}>
        <Text>{headingOne}</Text>
      </View>
      {bodyTwo && (
        <View style={styles.headRight} wrap={false}>
          <Text>{headingTwo}</Text>
        </View>
      )}
    </View>
    <View style={styles.body}>
      {(bodyOne || tags) && (
        <View style={!(bodyTwo || bodyThree) ? { width: "100%" } : {}}>
          <>
            {bodyOne && (
              <View style={styles.bodyRight}>
                <Text>{subHeadingOne}</Text>
              </View>
            )}
            {tags && (
              <View style={styles.tags}>
                {tags.map((item) => (
                  <View key={item} style={styles.tagGreen}>
                    <Text>{t(item)}</Text>
                  </View>
                ))}
              </View>
            )}
            {!!bodyOne && (
              <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                {/* {Array?.from(bodyOne)?.map((char, index) => (
                  <Text key={index}>{char}</Text>
                ))} */}
                <Text>{bodyOne}</Text>
              </View>
            )}
          </>
        </View>
      )}
      {!!bodyTwo && (
        <View style={styles.bodyRight}>
          {subHeadingTwo && (
            <View style={styles.subTitle}>
              <Text>{subHeadingTwo}</Text>
            </View>
          )}
          {bodyTwo && (
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              {Array?.from(bodyTwo)?.map((char, index) => (
                <Text key={index}>{char}</Text>
              ))}
            </View>
          )}
        </View>
      )}
      {bodyThree && (
        <View style={styles.bodyRight}>
          {subHeadingThree && (
            <View style={styles.subTitle}>
              <Text>{subHeadingThree}</Text>
            </View>
          )}
          <Text>{bodyThree}</Text>
        </View>
      )}
    </View>
  </View>
)

export { PdfJobOfferPointBlock }
