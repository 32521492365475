import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import { Modal, Divider, message } from "antd"
import { CloseCircleFilled, DownloadOutlined } from "@ant-design/icons"
import { getSignedObjectUrl, theme } from "../../.."
import { downloadPdfFile } from "../../../utility"
import dynamic from "next/dynamic"
import moment from "moment"

const Button = dynamic(
  () => import("../../atom/Button/index").then((mod) => mod.ButtonComponent),
  { ssr: false }
)

const InfoWrapper = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .info {
    display: flex;
    align-items: baseline;
    .label {
      width: 300px;
      max-width: 300px;
      min-width: 300px;
    }
    .value_title {
      font-weight: bold;
      padding-top: 0.8rem;
    }
    .value {
      margin-left: 10px;
      white-space: break-spaces;
      word-wrap: break-word;
      word-break: break-word;
      button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 200px;
      }
    }
  }
`

export interface IProps {
  visible: boolean
  onCancel: () => void
  modalData: any
}

const ApplicantModal: React.FC<IProps> = ({ visible, onCancel, modalData }) => {
  const { t } = useTranslation()
  const handleDownloadClick = async (fileUrl, fileName) => {
    try {
      const signedUrl = await getSignedObjectUrl(fileUrl)
      downloadPdfFile(signedUrl, fileName)
    } catch (error) {
      message.error({
        content:
          error?.message ||
          error?.data?.error?.message ||
          "Something went wrong",
        key: "37",
        icon: <CloseCircleFilled onClick={() => message.destroy("37")} />,
      })
    }
  }

  return (
    <Modal
      title="求職者情報"
      visible={visible}
      onCancel={onCancel}
      width={800}
      footer={null}
    >
      <h3>{t("Basic information")}</h3>
      <InfoWrapper>
        <div className="info">
          <div className="label">{t("Name")}</div>
          <div className="value">{modalData?.name}</div>
        </div>
        <div className="info">
          <div className="label">{t("Email")}</div>
          <div className="value">{modalData?.email}</div>
        </div>
        <div className="info">
          <div className="label">{t("Gender")}</div>
          <div className="value">{modalData?.gender}</div>
        </div>
        <div className="info">
          <div className="label">{t("Age")}</div>
          <div className="value">
            {moment(modalData?.date_of_birth).isValid()
              ? `${moment().diff(
                  moment(modalData?.date_of_birth),
                  "years",
                  false
                )} 歳`
              : `${modalData?.age} 歳` || "-"}
          </div>
        </div>
        <div className="info">
          <div className="label">{t("DOB")}</div>
          <div className="value">
            {moment(modalData?.date_of_birth).format("YYYY/MM/DD")}
          </div>
        </div>
        <div className="info">
          <div className="label">{t("Desired prefecture")}</div>
          <div className="value">
            {modalData?.desired_prefecture
              ? String(modalData?.desired_prefecture)
              : "-"}
          </div>
        </div>
      </InfoWrapper>
      <Divider dashed />
      <h3>{t("Material downloads")}</h3>
      <InfoWrapper>
        <div className="info">
          <div className="label">{t("Resume")}</div>
          <div className="value">
            {modalData?.resume ? (
              <Button
                background="#fff"
                color={theme.blue2}
                icon={<DownloadOutlined />}
                onClick={() =>
                  handleDownloadClick(
                    modalData?.resume,
                    `${modalData?.name}-${modalData?.resume_name}`
                  )
                }
              >
                {t("Download resume")}
              </Button>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className="info">
          <div className="label">{t("Work history")}</div>
          <div className="value">
            {modalData?.work_history ? (
              <Button
                background="#fff"
                color={theme.blue2}
                icon={<DownloadOutlined />}
                onClick={() =>
                  handleDownloadClick(
                    modalData?.work_history,
                    `${modalData?.name}-${modalData?.work_history_name}`
                  )
                }
              >
                {t("Download work history")}
              </Button>
            ) : (
              "-"
            )}
          </div>
        </div>
      </InfoWrapper>
      <Divider dashed />
      <h3>{t("Records")}</h3>
      {modalData?.free_entry.length > 0 ? (
        <InfoWrapper>
          <div className="info">
            <div className="value">
              {modalData?.free_entry ? modalData?.free_entry : "-"}
            </div>
          </div>
        </InfoWrapper>
      ) : (
        <InfoWrapper>
          <div className="info">
            {/* <div className="label">{t("Typical format")}</div> */}
            <div className="value">
              <div className="value_title">{t("Summary of duties")}</div>
              {modalData?.typical_format?.summary_of_duties
                ? modalData?.typical_format?.summary_of_duties
                : "-"}
              <div className="value_title">
                {t("Personality characteristics/guessing points")}
              </div>
              {modalData?.typical_format?.guessing_point
                ? modalData?.typical_format?.guessing_point
                : "-"}

              <div className="value_title">{t("Career Background")}</div>
              {modalData?.typical_format?.career_background
                ? modalData?.typical_format?.career_background
                : "-"}
            </div>
          </div>
        </InfoWrapper>
      )}
    </Modal>
  )
}

export { ApplicantModal }
