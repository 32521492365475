import React from "react"
import styled from "styled-components"

interface iSectionTitle {
  title: string
  className?: string
}
const Wrapper = styled.div`
  display: flex;
  h1 {
    margin-left: 10px;
    font-size: 16px;
  }
`
const Highlighter = styled.div`
  height: 26px;
  width: 5px;
  background-color: #001628;
`

const SectionTitle: React.FC<iSectionTitle> = ({ title, className }) => {
  return (
    <Wrapper className={className}>
      <Highlighter></Highlighter>
      <h1>{title}</h1>
    </Wrapper>
  )
}

export { SectionTitle }
