import React from "react"
import { Checkbox, Row, Col } from "antd"
import styled from "styled-components"
import { CheckboxGroupProps } from "antd/lib/checkbox"

const Wrapper = styled.div<{ blockTitle?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
  flex-direction: ${(props: any) => (props.blockTitle ? "column" : "row")};
  .type-label {
    width: 165px;
    font-weight: bold;
  }
  .ant-col {
    min-height: 35px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #182a4b;
    border-color: #182a4b;
  }
  .block-label {
    background: #ccf3fa;
    width: 100%;
    color: #0b5277;
    padding: 15px;
    margin-bottom: 20px;
  }
`

interface ICheckboxOptions {
  label: string
  value: string
}
interface MultiSelectCheckBoxProps extends CheckboxGroupProps {
  typeLabel?: string
  blockTitle?: boolean
  isJobTypeOption?: boolean
  isCountRequired?: boolean
  jobCount?: any
}

const MultiSelectCheckBoxComponent: React.FC<MultiSelectCheckBoxProps> = ({
  typeLabel,
  options,
  blockTitle,
  isJobTypeOption,
  isCountRequired,
  jobCount,
  ...props
}) => {
  return (
    <Wrapper blockTitle={blockTitle}>
      {typeLabel ? (
        blockTitle ? (
          <div className="block-label">{typeLabel}</div>
        ) : (
          <div className="type-label">{typeLabel}</div>
        )
      ) : null}
      <Checkbox.Group
        style={{
          width: "100%",
        }}
        {...props}
      >
        <Row>
          {options.map((item: ICheckboxOptions, index) => (
            <Col span={isJobTypeOption ? 12 : 8} key={index}>
              <Checkbox value={item.value}>{item.label}</Checkbox>
              {isCountRequired && jobCount ? (
                <span style={{ color: "#DE7C22" }}>{`${
                  jobCount?.[item.value]
                }件`}</span>
              ) : null}
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Wrapper>
  )
}

export { MultiSelectCheckBoxComponent }
