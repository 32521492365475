import styled from "styled-components"
import { Input } from "antd"
import { theme } from "../../../theme"

const { Search } = Input

interface SearchProps {
  value?: string
  loading?: boolean
  width?: number
  height?: number
  searchiconwidth?: number
  searchiconheight?: number
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onSearch?: (
    value: string,
    event?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void
  className?: string
  size?: any
}

const StyledSearch = styled(Search)`
  width: ${({ width }: SearchProps) => {
    return width ? `${width}px` : "100%"
  }};
  height: ${({ height }: SearchProps) => {
    return height ? `${height}px` : "40px"
  }};
  & .ant-input {
    border: 1px solid ${theme.borderColorBase};
    border-radius: 8px;
    height: ${({ height }: SearchProps) => {
      return height ? `${height}px` : "40px"
    }};
    &:hover,
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    outline: none;
    border-color: #d9d9d9;
    border-right: none;
  }
  .ant-input-affix-wrapper {
    &:active,
    &:focus,
    &:hover {
      box-shadow: none;
      outline: none;
      border-color: #d9d9d9;
      border-right: none;
    }
  }
  & .ant-input-group-addon {
    & .ant-btn {
      border-left: none;
      &:hover,
      &:focus,
      &:active {
        border-left: none;
      }
    }
  }

  & .ant-btn-icon-only {
    background: #fff;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border: 1px solid ${theme.borderColorBase};
    height: ${({ searchiconheight }: SearchProps) => {
      return searchiconheight ? `${searchiconheight}px` : "50px"
    }};
    width: ${({ searchiconwidth }: SearchProps) => {
      return searchiconwidth ? `${searchiconwidth}px` : "50px"
    }};
    & .anticon {
      svg {
        fill: ${theme.gray7};
      }
    }
    &:hover,
    &:focus {
      border-left: none !important;
    }
  }

  & .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    &:active,
    &:focus,
    &:hover {
      border-color: #d9d9d9;
      border-right-width: 0px !important;
      z-index: 1;
      outline: none;
      box-shadow: none;
    }
  }
`

export const SearchBar: React.FC<SearchProps> = ({
  value,
  onChange,
  onPressEnter,
  onSearch,
  loading,
  placeholder,
  className,
  size,
  ...rest
}) => {
  return (
    <StyledSearch
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onPressEnter={onPressEnter}
      onSearch={onSearch}
      loading={loading}
      className={className}
      size={size ? size : "middle"}
      allowClear={true}
      {...rest}
    />
  )
}
