import React, { useReducer } from "react"
import actionCreator from "../actionCreater"

const addRoom = actionCreator("ADD_ROOM")
const loadRooms = actionCreator("LOAD_ROOMS")
const loadRoomsInitial = actionCreator("LOAD_ROOMS_INITIAL")
const loadMessages = actionCreator("LOAD_MESSAGES")
const addMessage = actionCreator("ADD_MESSAGE")
const deleteMessage = actionCreator("DELETE_MESSAGE")
const setCurrentRoomId = actionCreator("SET_CURRENT_ROOM_ID")
const setUser = actionCreator("SET_USER")
const removeRoom = actionCreator("REMOVE_ROOM")
const updateRoomMessageState = actionCreator("UPDATE_ROOM_MESSAGE_STATE")
const setMessageCount = actionCreator("SET_MESSAGE_COUNT")
const setFileUploadingStatus = actionCreator("SET_FILE_UPLOADING_STATUS")
const updateRoom = actionCreator("UPDATE_ROOM")
const resetState = actionCreator("RESET_STATE")

const initialState = {
  rooms: {},
  currentRoomId: null,
  user: null,
  messageCount: {
    unseen: 0,
    unreplied: 0,
    applicantUnseen: 0,
    companyUnseen: 0,
  },
  isFileUploading: false,
}

const actions = {
  setUser,
  addRoom,
  loadRooms,
  loadRoomsInitial,
  addMessage,
  deleteMessage,
  loadMessages,
  setCurrentRoomId,
  removeRoom,
  updateRoomMessageState,
  setFileUploadingStatus,
  setMessageCount,
  updateRoom,
  resetState,
}

type Actions = typeof actions

export type ChatAction = {
  type: string
  payload: any
}

export type ChatState = typeof initialState

export type Dispatch = (action: ChatAction) => void

const ChatContext = React.createContext<
  { state: ChatState; actions: Actions; dispatch: Dispatch } | undefined
>(undefined)

function chatReducer(state: ChatState, action: ChatAction) {
  const { type, payload } = action

  switch (type) {
    case "RESET_STATE": {
      return {
        rooms: {},
        currentRoomId: null,
        user: null,
        messageCount: {
          unseen: 0,
          unreplied: 0,
          applicantUnseen: 0,
          companyUnseen: 0,
        },
        isFileUploading: false,
      }
    }

    case "SET_USER": {
      return {
        ...state,
        user: payload,
      }
    }

    case "SET_CURRENT_ROOM_ID": {
      return {
        ...state,
        currentRoomId: payload,
      }
    }

    case "LOAD_ROOMS": {
      const newRooms = {}
      payload.forEach((room) => {
        newRooms[room.id] = room
      })
      return {
        ...state,
        rooms: {
          ...state.rooms,
          ...newRooms,
        },
      }
    }

    case "LOAD_ROOMS_INITIAL": {
      const newRooms = {}
      payload.forEach((room) => {
        newRooms[room.id] = room
      })
      return {
        ...state,
        rooms: {
          ...newRooms,
        },
      }
    }

    case "ADD_ROOM": {
      if (state.rooms[payload.id]) {
        const rooms = { ...state.rooms }
        delete rooms[payload.id]
        return {
          ...state,
          rooms: {
            [payload.id]: payload,
            ...rooms,
          },
        }
      }
      return {
        ...state,
        rooms: {
          [payload.id]: payload,
          ...state.rooms,
        },
      }
    }

    case "UPDATE_ROOM_MESSAGE_STATE": {
      return {
        ...state,
        rooms: {
          ...state.rooms,
          [payload.id]: {
            ...state.rooms[payload.id],
            message_state: payload.message_state,
          },
        },
      }
    }

    case "UPDATE_ROOM": {
      return {
        ...state,
        rooms: {
          ...state.rooms,
          [payload.id]: {
            ...state.rooms[payload.id],
            ...payload.newState,
          },
        },
      }
    }

    case "REMOVE_ROOM": {
      const rooms = Object.assign({}, state.rooms)
      delete rooms[payload]
      return {
        ...state,
        rooms: {
          ...rooms,
        },
      }
    }

    case "LOAD_MESSAGES": {
      const messageList = [
        ...state.rooms[payload.room_id]["messages"],
        ...payload.messages,
      ]
      return {
        ...state,
        rooms: {
          ...state.rooms,
          [payload.room_id]: {
            ...state.rooms[payload.room_id],
            messages: messageList,
          },
        },
      }
    }

    case "DELETE_MESSAGE": {
      const roomId = state.currentRoomId
      const messages = state.rooms[roomId]?.messages
      const filteredMessages = messages.filter((msg) => msg.id !== payload.id)
      return {
        ...state,
        rooms: {
          ...state.rooms,
          [roomId]: {
            ...state.rooms[roomId],
            messages: filteredMessages,
          },
        },
      }
    }

    case "ADD_MESSAGE": {
      let messageList = []
      if (state.rooms[payload.room_id].messages.length > 0) {
        if (
          state.rooms[payload.room_id].messages[0].id === payload.message.id
        ) {
          messageList = [...state.rooms[payload.room_id].messages]
        } else {
          messageList = [
            payload.message,
            ...state.rooms[payload.room_id].messages,
          ]
        }
      } else {
        messageList = [payload.message]
      }
      return {
        ...state,
        rooms: {
          ...state.rooms,
          [payload.room_id]: {
            ...state.rooms[payload.room_id],
            messages: messageList,
          },
        },
      }
    }

    case "SET_MESSAGE_COUNT": {
      const { key, count } = payload
      return {
        ...state,
        messageCount: {
          ...state.messageCount,
          [key]: count,
        },
      }
    }

    case "SET_FILE_UPLOADING_STATUS": {
      return {
        ...state,
        isFileUploading: payload,
      }
    }

    default: {
      throw new Error()
    }
  }
}

function ChatProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(chatReducer, initialState)

  return (
    <ChatContext.Provider value={{ state, actions, dispatch }}>
      {children}
    </ChatContext.Provider>
  )
}

function useChatContext() {
  const context = React.useContext(ChatContext)
  if (context === undefined) {
    throw new Error(`useChatContext must be used within a ChatProvider`)
  }
  return context
}

export { ChatProvider, useChatContext }
