import { t } from "i18next"

export const genderOptions = [
  {
    label: t("Male"),
    value: "男性",
  },
  {
    label: t("Female"),
    value: "女性",
  },
]

/**
 *This function is used to 
 *scrolls to the desired <div>
  with the specified ID.
 * @param id
 */
export const scrollToDiv = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: "smooth" })
  }
}
