import { API } from "../src"

export interface IReturnFavoriteJobList {
  count?: number
  data?: any
}
interface ISearchJobQuery {
  page?: string
  size?: string
  keyword: string
  job_type?: string
  area?: string
  occupation_type?: string
}

export const fetchFavoriteJobs = async ({
  queryKey,
}): Promise<IReturnFavoriteJobList> => {
  const params = {} as ISearchJobQuery
  params.page = queryKey[1].page
  params.size = queryKey[1].size
  params.keyword = queryKey[1].keyword
  params.job_type = queryKey[1].jobType
  params.area = queryKey[1].area?.join()
  params.occupation_type = queryKey[1].worktype?.join()

  return API.get(`/favorite-job`, { params })
}

export const addFavoriteJob = async (values: any) => {
  return API.post(`/favorite-job`, values)
}

export const deleteFavoriteJob = async (id: string) => {
  return API.delete(`/favorite-job/${id}`)
}

export const getFavoriteJobById = async (id: string) => {
  return API.get(`/favorite-job/${id}`)
}
