import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { message } from "antd"
import { JobChatFile } from "./JobChatFile"
import { useFormik } from "formik"
import { CloseCircleFilled } from "@ant-design/icons"
import {
  JobRoomMessageState,
  sendJobMessage,
} from "../../../../services/jobChat"
import { moveUploadedFile } from "../../../../services/fileupload"
import { API } from "../../../api"
import dynamic from "next/dynamic"

const Button = dynamic(
  () => import("../../atom/Button/index").then((mod) => mod.ButtonComponent),
  { ssr: false }
)
const TextField = dynamic(
  () => import("../../atom/TextField/index").then((mod) => mod.TextField),
  { ssr: false }
)
const Wrapper = styled.div`
  margin-top: auto;
  padding-right: 20px;
  padding-left: 20px;
  position: absolute;
  left: 415px;
  right: 0;
  bottom: 0;
  padding-top: 10px;
  background-color: #fff;
  & .container {
    display: flex;
    flex-direction: column;
    & .chatbox-attach {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 380px) {
        flex-direction: column;
      }
      .button-wrapper {
        margin: auto 0 3px 20px;
        button {
          width: 100%;
        }
        @media (max-width: 380px) {
          width: 100%;
          margin: 8px auto;
        }
      }
      .text-field {
        flex-grow: 1;
        @media (max-width: 380px) {
          width: 100%;
        }
        textarea {
          width: 100%;
          border-radius: 20px;
          font-size: 16px;
          line-height: 24px;
          height: 40px;
          max-height: 120px !important;
        }
      }
    }
  }
`
export const getMessageType = (senderType: string) => {
  switch (senderType) {
    case "company":
      return "sent_by_company"
    case "agent-as-company":
      return "sent_by_agent_member_as_company"
    case "agent":
      return "sent_by_agent_member"
  }
}

const UserBlocked = styled.div<{ shake?: boolean }>`
  text-align: center;
  padding: 6px;
  font-size: 14px;
  color: rgba(255, 0, 0, 0.5);
  animation: ${({ shake }) => (shake ? "shake 0.5s" : "")};
  animation-iteration-count: 1;
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
`

interface TextMessageType {
  text: string
}

interface IMessageBoxProps {
  updateMessageBoxHeight?: (height: number) => void
  roomId: string
  selectionId: string
  selfId: string
  receiverType: string
  senderType: string
  handleSendEmailToReceiver?: () => void
  is_user_blocked: boolean
  isChatDeleted: boolean
}

const handleKeyboardSubmit = (e: any, formik) => {
  if (e.ctrlKey) {
    formik.handleSubmit()
  }
}

const JobMessageBox: React.FC<IMessageBoxProps> = ({
  updateMessageBoxHeight,
  roomId,
  selfId,
  receiverType,
  senderType,
  selectionId,
  handleSendEmailToReceiver,
  is_user_blocked,
  isChatDeleted = false,
}) => {
  const { t } = useTranslation()
  const [file, setFile] = useState(null)
  const [isMessageSent, setIsMessageSent] = useState(false)
  const [shake, setShake] = useState(false)
  const chatboxWrapperRef = useRef(null)
  const formik = useFormik<TextMessageType>({
    initialValues: {
      text: "",
    },
    onSubmit: async (values, form_actions) => {
      if (is_user_blocked) {
        setShake(true)
        updateMessageBoxHeight(chatboxWrapperRef?.current?.clientHeight)
        setTimeout(() => {
          setShake(false)
          updateMessageBoxHeight(chatboxWrapperRef?.current?.clientHeight)
        }, 500)
        return
      }
      try {
        const trimmedText = values.text.trim()
        if (trimmedText.length > 0 || file?.url) {
          form_actions.resetForm({})
          let message_state: JobRoomMessageState
          let last_msg_sent_by
          const message_type = getMessageType(senderType)

          switch (receiverType) {
            case "company": {
              message_state = JobRoomMessageState.CompanyNotSeen
              last_msg_sent_by = "agent2company"
              break
            }
            case "agent-as-company": {
              message_state = JobRoomMessageState.AgentCompanyNotSeen
              last_msg_sent_by = "agent2agentCompany"
              break
            }
            case "agent": {
              message_state = JobRoomMessageState.AgentNotSeen
              last_msg_sent_by = "company2agent"
              break
            }
          }
          const newMessage = await sendJobMessage(
            roomId,
            message_state,
            last_msg_sent_by,
            {
              sender_id: selfId,
              content: trimmedText,
              type: message_type,
              file_url: file?.url?.replace("temp", "default") || null,
              file_name: file?.name || null,
            }
          )
          if (newMessage?.id) {
            await API.post("/job-messages", {
              id: newMessage?.id,
              sender_id: selfId,
              room_id: roomId,
              type: message_type,
              content: trimmedText,
              file_url: file?.url?.replace("temp", "default") || null,
              file_name: file?.name || null,
            })
          }
          setFile(null)
          handleSendEmailToReceiver()
          setIsMessageSent(true)
          await API.put(`/job-applicant-update-date/${selectionId}`)
          if (file?.url) {
            await moveUploadedFile(file?.url)
          }
        }
      } catch (error) {
        message.error({
          content: "Something went wrong",
          key: "33",
          icon: <CloseCircleFilled onClick={() => message.destroy("33")} />,
        })
      }
    },
  })

  useEffect(() => {
    updateMessageBoxHeight &&
      updateMessageBoxHeight(chatboxWrapperRef?.current?.clientHeight)
  }, [chatboxWrapperRef?.current?.clientHeight])

  useEffect(() => {
    updateMessageBoxHeight &&
      updateMessageBoxHeight(chatboxWrapperRef?.current?.clientHeight)
  }, [is_user_blocked])

  return (
    <Wrapper ref={chatboxWrapperRef}>
      <form onSubmit={formik.handleSubmit}>
        <div className="container">
          {(is_user_blocked || isChatDeleted) && (
            <UserBlocked shake={shake}>
              <p>
                {isChatDeleted
                  ? t(
                      "You cannot chat because either job or applicant has been deleted."
                    )
                  : t("You cannot chat with the user after the chat is ended.")}
              </p>
            </UserBlocked>
          )}
          <div className="chatbox-attach">
            <TextField
              type="textarea"
              name="text"
              disabled={is_user_blocked || isChatDeleted}
              value={formik.values.text}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t("Please write your message here")}
              className="text-field form-group-field"
              onPressEnter={(e) => handleKeyboardSubmit(e, formik)}
            />
            <div className="button-wrapper">
              <Button htmlType="submit">送信</Button>
            </div>
          </div>
          <JobChatFile
            sent={isMessageSent}
            updateSent={setIsMessageSent}
            fireStoreFile={file}
            updateFileToFireStore={setFile}
          />
        </div>
      </form>
    </Wrapper>
  )
}

export { JobMessageBox }
