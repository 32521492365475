import {
  DownloadOutlined,
  LoadingOutlined,
  TeamOutlined,
} from "@ant-design/icons"
import { Modal, notification, Spin, Tag } from "antd"
import moment from "moment"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import { referJobSeeker } from "../../../../../services/jobseeker"
import { addhttp, PdfDownload } from "../../../../../utils"
import generatePdfDocument from "../../../../../utils/generatePdf"
import { JobTypeTranslation } from "../../../../../utils/jobTypeTranslation"
import { theme } from "../../../../theme"
import { getSignedObjectUrl } from "../../../../utility"
import { JobSeekers } from "../../JobSeekers"
import { ArrayTranslate, TopSectionCard } from "../TopSectionCard"
import { TopSectionCardSecond } from "../TopSectionCardSecond"

const Button = dynamic(
  () => import("../../../atom/Button/index").then((mod) => mod.ButtonComponent),
  { ssr: false }
)
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .tags {
    padding-bottom: 6px;
    .ant-tag {
      font-size: 10px;
    }
  }
  .title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 16px;
      font-weight: bold;
      padding-right: 10px;
    }

    .title-extra {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
  }

  .company-url,
  .occupation-list {
    display: flex;
    gap: 9px;
    margin-bottom: 15px;
  }
  .industry-type {
    color: #7d857c;
  }
  .main {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }

    .left {
      .job-detail {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }
      img {
        background-color: #f0f2f5;
        object-fit: contain;
        object-position: center;
      }
    }

    & .right {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
  .csv-download {
    margin-left: 8px;
    color: black;
  }
  .download-btn {
    a {
      margin-left: 8px;
      color: #000000;
    }
  }
`

const TopSection = ({
  job,
  // handlePDFDownload,
  isAgent,
  dashboardMenu,
  isAgentAdmin,
  isCompany,
}: {
  job: any
  handlePDFDownload?: any
  isAgent: boolean
  dashboardMenu: string
  isAgentAdmin?: boolean
  isDownloading?: boolean
  isCompany?: boolean
}) => {
  const { t } = useTranslation()
  const [signedObject, setSignedObject] = useState("")
  const [interviewImage, setInterviewInmage] = useState("")
  const router = useRouter()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [pdfDownloading, setPdfDownloading] = useState(false)
  const { applicantId, loginId } = router.query

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />
  const jobData = job?.data
  useEffect(() => {
    if (jobData?.image) {
      ;(async () => {
        const imageUrl = await getSignedObjectUrl(jobData?.image)
        setSignedObject(imageUrl)
      })()
    }
    if (jobData?.job_overview?.interview_image) {
      ;(async () => {
        const imageUrl = await getSignedObjectUrl(
          jobData?.job_overview?.interview_image
        )
        setInterviewInmage(imageUrl)
      })()
    }
  }, [job])

  const csvData = (jobInfo) => {
    const csvInfo = [
      [
        t("Company name"),
        jobInfo?.agent_company.name
          ? jobInfo?.agent_company.name
          : jobInfo?.company.name,
      ],
      [t("One phrase"), jobInfo?.one_phrase],
      [t("Company image"), signedObject],
      [t("Industry"), jobInfo?.industry_type_new || jobInfo?.industry_type],
      [
        t("Annual Income"),
        `${jobInfo?.annual_income?.from}-${jobInfo?.annual_income?.to}`,
      ],
      [t("Area"), jobInfo?.areas],
      [t("Age"), `${jobInfo?.age?.from}-${jobInfo?.age?.to}`],
      [t("Desired gender"), jobInfo?.job_overview?.desired_gender],
      [t("Contract type"), jobInfo?.contract_type],
      [
        t("Address"),
        jobInfo?.company_id
          ? jobInfo?.company?.address
          : jobInfo?.agent_company?.address,
      ],
      [
        t("Established"),
        jobInfo?.company_id
          ? jobInfo?.company?.established_date
          : jobInfo?.agent_company?.established_date,
      ],
      [
        t("No. of employees"),
        jobInfo?.company_id
          ? jobInfo?.company?.number_of_employees
          : jobInfo?.agent_company?.number_of_employees,
      ],
      [t("Last Updated Date"), jobInfo?.updated_date || jobInfo?.created_at],
    ]
    jobInfo?.job_offer_point?.qualification_requirement?.info &&
      csvInfo.push([
        t("Qualification requirements"),
        jobInfo?.job_offer_point?.qualification_requirement?.info,
      ])
    jobInfo?.job_offer_point?.qualification_requirement?.skill &&
      csvInfo.push([
        t("Skill"),
        jobInfo?.job_offer_point?.qualification_requirement?.skill,
      ])
    jobInfo?.job_offer_point?.qualification_requirement?.experience &&
      csvInfo.push([
        t("Experience"),
        jobInfo?.job_offer_point?.qualification_requirement?.experience,
      ])
    jobInfo?.job_overview?.business_details &&
      csvInfo.push([
        t("Business details"),
        jobInfo?.job_overview?.business_details,
      ])
    jobInfo?.job_overview?.work_details &&
      csvInfo.push([
        t("Work details"),
        jobInfo?.job_overview?.work_details.toString(),
      ])
    jobInfo?.topic?.characteristic &&
      csvInfo.push([
        t("Characteristics"),
        jobInfo?.topic?.characteristics.toString(),
      ])
    jobInfo?.topic?.thoughtfulness &&
      csvInfo.push([
        t("Thoughtfulness"),
        jobInfo?.topic?.thoughtfulness.toString(),
      ])
    jobInfo?.topic?.working_type &&
      csvInfo.push([t("Working type"), jobInfo?.topic?.working_type.toString()])
    jobInfo?.topic?.company_type &&
      csvInfo.push([t("Company type"), jobInfo?.topic?.company_type.toString()])
    jobInfo?.topic?.recruitment_Features &&
      csvInfo.push([
        t("Recruitment features"),
        jobInfo?.topic?.recruitment_features.toString(),
      ])

    jobInfo?.topic?.workplace_features &&
      csvInfo.push([
        t("Workplace features"),
        jobInfo?.topic?.workplace_features.toString(),
      ])
    jobInfo?.topic?.work_features &&
      csvInfo.push([
        t("Work features"),
        jobInfo?.topic?.work_features.toString(),
      ])

    jobInfo?.topic?.company_features &&
      csvInfo.push([
        t("Company features"),
        jobInfo?.topic?.company_features.toString(),
      ])
    jobInfo?.topic?.work_style &&
      csvInfo.push([(t("Work style"), jobInfo?.topic?.work_style.toString())])
    jobInfo?.job_overview?.holidays_and_welfares &&
      csvInfo.push([
        t("Holidays and welfares"),
        jobInfo?.job_overview?.holidays_and_welfares.toString(),
      ])

    jobInfo?.job_overview?.work_time.from &&
      jobInfo?.job_overview?.work_time.to &&
      csvInfo.push([
        t("Work time"),
        `${moment(jobInfo?.job_overview?.work_time.from).format(
          "HH:mm"
        )}-${moment(jobInfo?.job_overview?.work_time.to).format("HH:mm")}`,
      ])
    jobInfo?.job_overview?.message_from_hr?.info &&
      csvInfo.push([
        t("Message from HR"),
        jobInfo?.job_offer_point?.message_from_hr?.info,
      ])
    jobInfo?.job_overview?.message_from_hr?.career_plan &&
      csvInfo.push([
        t("Career plan"),
        jobInfo?.job_offer_point?.message_from_hr?.career_plan,
      ])
    jobInfo?.job_overview?.informal_information &&
      csvInfo.push([
        t("Informal candidate information"),
        jobInfo?.job_offer_point?.informal_information,
      ])
    jobInfo?.point_of_recommendation &&
      csvInfo.push([
        t("Points for recommendation/ important point"),
        jobInfo?.point_of_recommendation,
      ])
    jobInfo?.job_overview?.interview &&
      csvInfo.push([t("Interview"), jobInfo?.job_overview?.interview])

    interviewImage && [t("Interview image"), interviewImage]
    jobInfo?.job_offer_point?.selection_flow &&
      csvInfo.push([
        t("Selection flow"),
        jobInfo?.job_offer_point?.selection_flow,
      ])
    jobInfo?.job_offer_point?.message_from_hr?.document_screening &&
      csvInfo.push([
        t("Example of selection screening"),
        jobInfo?.job_offer_point?.message_from_hr?.document_screening,
      ])
    jobInfo?.job_offer_point?.message_from_hr?.reason_of_rejection &&
      csvInfo.push([
        t("Example of interview rejection"),
        jobInfo?.job_offer_point?.message_from_hr?.reason_of_rejection,
      ])
    jobInfo?.interview?.first_interview?.question &&
      csvInfo.push([
        t("First interview Question Example"),
        jobInfo?.interview?.first_interview?.question,
      ])
    jobInfo?.interview?.first_interview?.selection_point &&
      csvInfo.push([
        t("First interview Selection Point"),
        jobInfo?.interview?.first_interview?.selection_point,
      ])
    jobInfo?.interview?.first_interview?.ng_reason &&
      csvInfo.push([
        t("First interview NG reason"),
        jobInfo?.interview?.first_interview?.ng_reason,
      ])
    jobInfo?.interview?.second_interview?.question &&
      csvInfo.push([
        t("Second interview Question Example"),
        jobInfo?.interview?.second_interview?.question,
      ])
    jobInfo?.interview?.second_interview?.selection_point &&
      csvInfo.push([
        t("Second interview Selection Point"),
        jobInfo?.interview?.second_interview?.selection_point,
      ])
    jobInfo?.interview?.second_interview?.ng_reason &&
      csvInfo.push([
        t("Second interview NG reason"),
        jobInfo?.interview?.second_interview?.ng_reason,
      ])
    jobInfo?.interview?.final_interview?.question &&
      csvInfo.push([
        t("Final interview Question Example"),
        jobInfo?.interview?.final_interview?.question,
      ])
    jobInfo?.interview?.final_interview?.selection_point &&
      csvInfo.push([
        t("Final interview Selection Point"),
        jobInfo?.interview?.final_interview?.selection_point,
      ])
    jobInfo?.interview?.final_interview?.ng_reason &&
      csvInfo.push([
        t("Final interview NG reason"),
        jobInfo?.interview?.final_interview?.ng_reason,
      ])
    jobInfo?.interview?.selection?.question &&
      csvInfo.push([
        t("Selection Question Example"),
        jobInfo?.interview?.selection?.question,
      ])
    jobInfo?.interview?.selection?.selection_point &&
      csvInfo.push([
        t("Selection Selection Point"),
        jobInfo?.interview?.selection?.selection_point,
      ])
    jobInfo?.interview?.selection?.ng_reason &&
      csvInfo.push([
        t("Selection NG reason"),
        jobInfo?.interview?.selection?.ng_reason,
      ])
    jobInfo?.job_overview?.recruiter_comment &&
      csvInfo.push([
        t("Recruiter Comment"),
        jobInfo?.job_overview?.recruiter_comment,
      ])
    jobInfo?.job_overview?.looking_for_people_like_this &&
      csvInfo.push([
        t("Looking for people like this"),
        jobInfo?.job_overview?.looking_for_people_like_this,
      ])
    return csvInfo
  }

  const { mutate, isLoading: isLoadingReferJobSeeker } = useMutation(
    referJobSeeker,
    {
      onSuccess: () => {
        notification.success({
          message: t("Job picked up successfully"),
        })
        router.push({ pathname: "/jobs", query: { applicantId } })
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        const error_type = error?.data?.error?.error
        if (error_type.includes("Duplicate entry")) {
          notification.error({ message: t("Already picked up") })
          return
        }
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )
  // pick up  job by Applicant
  const pickUpApplicant = (applicantId: string) => {
    {
      mutate({
        job_id: jobData.id,
        applicant_id: applicantId,
        status: "ピックアップ",
      })
    }
  }

  const downloadPdf = async () => {
    setPdfDownloading(true)
    let interviewUrl = ""
    let imageUrl = ""
    const job = jobData
    if (job?.job_overview?.interview_image)
      interviewUrl = await getSignedObjectUrl(
        job?.job_overview?.interview_image
      )
    if (job?.image) imageUrl = await getSignedObjectUrl(job?.image)
    await generatePdfDocument(
      <PdfDownload
        jobData={job}
        isCompany={isCompany}
        signedObject={imageUrl}
        interviewImageObject={interviewUrl}
      />,
      job.agent_company.name
        ? `${job.agent_company.name} - ${job?.one_phrase}.pdf`
        : `${job.company.name} - ${job?.one_phrase}.pdf`
    )
    setPdfDownloading(false)
  }

  return (
    <Wrapper>
      <div className="tags">
        {jobData?.job_type && (
          <Tag color="purple">{JobTypeTranslation[jobData?.job_type]}</Tag>
        )}
        {jobData?.contract_type && (
          <Tag color="cyan">{jobData?.contract_type}</Tag>
        )}
      </div>
      <div className="title-bar">
        <div className="title">
          {jobData?.agent_company?.name
            ? jobData?.agent_company.name
            : jobData?.company?.name}{" "}
          {jobData?.one_phrase}
        </div>
        <div className="title-extra" data-html2canvas-ignore={true}>
          <Button
            background="#fff"
            color={theme.blue2}
            icon={<DownloadOutlined />}
          >
            <CSVLink
              data={csvData(jobData)}
              filename={
                (jobData?.agent_company?.name
                  ? jobData.agent_company.name
                  : jobData?.company?.name) +
                " - " +
                jobData?.one_phrase +
                ".csv"
              }
            >
              <span className="csv-download">{t("CSV download")}</span>
            </CSVLink>
          </Button>

          <a target="_blank">
            <Button
              background="#fff"
              color={theme.blue2}
              icon={
                pdfDownloading ? (
                  <Spin indicator={antIcon} style={{ marginRight: "8px" }} />
                ) : (
                  <DownloadOutlined />
                )
              }
              className={"download-btn"}
              disabled={pdfDownloading}
              onClick={downloadPdf}
            >
              {t("Download PDF")}
            </Button>
          </a>
          {isAgent && dashboardMenu != "company" && (
            <Button
              icon={<TeamOutlined />}
              loading={applicantId ? isLoadingReferJobSeeker : false}
              onClick={
                applicantId
                  ? () => pickUpApplicant(String(applicantId)) //perform pickup functiononality
                  : showModal
              }
            >
              {applicantId ? t("Pick up") : t("Refer job seekers")}
            </Button>
          )}
        </div>
      </div>
      <div className="company-url">
        {(jobData?.company?.url || jobData?.agent_company?.url) && (
          <a
            href={
              jobData?.company_id
                ? addhttp(jobData?.company?.url)
                : addhttp(jobData?.agent_company?.url)
            }
            target="_blank"
          >
            {jobData?.company_id
              ? jobData?.company?.url
              : jobData?.agent_company?.url}
          </a>
        )}
        {jobData?.industry_type?.length ||
        jobData?.industry_type_new?.length ? (
          <span className="industry-type">
            (
            {ArrayTranslate(
              jobData?.industry_type_new?.length
                ? jobData?.industry_type_new
                : jobData?.industry_type
            )}
            )
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="main">
        <div className="left">
          <img
            src={signedObject || "/assets/icons/logo-job-placeholder.png"}
            height={250}
            width={309}
            alt="job-image"
          />
          <div className="job-detail" data-html2canvas-ignore={true}>
            <span>
              {t("Recruiting company")} :{" "}
              {jobData?.company_id
                ? jobData?.company?.name
                : jobData?.agent_company?.agent?.company_name}
            </span>

            <span>
              求人最終更新日 :{moment(jobData?.updated_at).format("YYYY-MM-DD")}{" "}
            </span>
          </div>
        </div>
        <div className="right">
          <TopSectionCard job={jobData} />
          <TopSectionCardSecond job={jobData} />
        </div>
      </div>
      <Modal
        title={t("Refer job seekers")}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={"calc(100% - 300px)"}
        style={{ left: "8rem", top: "5.5rem" }}
      >
        <div
          style={{
            height: "calc(100vh - 215px)",
            overflow: "hidden",
          }}
        >
          <JobSeekers
            jobID={jobData?.id}
            isAgentAdmin={isAgentAdmin}
            loginId={loginId as string}
          />
        </div>
      </Modal>
    </Wrapper>
  )
}

export { TopSection }
