import { Radio as RadioButton } from "antd"
import React from "react"

export interface RadioOptions {
  label: string
  value: string | number
}

export interface RadioProps {
  name?: string
  onChange?: (e: any) => void
  options?: RadioOptions[]
  value?: string | number
  label?: string
  error?: string
  disabled?: boolean
}
const RadioButtons: React.FC<RadioProps> = ({
  name,
  onChange,
  options,
  value,
  error,
  label,
  disabled,
}: RadioProps) => {
  const radioButtons = []
  for (let id = 0; id < options.length; id++) {
    radioButtons.push(
      <RadioButton key={options[id].value} value={options[id].value}>
        {options[id].label}
      </RadioButton>
    )
  }

  return (
    <div className="radio-group__wrapper">
      {label && <p className="label"> {label} </p>}
      <div className="radio-group">
        <RadioButton.Group
          name={name}
          onChange={onChange}
          value={value}
          disabled={disabled}
        >
          {radioButtons}
        </RadioButton.Group>
        {error && <p>{error}</p>}
      </div>
    </div>
  )
}

export { RadioButtons }
