import { Badge, Tag } from "antd"
import React from "react"
import styled from "styled-components"

export interface iJobSectionBlock {
  index: number
  title: string
  content?: string
  important_items?: string[]
  html2canvas_ignore?: boolean
}

const Wrapper = styled.div`
  margin-left: 5px;
  margin-top: 6px;
  margin-bottom: 6px;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .ant-badge-count {
      background-color: #182a4b;
    }
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
  }
  .content {
    font-size: 12px;
    word-break: break-all;
    white-space: pre-wrap;
  }
  .tags {
    margin-bottom: 10px;
    margin-top: 5px;
    .ant-tag {
      font-size: 10px;
    }
  }
`
const checkKeyMatch = (item) => {
  return (
    <Tag key={item} color="cyan">
      {item}
    </Tag>
  )
}
const JobSectionBlock: React.FC<iJobSectionBlock> = ({
  title,
  index,
  content,
  important_items,
  html2canvas_ignore,
}) => {
  return (
    <Wrapper data-html2canvas-ignore={html2canvas_ignore}>
      <div className="header">
        <Badge count={index} />
        <div className="title">{title}</div>
      </div>
      {important_items && (
        <div className="tags">
          {important_items.map((item) => {
            return checkKeyMatch(item)
          })}
        </div>
      )}
      <div className="content">{content}</div>
    </Wrapper>
  )
}

export { JobSectionBlock }
