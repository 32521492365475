import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { message } from "antd"
import moment from "moment"
import { useMutation } from "react-query"
import { ChatFile } from "./ChatFile"
import { useFormik } from "formik"
import { useChatContext } from "../../../contexts/chat/ChatContext"
import {
  sendMessage,
  sendNotificationEmail,
  updateRoomSession,
  saveMessageInDB,
} from "../../../chatServices"
import { CloseCircleFilled } from "@ant-design/icons"
import dynamic from "next/dynamic"

const Button = dynamic(
  () => import("../../atom/Button/index").then((mod) => mod.ButtonComponent),
  { ssr: false }
)
const TextField = dynamic(
  () => import("../../atom/TextField/index").then((mod) => mod.TextField),
  { ssr: false }
)
const Wrapper = styled.div`
  margin-top: auto;
  padding-right: 20px;
  padding-left: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 10px;
  background-color: #fff;
  & .container {
    display: flex;
    flex-direction: column;
    & .chatbox-attach {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 380px) {
        flex-direction: column;
      }
      .button-wrapper {
        margin: auto 0 3px 20px;
        button {
          width: 100%;
        }
        @media (max-width: 380px) {
          width: 100%;
          margin: 8px auto;
        }
      }
      .text-field {
        flex-grow: 1;
        @media (max-width: 380px) {
          width: 100%;
        }
        textarea {
          width: 100%;
          border-radius: 20px;
          font-size: 16px;
          line-height: 24px;
          height: 40px;
          max-height: 120px !important;
        }
      }
    }
  }
`
const UserBlocked = styled.div<{ shake?: boolean }>`
  text-align: center;
  padding: 6px;
  font-size: 14px;
  color: rgba(255, 0, 0, 0.5);
  animation: ${({ shake }) => (shake ? "shake 0.5s" : "")};
  animation-iteration-count: 1;
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
`

interface TextMessageType {
  text: string
}

interface IMessageBoxProps {
  updateMessageBoxHeight?: (height: number) => void
  isGuest?: boolean
}

const handleKeyboardSubmit = (e: any, formik) => {
  if (e.ctrlKey) {
    formik.handleSubmit()
  }
}

const MessageBox: React.FC<IMessageBoxProps> = ({
  updateMessageBoxHeight,
  isGuest,
}) => {
  const { t } = useTranslation()
  const { state } = useChatContext()
  const [file, setFile] = useState(null)
  const [isMessageSent, setIsMessageSent] = useState(false)

  const [shake, setShake] = useState(false)

  const mutation = useMutation(sendNotificationEmail)

  // Send notification mail if the last message was sent before 2 hours
  const handleSendEmail = () => {
    const currentRoom = state.rooms[state.currentRoomId]

    const latestMsgDate = currentRoom?.messages?.[0]?.created_at
    const duration = moment.duration(moment().diff(latestMsgDate)).asMinutes()

    const user = currentRoom.guest
    if (!currentRoom?.is_active) {
      mutation.mutate(user)
    } else if (duration > 120) {
      mutation.mutate(user)
      currentRoom?.is_active &&
        updateRoomSession({
          currentRoomId: state.currentRoomId,
          isActive: false,
        })
    }
  }

  const formik = useFormik<TextMessageType>({
    initialValues: {
      text: "",
    },
    onSubmit: async (values, form_actions) => {
      try {
        if (state.rooms[state.currentRoomId]?.is_user_blocked) {
          setShake(true)
          setTimeout(() => {
            setShake(false)
          }, 500)
          return
        }
        const trimmedText = values.text.trim()
        if (trimmedText.length > 0 || file?.url) {
          form_actions.resetForm({})
          const message = await sendMessage(state.currentRoomId, {
            sender_id: state.user.id,
            is_seen: false,
            content: trimmedText,
            file_url: file?.url?.replace("temp", "default") || null,
            file_name: file?.name || null,
          })
          setFile(null)
          setIsMessageSent(true)
          state?.user?.type === "agent" && handleSendEmail()
          await saveMessageInDB({
            id: message.id,
            room_id: state.currentRoomId,
            sender_id: message.sender_id,
            content: message.content,
            file_url: message?.file_url?.replace("default", "temp") || null,
            file_name: message.file_name,
            created_at: message.created_at,
          })
        }
      } catch (error) {
        message.error({
          content: "Something went wrong",
          key: "33",
          icon: <CloseCircleFilled onClick={() => message.destroy("33")} />,
        })
      }
    },
  })

  const chatboxWrapperRef = useRef(null)
  useEffect(() => {
    updateMessageBoxHeight &&
      updateMessageBoxHeight(chatboxWrapperRef?.current?.clientHeight)
  }, [chatboxWrapperRef?.current?.clientHeight])

  return (
    <Wrapper ref={chatboxWrapperRef}>
      {!isGuest && state.rooms[state.currentRoomId]?.is_user_blocked && (
        <UserBlocked shake={shake}>
          <p>
            {t(
              "This user is blocked. Unblock the user to send message to the user."
            )}
          </p>
        </UserBlocked>
      )}
      {isGuest && state.rooms[state.currentRoomId]?.is_user_blocked ? null : (
        <form onSubmit={formik.handleSubmit}>
          <div className="container">
            <div className="chatbox-attach">
              <TextField
                type="textarea"
                name="text"
                value={formik.values.text}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={t("Please write your message here")}
                className="text-field form-group-field"
                onPressEnter={(e) => handleKeyboardSubmit(e, formik)}
              />
              <div className="button-wrapper">
                <Button htmlType="submit" disabled={state?.isFileUploading}>
                  送信
                </Button>
              </div>
            </div>
            <ChatFile
              sent={isMessageSent}
              updateSent={setIsMessageSent}
              fireStoreFile={file}
              updateFileToFireStore={setFile}
            />
          </div>
        </form>
      )}
    </Wrapper>
  )
}

export { MessageBox }
