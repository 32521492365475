import { BellOutlined as NotificationBell } from "@ant-design/icons"
import styled from "styled-components"

interface Props {
  count?: number
  onClick?: () => void
}

const BellOutlined = styled(NotificationBell)`
  z-index: 1000;
  height: 22px;
  width: 24px;
  cursor: pointer;

  & .ant-badge-count {
    width: 8px !important;
    height: 22px !important;
    padding: 0px, 8px, 0px, 8px;
  }
  & .ant-scroll-number {
    width: 8px !important;
    height: 22px !important;
  }
`

const NotificationBellComponent = ({ onClick }: Props) => {
  return (
    <>
      <BellOutlined onClick={onClick} />
    </>
  )
}

export { NotificationBellComponent }
