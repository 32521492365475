import { Empty, Select as SelectComp, Spin } from "antd"
import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

interface ISelect {
  width?: number | string
  height?: number
  className?: string
  allowClear?: boolean
  defaultValue?: string | string[] | number | number[]
  options?: any[]
  value?: any
  onClick?: () => void
  onChange?: any
  placeholder?: string
  loading?: boolean
  showSearch?: boolean
  mode?: "multiple" | "tags"
  onClear?: any
  showArrow?: boolean
  onSearch?: (keyword: string) => void
  style?: React.CSSProperties
}

interface props {
  width?: number | string
  height?: number
}

const Wrapper = styled.div`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: ${({ width }: props) => (width ? width + "px" : "940px")};
    height: ${({ height }: props) => (height ? height + "px" : "40px")};
    padding: 0;
    padding-left: 10px;
    padding-top: 4px;
    &:focus,
    &:hover,
    &:active {
      border-color: #8b94a5;
    }
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 0;
    padding-top: 4px;
  }
`

const HtmlBlock = ({ loading }) => {
  const { t } = useTranslation()
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={loading ? <Spin /> : t("No data")}
    />
  )
}
const SelectComponent = ({
  options,
  className,
  value,
  placeholder,
  onClick,
  onChange,
  showSearch,
  width,
  height,
  loading,
  allowClear,
  defaultValue,
  mode,
  onClear,
  showArrow,
  onSearch,
  style,
}: ISelect) => {
  return (
    <Wrapper width={width} height={height}>
      {showSearch ? (
        <SelectComp
          allowClear={allowClear}
          defaultValue={defaultValue}
          options={options}
          className={className}
          value={value}
          placeholder={placeholder}
          onClick={onClick}
          onChange={onChange}
          onSearch={onSearch}
          loading={loading}
          showSearch={showSearch}
          onClear={onClear}
          filterOption={(input, option) =>
            option?.label
              ?.toString()
              ?.toLowerCase()
              ?.indexOf(input?.toLowerCase()) >= 0
          }
          mode={mode}
          showArrow={showArrow}
          notFoundContent={<HtmlBlock loading={loading} />}
          style={style}
        />
      ) : (
        <SelectComp
          allowClear={allowClear}
          defaultValue={defaultValue}
          options={options}
          className={className}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          loading={loading}
          mode={mode}
          onClear={onClear}
          showArrow={showArrow}
          style={style}
        />
      )}
    </Wrapper>
  )
}

export { SelectComponent }
