import React, { useState } from "react"
import styled from "styled-components"
import { JobChatHeader } from "./JobChatHeader"
import { JobMessageList } from "./JobMessageList"
import { JobMessageBox } from "./JobMessageBox"
import { IJobRoom } from "../../../../services/jobChat"
import { JobChatList } from "./JobChatList"

interface IJobsChatProps {
  selectionId: string
  title: string
  room: IJobRoom
  selfId: string
  hasMore: boolean
  handleFetchMoreMessages: () => void
  handleSendEmailToReceiver?: () => void
  job?: any
  senderType?: any
  applicant?: any
  receiverType: string
  handleDeletedMessage: (id: string) => void
  handleBlockUserClick: () => void
  authUserId: string
  isCompany?: boolean
  isChatDeleted?: boolean
  memberNames?: any
}
const Wrapper = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  background-color: #f5f8ff;
  margin-left: 4px;
  & .chat-list {
    width: 300px;
  }
  & .chat-body {
    flex-grow: 1;
  }
`
const JobsChat: React.FC<IJobsChatProps> = ({
  title,
  selectionId,
  room,
  selfId,
  handleFetchMoreMessages,
  hasMore,
  job,
  applicant,
  handleSendEmailToReceiver,
  receiverType,
  senderType,
  handleDeletedMessage,
  handleBlockUserClick,
  authUserId,
  isCompany,
  isChatDeleted = false,
  memberNames,
}) => {
  const [messageBoxHeight, setMessageBoxHeight] = useState(0)
  const [chatHeaderHeight, setChatHeaderHeight] = useState(0)
  return (
    <Wrapper>
      <JobChatList isCompany={isCompany} job={job} applicant={applicant} />
      <div className="chat-body">
        <JobChatHeader
          title={title}
          is_user_blocked={room?.is_user_blocked}
          isChatDeleted={isChatDeleted}
          blocked_by={room?.blocked_by}
          authUserId={authUserId}
          updateChatHeaderHeight={setChatHeaderHeight}
          handleBlockUserClick={handleBlockUserClick}
        />
        <JobMessageList
          roomId={room?.id}
          selfId={selfId}
          senderType={senderType}
          hasMore={hasMore}
          messages={room?.messages}
          paginateEnable={room?.paginateEnable}
          messageBoxHeight={messageBoxHeight}
          chatHeaderHeight={chatHeaderHeight}
          handleFetchMoreMessages={handleFetchMoreMessages}
          handleDeletedMessage={handleDeletedMessage}
          memberNames={memberNames}
        />
        <JobMessageBox
          isChatDeleted={isChatDeleted}
          is_user_blocked={room?.is_user_blocked}
          handleSendEmailToReceiver={handleSendEmailToReceiver}
          updateMessageBoxHeight={setMessageBoxHeight}
          roomId={room?.id}
          selfId={selfId}
          receiverType={receiverType}
          senderType={senderType}
          selectionId={selectionId}
        />
      </div>
    </Wrapper>
  )
}

export { JobsChat }
