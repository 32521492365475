import React from "react"
import { Table } from "antd"
import { SpinProps } from "antd/lib/spin"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import { TableLocale, ColumnsType } from "antd/lib/table/interface"
import styled from "styled-components"
import { theme } from "../../../theme"

const TableWrapper = styled(Table)`
  font-size: 16px;
  border: 1px solid #f0f0f0;
  a {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  & thead > tr > th {
    background-color: ${theme.blue2};
    height: 50px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }

  & thead > tr > td {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: #000000;
  }
  & .ant-table-row {
    height: 64px;
  }
  & .ant-table-row:nth-child(even) {
    background-color: #f8f8f8;
  }

  & .ant-table-cell {
    padding-left: 20px !important;
  }

  & .ant-table-container table > thead > tr:first-child th:first-child,
  .ant-table-container table > tbody > tr > td:first-child {
    padding-left: 35px;
  }
`

interface TabularProps {
  key?: string
  dataSource?: Record<string, unknown>[]
  columns?: ColumnsType
  pagination?: any
  loading?: boolean | SpinProps
  size?: SizeType
  bordered?: boolean
  locale?: TableLocale
  onChange?: any
  rowSelection?: object
  scroll?: {
    x?: string | number | true
    y?: string | number
  } & {
    scrollToFirstRowOnChange?: boolean
  }
}

const TableComponent: React.FC<TabularProps> = ({
  key,
  dataSource,
  columns,
  pagination,
  loading,
  size,
  bordered,
  locale,
  onChange,
  rowSelection,
  scroll,
}) => {
  // pagination.showSizeChanger = false
  return (
    <TableWrapper
      key={key}
      dataSource={dataSource}
      rowSelection={rowSelection}
      columns={columns}
      pagination={pagination}
      loading={loading}
      size={size}
      bordered={bordered}
      locale={locale}
      onChange={onChange}
      scroll={scroll}
    />
  )
}

export { TableComponent }
