import { DeleteOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons"
import React, { useEffect, useState } from "react"
import { Spin, Upload, message, notification, Avatar } from "antd"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { fileUpload } from "../../../../services/fileupload"
import { getSignedObjectUrl } from "../../.."
interface ImageUploadProps {
  height?: string
  width?: string
  cover?: boolean
  isDefaultImage?: boolean
  onChange?: (fileName?: any, value?: any) => void
  loading?: boolean
  value?: any
  model?: string
  showLoading?: boolean
  type?: string
  pdfName?: string
  isAvatar?: boolean
  limit?: number
}

const Wrapper = styled.div`
  .upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 60px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #65b798;
    border: 1px solid grey;
    cursor: pointer;
    & svg {
      fill: white;
      height: 15px;
      width: 15px;
    }
  }

  .delete-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 15px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    border: 1px solid grey;
    background-color: white;
    cursor: pointer;
    & svg {
      fill: #cf1322;
    }
  }

  .upload-btn-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #65b798;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    & svg {
      fill: white;
      height: 15px;
      width: 15px;
    }
  }
  .delete-btn-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    margin-left: 10px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    outline: none;
    border: none;
    cursor: pointer;

    & svg {
      fill: #cf1322;
    }
  }
  .file-name {
    padding-left: 40px;
    max-width: 400px;
    height: 30px;
    & .title {
      font-size: 15px;
    }
    & .spin-class {
      word-break: break-all;
      width: 500px;
    }
  }

  &.pdf-wrapper {
    display: flex;
    align-items: center;
  }

  & .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
`

const FileUpload: React.FC<ImageUploadProps> = (props) => {
  const {
    cover = false,
    value,
    onChange,
    type,
    model,
    pdfName,
    width = "768px",
    height = "432px",
    isAvatar,
    limit = 2,
  } = props
  const [file, setFile] = useState(null)
  const [imgUrl, setImgUrl] = useState(null)
  const { t } = useTranslation()

  const { mutate, isLoading: uploadLoading } = useMutation(
    "fileUpload",
    fileUpload,
    {
      onSuccess: (result) => {
        if (type === "pdf") {
          onChange && onChange(file?.name, result?.data)
          setFile({
            url: result?.data,
            ...file,
          })
        } else {
          onChange && onChange(null, result?.data)
          setFile({
            url: result?.data,
            alt: cover ? "Cover image " : "Thumb ",
          })
        }
      },
      onError: (error?: any) => {
        const msg = error.data.error
        notification.error({
          message: msg ? t(`${msg}`) : t("An error has occurred"),
        })
      },
    }
  )

  useEffect(() => {
    setFile({
      url: value,
      alt: value && cover ? "Cover image " : "Thumb ",
    })
    return () => {
      setFile(null)
    }
  }, [value, pdfName])

  const handleDeleteClick = () => {
    setFile(null)
    onChange && onChange(null, null)
  }

  const beforeUpload = (file) => {
    if (type === "pdf") {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "image/jpeg",
        "image/png",
      ]
      const isAllowedType = allowedTypes.includes(file.type)
      const isMax10M = file.size / 1024 / 1024 <= 10

      if (!isAllowedType) {
        message.error(t("Please upload a valid file type"))
      }
      if (!isMax10M) {
        message.error(t("File must be smaller than 10 MB"))
      }
      return isAllowedType && isMax10M
    }
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
    if (!isJpgOrPng) {
      message.error(t("You can only upload JPG/PNG file!"))
    }
    if (limit == 2) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        message.error(t("Image must smaller than 2MB!"))
      }
      return isJpgOrPng && isLt2M
    }
    if (limit == 5) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        message.error(t("Image must smaller than 5MB!"))
      }
      return isJpgOrPng && isLt5M
    }
  }

  const handleChange = async (info) => {
    if (info.file.status === "done") {
      const bodyFormData = new FormData()
      bodyFormData.append("file", info?.file?.originFileObj)
      bodyFormData.append("model", model ? model : "default")
      if (type === "pdf") {
        setFile({ name: info?.file?.originFileObj?.name })
      }
      mutate(bodyFormData)
    }
  }
  const getDisplayNameFromUrl = () => {
    const fileUrl = file?.url?.split("/")
    return fileUrl.length > 0 ? fileUrl[fileUrl.length - 1] : ""
  }

  if (type === "pdf") {
    return (
      <Wrapper className="pdf-wrapper">
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/jpeg, image/png"
        >
          <div className="upload-btn-pdf">
            <UploadOutlined />
          </div>
        </Upload>
        <div className="delete-btn-pdf" onClick={handleDeleteClick}>
          <DeleteOutlined />
        </div>
        <br></br>
        <div className="file-name">
          <Spin spinning={uploadLoading} delay={500}>
            {imgUrl && (
              <a href={file?.url} target="_blank">
                <span className="title">
                  {file?.name || getDisplayNameFromUrl()}
                </span>
              </a>
            )}
          </Spin>
        </div>
      </Wrapper>
    )
  }
  useEffect(() => {
    ;(async () => {
      if (file?.url) {
        const imageUrl = await getSignedObjectUrl(file?.url)
        setImgUrl(imageUrl)
      }
    })()
    return () => {
      setImgUrl(null)
    }
  }, [file?.url])
  return (
    <Wrapper
      style={{
        position: "relative",
        width: width,
        height: height,
        background: "#E6E6E6",
        border: "1px solid #D9D9D9",
        borderRadius: "2px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Spin spinning={uploadLoading} delay={500} className="image-spin-wrapper">
        {file?.url ? (
          <img
            src={imgUrl}
            alt="Thumbnail image"
            style={{
              height: "100%",
              objectFit: "contain",
              width: "100%",
            }}
          />
        ) : isAvatar ? (
          <Avatar
            shape="square"
            size={64}
            icon={<UserOutlined />}
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          />
        ) : (
          <img
            src="/assets/images/cover-default.png"
            alt="Thumbnail image"
            style={{
              height: "100%",
              objectFit: "cover",
              width: "100%",
            }}
          />
        )}

        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          accept={"image/jpg, image/png"}
        >
          <div className="upload-btn">
            <UploadOutlined />
          </div>
        </Upload>
        <div className="delete-btn" onClick={handleDeleteClick}>
          <DeleteOutlined />
        </div>
      </Spin>
    </Wrapper>
  )
}

export { FileUpload }
