import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

interface iTextContainer {
  title: string
  content?: string | ReactNode
  subContainer?: boolean
}

const TextContaineer = styled.div`
  margin-top: 8px;
  margin-left: 8px;
  .title {
    font-size: 14px;
    font-weight: bold;
  }
  .content {
    margin-left: 5px;
    font-size: 12px;
    white-space: pre-wrap;
  }
`

const SubTextContainer = styled.div`
  margin-left: 8px;
  .title {
    font-size: 12px;
    font-weight: bold;
  }
  .content {
    margin-top: -2px;
    margin-left: 5px;
    font-size: 10px;
    white-space: pre-wrap;
  }
`
/* eslint-disable */
const Linkify = ({ children }) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g
  if (children[1]) {
    const words = children[1]?.split(urlPattern)
    const formattedChat = words?.map((word, index) => {
      if (word.match(urlPattern)) {
        // If the word is a URL, convert it to a clickable link
        return (
          <a key={index} href={word} target="_blank">
            {word}
          </a>
        )
      } else {
        return <span key={index}>{word}</span>
      }
    })
    return formattedChat
  }
  return children
}

const TextContainer: React.FC<iTextContainer> = ({
  title,
  content,
  subContainer = false,
}) => {
  const { t } = useTranslation()
  return !subContainer ? (
    <TextContaineer>
      <div className="title">{t(title)}</div>
      <div className="content">
        {content ? <Linkify> {content}</Linkify> : "-"}
      </div>
    </TextContaineer>
  ) : (
    <SubTextContainer>
      <div className="title">{t(title)}</div>
      {content ? <Linkify> {content}</Linkify> : "-"}
    </SubTextContainer>
  )
}

export { TextContainer }
