import React, { ReactNode, useEffect, useState } from "react"
import { DatePicker as AntDatePicker, ConfigProvider } from "antd"
import moment from "moment"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { theme } from "../../../theme"
import { i18n } from "../../../i18n"
import jaJP from "antd/lib/locale/ja_JP"
import "moment/locale/ja"

interface DatePickerProps {
  value?: any
  format?: string
  onChange?: (val: string, date?: any) => void
  onOpenChange?: (val: any) => void
  size?: "large" | "small" | "default"
  bordered?: boolean
  className?: string
  name?: string
  disabledDate?: any
  label?: string
  range?: boolean
  showTime?: boolean
  suffixIcon?: ReactNode
  clearIcon?: ReactNode
  error?: any
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  id?: string
  required?: boolean
  separator?: string
  placeholder?: any
  allowClear?: boolean
  defaultValue?: any
  defaultPickerValue?: any
  minuteStep?: any
  picker?: any
  style?: any
  noErrorText?: boolean
}

const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 2px;
`

const Label = styled.div`
  ${theme.typography.PackDesc};
  margin-right: 10px;
`

const RequiredLabel = styled.div`
  ${theme.typography.PackDesc};
  line-height: 20px;
  color: #f5222d;
  min-width: 40px;
  display: flex;
  justify-content: center;
  height: 22px;
  background: #fff1f0;
  border: 1px solid #ffa39e;
  border-radius: 2px;
`

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    value,
    onChange,
    format = "YYYY/MM/DD",
    size,
    bordered,
    className,
    name,
    label,
    required,
    range = false,
    showTime,
    disabledDate,
    suffixIcon,
    separator,
    placeholder,
    allowClear,
    clearIcon,
    defaultValue,
    defaultPickerValue,
    minuteStep,
    picker,
    style,
    noErrorText,
  } = props
  const { t } = useTranslation()
  const { RangePicker } = AntDatePicker
  const [currentDate, setCurrentDate] = useState<moment.Moment>()
  const [rangeDate, setRangeDate] = useState()
  useEffect(() => {
    onChange && onChange(currentDate?.format(format))
  }, [currentDate])

  useEffect(() => {
    onChange && onChange(rangeDate)
  }, [rangeDate])

  const handleDateChange = (date: any) => {
    if (range) {
      setRangeDate(date)
      return
    }
    setCurrentDate(date)
  }
  return (
    <>
      {label && (
        <LabelContainer>
          <Label>{label}</Label>
          {required && <RequiredLabel>必須</RequiredLabel>}
        </LabelContainer>
      )}
      {!range ? (
        <ConfigProvider locale={i18n.language === "ja" && jaJP}>
          <AntDatePicker
            className={className}
            size={size === "default" ? null : size}
            bordered={bordered}
            value={value && moment(value, format)}
            format={format}
            showTime={showTime}
            disabledDate={disabledDate}
            onChange={handleDateChange}
            placeholder={
              placeholder || moment(moment.now()).format("YYYY/MM/DD")
            }
            picker={picker}
            name={name}
            suffixIcon={
              suffixIcon ? (
                suffixIcon
              ) : (
                <img src="/assets/icons/date.svg" alt="datepicker icon" />
              )
            }
            allowClear={allowClear}
            style={style}
          />
        </ConfigProvider>
      ) : (
        <ConfigProvider locale={i18n.language === "ja" && jaJP}>
          <RangePicker
            allowClear={allowClear}
            className={className}
            size={size === "default" ? null : size}
            bordered={bordered}
            format={format}
            showTime={showTime}
            name={name}
            clearIcon={clearIcon}
            suffixIcon={suffixIcon}
            value={value}
            onChange={handleDateChange}
            id={props.id}
            onOpenChange={props.onOpenChange}
            placeholder={[t("Start Date"), t("End Date")]}
            separator={separator}
            defaultPickerValue={defaultPickerValue}
            defaultValue={defaultValue}
            minuteStep={minuteStep}
            style={style}
          />
        </ConfigProvider>
      )}
      {!noErrorText && (
        <>
          <div style={{ clear: "both" }}> </div>
          {props.error != "" && (
            <p
              style={{
                fontSize: "12px",
                color: "#EB5757",
                marginTop: " 2px",
                marginLeft: "2px",
              }}
            >
              {t(props.error)}
            </p>
          )}
        </>
      )}
    </>
  )
}

export type { DatePickerProps }

export { DatePicker }
