export const CharacterCounter = (str) => {
  let point
  let index
  let width = 0
  let len = 0
  for (index = 0; index < str.length; ) {
    point = str.codePointAt(index)
    width = 0
    while (point) {
      width += 1
      point = point >> 8
    }
    index += Math.round(width / 2)
    len += 1
  }
  return len.toLocaleString("en-US")
}

export const limitCharacters = (str: string, limit: number) =>
  str.length > limit ? `${str.substring(0, limit)}...` : str

export const convertGenderStringInverse = (str: string) => {
  const genderTypeInverse = {
    男CH: "男性",
    男性が好ましいCH: "男性が好ましい",
    不問CH: "不問",
    女CH: "女性",
    女性が好ましいCH: "女性が好ましい",
  }
  if (!str) return ""
  const strArr = str.split(",")
  if (strArr.length > 0) {
    let f = ""
    let iterations = strArr.length
    for (const v of strArr) {
      f = f + genderTypeInverse[v] || ""
      if (--iterations) {
        f = f + ","
      }
    }
    return f
  }
  return ""
}
