export const chatPageSize = 15
export const chatRoomSize = 10
export const getTopic = {
  求人について: "About Job",
  候補者について: "About candidates",
  システム利用について: "About systems",
  その他: "Others",
  salesagentおすすめ求人配信: "Recommended job postings",
  エージェントを検索: "Search Agents",
}

export const getJobTitle = (job) => {
  const companyName = job?.agent_company?.name
    ? job?.agent_company?.name
    : job?.company?.name
  const onePhrase = job?.one_phrase
  return `${companyName} ${onePhrase}`
}
