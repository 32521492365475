import {
  CloseCircleFilled,
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import React, { useEffect, useState } from "react"
import { Upload, message, notification, Spin } from "antd"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { fileUpload } from "../../../../services/fileupload"
import { API } from "../../../api"
interface FileUploadProps {
  onChange?: (fileName?: string, value?: any) => void
  loading?: boolean
  value?: any
  model?: string
  showLoading?: boolean
  type?: string
  pdfName?: string
  label?: string
}

const Wrapper = styled.div`
  margin-bottom: 2.5rem;
  .upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 60px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #1890ff;
    border: 1px solid grey;
    cursor: pointer;
    & svg {
      fill: white;
      height: 15px;
      width: 15px;
    }
  }

  .delete-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 15px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    border: 1px solid grey;
    background-color: white;
    cursor: pointer;
    & svg {
      fill: #cf1322;
    }
  }

  .upload-btn-pdf,
  .download-btn-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #1890ff;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    & svg {
      fill: white;
      height: 15px;
      width: 15px;
    }
  }
  .download-btn-pdf {
    background-color: gray;
    margin: 0 8px;
  }
  .delete-btn-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    margin-left: 2px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    outline: none;
    border: none;
    cursor: pointer;

    & svg {
      fill: #cf1322;
    }
  }
  & .title {
    font-size: 15px;
  }
  .file-name {
    max-width: 400px;
    margin-left: 15px;
    & .spin-class {
      word-break: break-all;
      width: 500px;
    }
  }

  &.pdf-wrapper {
    display: flex;
    align-items: center;
  }

  & .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
  .pdf-lable {
    font-weight: 700;
    font-size: 18px;
  }
  .text-required {
    display: block;
    color: #f00;
    font-size: 11px;
  }
`

const PdfFileUpload: React.FC<FileUploadProps> = (props) => {
  const { value, onChange, type, model, pdfName } = props
  const [file, setFile] = useState(null)
  const { t } = useTranslation()

  const { mutate, isLoading: uploadLoading } = useMutation(
    "fileUpload",
    fileUpload,
    {
      onSuccess: (result) => {
        if (type === "pdf") {
          onChange && onChange(file?.name, result?.data)
          setFile({
            name: file?.name,
            url: result?.data,
            ...file,
          })
        } else {
          onChange && onChange(null, result?.data)
          setFile({
            url: result?.data,
            alt: "Thumb",
          })
        }
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg ? t(`${msg}`) : t("An error has occurred"),
        })
      },
    }
  )

  useEffect(() => {
    if (type === "pdf") {
      setFile({ url: value, name: pdfName })
    } else {
      setFile({
        url: value,
        alt: "Thumb",
        name: pdfName,
      })
    }
  }, [value, pdfName])

  const handleDeleteClick = () => {
    setFile(null)
    onChange && onChange(null, null)
  }

  const getSignedObjectUrl = async (url: string) => {
    try {
      const params = {
        object: url,
      }
      const response: any = await API.get("/utils/get-signed-url", { params })
      if (response?.data?.signed_url) {
        return response?.data?.signed_url
      }
      return url
    } catch (error) {
      message.error({
        content:
          error?.message ||
          error?.data?.error?.message ||
          "Something went wrong",
        key: "37",
        icon: <CloseCircleFilled onClick={() => message.destroy("37")} />,
      })
    }
  }

  const handleDownloadClick = async () => {
    const file_url = await getSignedObjectUrl(file?.url)
    const req = new XMLHttpRequest()
    req.open("GET", file_url, true)
    req.responseType = "blob"
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/octet-stream",
      })
      const ref = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.setAttribute("id", "pdfDownload")
      link.setAttribute("download", pdfName)
      link.setAttribute("href", ref)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    }
    req.send()
  }

  const beforeUpload = (file) => {
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
    ]
    const isAllowedType = allowedTypes.includes(file.type)
    const isMax10M = file.size / 1024 / 1024 <= 10

    if (!isAllowedType) {
      message.error({
        content: t("Please upload a valid file type"),
        key: "fileType",
        icon: <CloseCircleFilled onClick={() => message.destroy("fileType")} />,
      })
    }
    if (!isMax10M) {
      message.error({
        content: t("File must be smaller than 10 MB"),
        key: "fileSize",
        icon: <CloseCircleFilled onClick={() => message.destroy("fileSize")} />,
      })
    }
    return isAllowedType && isMax10M
  }

  const handleChange = async (info) => {
    const { status, name } = info.file
    setFile({ name: name })
    if (status === "done") {
      const bodyFormData = new FormData()
      bodyFormData.append("file", info?.file?.originFileObj)
      bodyFormData.append("model", model ? model : "temp")
      if (type === "pdf") {
        setFile({ name: info?.file?.originFileObj?.name })
      }
      mutate(bodyFormData)
    }
  }
  const getDisplayNameFromUrl = () => {
    const fileUrl = file?.url?.split("/")
    return fileUrl?.length > 0 ? fileUrl[fileUrl.length - 1] : ""
  }
  return (
    <Wrapper className="pdf-wrapper">
      <Upload
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/jpeg, image/png"
      >
        <div className="upload-btn-pdf">
          <PlusOutlined />
        </div>
      </Upload>
      {value && (
        <>
          <div className="download-btn-pdf" onClick={handleDownloadClick}>
            <DownloadOutlined />
          </div>
          <div className="delete-btn-pdf" onClick={handleDeleteClick}>
            <DeleteOutlined />
          </div>
        </>
      )}
      <div className="file-name">
        <Spin spinning={uploadLoading} delay={500}>
          {file?.url && (
            <div className="title">{file?.name || getDisplayNameFromUrl()}</div>
          )}
        </Spin>
      </div>
    </Wrapper>
  )
}

export { PdfFileUpload }
