import { API } from ".."
import { PDFDocument } from "pdf-lib"

export const getSignedObjectUrl = (url: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const params = {
      object: url,
    }
    API.get("/utils/get-signed-url", { params })
      .then((response) => {
        if (response?.data?.signed_url) {
          resolve(response?.data?.signed_url)
        } else {
          resolve(null)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const clearMetaFromPDF = async (file: File) => {
  const fileBuffer = await file.arrayBuffer()
  const pdfDoc = await PDFDocument.load(fileBuffer, {
    updateMetadata: true,
  })
  pdfDoc.setAuthor("")
  pdfDoc.setTitle("")
  pdfDoc.setSubject("")
  pdfDoc.setKeywords([])
  pdfDoc.setProducer("")
  pdfDoc.setCreator("")
  const modifiedPdfBlob = await pdfDoc.save()
  const updatedFile = new File([modifiedPdfBlob], "file.pdf", {
    type: "application/pdf",
  })
  return updatedFile
}

export const downloadPdfFile = (file_url, pdfName) => {
  const req = new XMLHttpRequest()
  req.open("GET", file_url, true)
  req.responseType = "blob"
  req.onload = function () {
    const blob = new Blob([req.response], { type: "application/pdf" })
    const ref = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.setAttribute("id", "pdfDownload")
    link.setAttribute("download", pdfName)
    link.setAttribute("href", ref)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }
  req.send()
}
