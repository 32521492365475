import {
  CloseCircleFilled,
  DeleteOutlined,
  DownloadOutlined,
  UserOutlined,
} from "@ant-design/icons"
import React, { useEffect } from "react"
import { useChatContext } from "../../../contexts/chat/ChatContext"
import styled from "styled-components"
import { Avatar, message, notification, Popconfirm, Space } from "antd"
import moment from "moment"
import { API } from "../../../api"
import { t } from "i18next"
import {
  chatMessageDeleteListener,
  deleteFirestoreDocument,
} from "../../../chatServices"
import { Linkify } from "../JobsChat/JobMessageItem"

export interface IMessageItem {
  id: string
  sender_id: string
  content: string
  file_url: string
  file_name: string
  created_at: string
  handleDeletedMessage: (id: string) => void
}

const Wrapper = styled.div<{ isSelf: boolean }>`
  align-self: ${({ isSelf }) => (isSelf ? "flex-end" : "flex-start")};
  width: 450px;
  max-width: 100%;
  display: flex;
  gap: 12px;
  justify-content: ${({ isSelf }) => (isSelf ? "flex-end" : "flex-start")};
  @media (max-width: 901px) {
    padding: 0;
  }
  & .msg-content {
    display: flex;
    flex-direction: column;
    align-items: ${({ isSelf }) => (isSelf ? "flex-end" : "flex-start")};
    & .time {
      font-size: 11px;
      margin-top: 3px;
      color: #969090;
    }
    & .img-wrapper {
      max-width: 200px;
      height: 160px;
      background: black;
      margin: 6px 0 2px;
      img {
        width: 100%;
        height: 160px;
        object-fit: contain;
        background-color: #fff;
      }
    }
    & .text-content {
      max-width: 400px;
      font-size: 16px;
      margin-bottom: 0;
      white-space: pre-line;
      word-break: break-all;
      @media (max-width: 600px) {
        max-width: 300px;
      }
      @media (max-width: 400px) {
        max-width: 100%;
      }
      background-color: ${({ isSelf }) => (isSelf ? "#4BE232" : "#d5d5d5")};
      padding: 8px;
      border-radius: 8px;
      position: relative;
    }
    & .pdf-content {
      margin-top: 6px;
      font-size: 22px;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.28);
      border-radius: 4px;
      padding: 8px;
      .pdf-file {
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% - 30px);
      }
    }
  }
  .ant-avatar {
    background-color: #fff;
    border: 1px solid #262626;
    font-size: 16px;
    .anticon {
      filter: invert(1);
    }
  }
`

const MessageItem: React.FC<IMessageItem> = ({
  sender_id,
  content,
  file_url,
  file_name,
  created_at,
  id,
  handleDeletedMessage,
}) => {
  const { state } = useChatContext()
  const roomId = state.currentRoomId

  const getSignedObjectUrl = async (url: string) => {
    try {
      const params = {
        object: url,
      }
      const response: any = await API.get("/utils/get-signed-url", { params })
      if (response?.data?.signed_url) {
        return response?.data?.signed_url
      }
      return url
    } catch (error) {
      message.error({
        content:
          error?.message ||
          error?.data?.error?.message ||
          "Something went wrong",
        key: "32",
        icon: <CloseCircleFilled onClick={() => message.destroy("32")} />,
      })
    }
  }

  const handleDownloadClick = async (url) => {
    const file_url = await getSignedObjectUrl(url)
    const req = new XMLHttpRequest()
    req.open("GET", file_url, true)
    req.responseType = "blob"
    req.onload = function () {
      const blob = new Blob([req.response], { type: "application/pdf" })
      const ref = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.setAttribute("id", "pdfDownload")
      link.setAttribute("download", file_name)
      link.setAttribute("href", ref)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    }
    req.send()
  }

  const deleteMessage = async (id) => {
    try {
      await deleteFirestoreDocument({
        collection_name: "rooms",
        room_id: roomId,
        id,
      })
    } catch (error) {
      notification.error({ message: "message not found" })
    }
  }

  useEffect(() => {
    if (roomId && handleDeletedMessage) {
      chatMessageDeleteListener(roomId, (id) => {
        handleDeletedMessage(id)
      })
    }
  }, [roomId, id])

  return (
    <Wrapper isSelf={state.user.id === sender_id ? true : false}>
      {state.user.id === sender_id ? null : <Avatar icon={<UserOutlined />} />}
      <div className="msg-content">
        {content && (
          <div className="text-content">
            <Linkify>{content}</Linkify>
          </div>
        )}
        {file_name && file_url ? (
          <div className="pdf-content">
            <div onClick={() => handleDownloadClick(file_url)}>
              <DownloadOutlined />
              <span className="pdf-file">{file_name}</span>
            </div>
          </div>
        ) : null}
        <div className="time">
          {moment(created_at).format("YYYY年MM月DD日 HH時mm分")}
          {state.user.id === sender_id &&
            !state.user.id.includes("applicant_") && (
              <Space>
                &nbsp;
                <Popconfirm
                  title={t("Are you sure you want to delete this message?")}
                  onConfirm={() => deleteMessage(id)}
                  onCancel={null}
                  okText={t("Yes")}
                  cancelText={t("No")}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Space>
            )}
        </div>
      </div>
    </Wrapper>
  )
}

export { MessageItem }
