import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { SectionTitle } from "../../../atom/JobDetail/SectionTitle"
import { JobDetailTextBlock } from "../JobDetailTextBlock"

const Wrapper = styled.div`
  margin-bottom: 30px;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 8px;
`

const JobOverview = ({ job }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <SectionTitle title={t("Job overview")} />
      <JobDetailTextBlock job={job} />
    </Wrapper>
  )
}

export { JobOverview }
