import { t } from "i18next"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import JobOfferPointBlock from "../../../atom/JobDetail/JobOfferPointBlock"
import { JobSectionBlock } from "../JobSectionBlock"
import { getSignedObjectUrl } from "../../../../utility/file"
import { GetTopicsLabel } from "../../../../constants"
// import { InformationToAgent } from "../InformationToAgent"
import { InformationToAgent } from "../InformationToAgent"
import { Tag } from "antd"

// import { SelectionFlow } from "../SelectionFlow"
// import dynamic from "next/dynamic"

// const ChartComponent = dynamic(
//   () => import("../../JobStatusChart/index").then((mod) => mod.JobStatusChart),
//   { ssr: false }
// )

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 20px;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 8px;

  .interview {
    display: flex;
    width: 100%;
  }
  .interview-details {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    width: 100%;
  }
  .interview-image {
    width: 100%;
    background-color: #f0f2f5;
  }
  .image {
    width: 100%;
    height: 358px;
    object-fit: contain;
    object-position: center;
  }
`
const HorizontalWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const JobOfferPoint = ({
  jobData,
  isCompany,
  //  jobStatusCount
}) => {
  const [signedObject, setSignedObject] = useState("")
  const [indexLabel, setIndexLabel] = useState<any>()
  const interview_image = jobData?.job_overview?.interview_image

  useEffect(() => {
    let index = 1
    const indexValue = {}
    if (
      jobData?.job_offer_point?.qualification_requirement?.info ||
      jobData?.job_offer_point?.qualification_requirement?.skill ||
      jobData?.job_offer_point?.qualification_requirement?.experience
    ) {
      indexValue["entry_requirement"] = index
      index = index + 1
    }
    if (
      jobData?.job_overview?.business_details ||
      jobData?.job_overview?.work_details
    ) {
      indexValue["work_content"] = index
      index = index + 1
    }
    if (
      jobData?.topic?.characteristics?.length > 0 ||
      jobData?.topic?.thoughtfulness?.length > 0 ||
      jobData?.topic?.recruitment_features?.length > 0 ||
      jobData?.topic?.workplace_features?.length > 0 ||
      jobData?.topic?.work_features?.length > 0 ||
      jobData?.topic?.working_type?.length > 0 ||
      jobData?.topic?.company_type?.length > 0
    ) {
      indexValue["topics"] = index
      index = index + 1
    }
    if (
      jobData?.job_offer_point?.qualification_requirement?.workstyl ||
      jobData?.job_overview?.holidays_and_welfares ||
      (jobData?.job_overview?.work_time?.from &&
        jobData?.job_overview?.work_time?.to)
    ) {
      indexValue["employment_condition"] = index
      index = index + 1
    }
    if (
      jobData?.job_offer_point?.message_from_hr.info ||
      jobData?.job_offer_point?.message_from_hr.career_plan ||
      jobData?.job_offer_point?.informal_information ||
      jobData?.point_of_recommendation ||
      jobData?.job_overview?.interview ||
      jobData?.job_overview?.interview_image
    ) {
      indexValue["recruiting_company"] = index
      index = index + 1
    }
    if (
      (jobData?.job_offer_point?.message_from_hr?.selection_flow &&
        jobData?.job_offer_point?.message_from_hr?.selection_flow?.length >
          0) ||
      jobData?.job_offer_point?.message_from_hr?.document_screening ||
      jobData?.job_offer_point?.message_from_hr?.reason_of_rejection ||
      jobData?.interview?.first_interview ||
      jobData?.interview?.second_interview ||
      jobData?.interview?.final_interview ||
      jobData?.interview?.selection ||
      jobData?.job_overview?.recruiter_comment ||
      jobData?.job_overview?.looking_for_people_like_this
    ) {
      indexValue["sales_info"] = index
      index = index + 1
    }
    setIndexLabel(indexValue)

    if (interview_image) {
      ;(async () => {
        const imageUrl = await getSignedObjectUrl(interview_image)
        setSignedObject(imageUrl)
      })()
    }
  }, [jobData])

  return (
    <Wrapper>
      {!isCompany && <InformationToAgent job={jobData} />}
      {indexLabel?.entry_requirement && (
        <JobSectionBlock
          index={indexLabel?.entry_requirement}
          title={t("Entry requirements")}
        />
      )}
      <HorizontalWrapper>
        {jobData?.job_offer_point?.qualification_requirement?.info && (
          <JobOfferPointBlock
            headingOne={t("Qualification requirements")}
            bodyOne={jobData?.job_offer_point?.qualification_requirement?.info}
          />
        )}
        {jobData?.job_offer_point?.qualification_requirement?.skill && (
          <JobOfferPointBlock
            hideRight={true}
            headingOne={t("Skill")}
            bodyOne={jobData?.job_offer_point?.qualification_requirement?.skill}
          />
        )}
        {jobData?.job_offer_point?.qualification_requirement?.experience && (
          <JobOfferPointBlock
            headingOne={t("Experience")}
            bodyOne={
              jobData?.job_offer_point?.qualification_requirement?.experience
            }
          />
        )}
      </HorizontalWrapper>
      {(jobData?.job_overview?.business_details ||
        jobData?.job_overview?.work_details) && (
        <JobSectionBlock
          index={indexLabel?.work_content}
          title={t("Work/Business content")}
        />
      )}
      {jobData?.job_overview?.business_details && (
        <JobOfferPointBlock
          headingOne={t("Business Detail")}
          hideRight={true}
          bodyOne={jobData?.job_overview?.business_details}
        />
      )}
      {jobData?.job_overview?.work_details && (
        <JobOfferPointBlock
          headingOne={t("Work details")}
          hideRight={true}
          bodyOne={jobData?.job_overview?.work_details}
        />
      )}
      {indexLabel?.topics && (
        <div data-html2canvas-ignore={true}>
          <JobSectionBlock index={indexLabel?.topics} title={t("Topics")} />
          <HorizontalWrapper>
            {jobData?.topic?.characteristics &&
            jobData?.topic?.characteristics.length > 0 ? (
              <JobOfferPointBlock
                headingOne={t("Characteristics")}
                hideRight={true}
                tags={GetTopicsLabel(jobData?.topic?.characteristics)}
              />
            ) : null}
            {jobData?.topic?.thoughtfulness &&
            jobData?.topic?.thoughtfulness.length > 0 ? (
              <JobOfferPointBlock
                headingOne={t("Thoughtfulness")}
                hideRight={true}
                tags={GetTopicsLabel(jobData?.topic?.thoughtfulness)}
              />
            ) : null}
          </HorizontalWrapper>
          <HorizontalWrapper>
            {jobData?.topic?.working_type &&
            jobData?.topic?.working_type.length ? (
              <JobOfferPointBlock
                headingOne={t("Working type")}
                hideRight={true}
                tags={GetTopicsLabel(jobData?.topic?.working_type)}
              />
            ) : null}
            {jobData?.topic?.company_type &&
            jobData?.topic?.company_type.length ? (
              <JobOfferPointBlock
                headingOne={t("Company type")}
                hideRight={true}
                tags={GetTopicsLabel(jobData?.topic?.company_type)}
              />
            ) : null}
          </HorizontalWrapper>
          <HorizontalWrapper>
            {jobData?.topic?.recruitment_features &&
            jobData?.topic?.recruitment_features.length ? (
              <JobOfferPointBlock
                headingOne={t("Recruitment features")}
                hideRight={true}
                tags={GetTopicsLabel(jobData?.topic?.recruitment_features)}
              />
            ) : null}
            {jobData?.topic?.workplace_features &&
            jobData?.topic?.workplace_features.length ? (
              <JobOfferPointBlock
                headingOne={t("Workplace features")}
                hideRight={true}
                tags={GetTopicsLabel(jobData?.topic?.workplace_features)}
              />
            ) : null}
          </HorizontalWrapper>
          <HorizontalWrapper>
            {jobData?.topic?.work_features &&
            jobData?.topic?.work_features.length ? (
              <JobOfferPointBlock
                headingOne={t("Work features")}
                hideRight={true}
                tags={GetTopicsLabel(jobData?.topic?.work_features)}
              />
            ) : null}
            {jobData?.topic?.company_features &&
            jobData?.topic?.company_features.length ? (
              <JobOfferPointBlock
                headingOne={t("Company features")}
                hideRight={true}
                tags={GetTopicsLabel(jobData?.topic?.company_features)}
              />
            ) : null}
          </HorizontalWrapper>
        </div>
      )}
      {indexLabel?.employment_condition && (
        <JobSectionBlock
          index={indexLabel?.employment_condition}
          title={t("Employment conditions")}
        />
      )}
      {jobData?.job_offer_point?.qualification_requirement?.workstyle && (
        <JobOfferPointBlock
          headingOne={t("Work style")}
          hideRight={true}
          bodyOne={
            jobData?.job_offer_point?.qualification_requirement?.workstyle
          }
        />
      )}
      {jobData?.job_overview?.holidays_and_welfares && (
        <JobOfferPointBlock
          headingOne={t("Holidays and welfares")}
          hideRight={true}
          bodyOne={jobData?.job_overview?.holidays_and_welfares}
        />
      )}
      {indexLabel?.recruiting_company && (
        <JobSectionBlock
          index={indexLabel?.recruiting_company}
          title={t("Information from recruiting Companies")}
        />
      )}
      {jobData?.job_offer_point?.message_from_hr.info && (
        <JobOfferPointBlock
          headingOne={t("Message from HR")}
          hideFull={true}
          bodyOne={jobData?.job_offer_point?.message_from_hr?.info}
        />
      )}
      <HorizontalWrapper>
        {jobData?.job_offer_point?.message_from_hr?.career_plan && (
          <JobOfferPointBlock
            headingOne={t("Career plan")}
            hideRight={true}
            bodyOne={jobData?.job_offer_point?.message_from_hr?.career_plan}
          />
        )}
        {jobData?.job_offer_point?.informal_information && (
          <JobOfferPointBlock
            headingOne={t("Informal candidate information")}
            hideFull={true}
            bodyOne={jobData?.job_offer_point?.informal_information}
          />
        )}
      </HorizontalWrapper>
      <div className="interview">
        <div className="interview-details">
          {jobData?.point_of_recommendation && (
            <JobOfferPointBlock
              headingOne={t("Points for recommendation/ important point")}
              bodyOne={jobData?.point_of_recommendation}
            />
          )}
          {jobData?.job_overview?.interview && (
            <JobOfferPointBlock
              headingOne={t("Interview")}
              hideRight={true}
              bodyOne={jobData?.job_overview?.interview}
            />
          )}
        </div>
        {jobData?.job_overview?.interview_image && (
          <div className="interview-image">
            <img src={signedObject} alt="interview-image" className="image" />
          </div>
        )}
      </div>
      {!isCompany && (
        <>
          {" "}
          {indexLabel?.sales_info && (
            <>
              <JobSectionBlock
                index={indexLabel?.sales_info}
                html2canvas_ignore={
                  jobData?.job_offer_point?.message_from_hr?.selection_flow &&
                  jobData?.job_offer_point?.message_from_hr?.selection_flow
                    ?.length > 0
                }
                title={t("Sales agent limited information")}
              />
              <div data-html2canvas-ignore={true}>
                <Tag
                  color={"error"}
                  style={{ marginLeft: "2rem", padding: "0 0.3rem" }}
                >
                  {
                    "限定情報になりますので、外部及び求職者への展開はご遠慮ください"
                  }
                </Tag>
              </div>
            </>
          )}
          {/* </div> */}
          {jobData?.job_offer_point?.message_from_hr?.selection_flow &&
          jobData?.job_offer_point?.message_from_hr?.selection_flow.length >
            0 ? (
            <JobOfferPointBlock
              headingOne={t("Selection flow")}
              hideRight={true}
              tags={jobData?.job_offer_point?.message_from_hr?.selection_flow}
            />
          ) : null}
          <div data-html2canvas-ignore={true}>
            {
              <HorizontalWrapper>
                {jobData?.job_offer_point?.message_from_hr
                  ?.document_screening && (
                  <JobOfferPointBlock
                    headingOne={t("Example of selection screening")}
                    hideRight={true}
                    bodyOne={
                      jobData?.job_offer_point?.message_from_hr
                        ?.document_screening
                    }
                  />
                )}
                {jobData?.job_offer_point?.message_from_hr
                  ?.reason_of_rejection && (
                  <JobOfferPointBlock
                    headingOne={t("Example of interview rejection")}
                    hideRight={true}
                    bodyOne={
                      jobData?.job_offer_point?.message_from_hr
                        ?.reason_of_rejection
                    }
                  />
                )}
              </HorizontalWrapper>
            }
            {(jobData?.screening?.selection_point ||
              jobData?.screening?.ng_reason) && (
              <JobOfferPointBlock
                headingOne={t("Document screening")}
                hideRight={true}
                subHeadingOne={t("Selection")}
                bodyOne={jobData?.screening?.selection_point}
                subHeadingTwo={t("Not good reason")}
                bodyTwo={jobData?.screening?.ng_reason}
              />
            )}
          </div>
          <div data-html2canvas-ignore={true}>
            {jobData?.interview?.first_interview &&
              (jobData?.interview?.first_interview.question ||
                jobData?.interview?.first_interview.selection_point ||
                jobData?.interview?.first_interview.ng_reason) && (
                <>
                  <JobOfferPointBlock
                    headingOne={t("First interview")}
                    hideRight={true}
                    subHeadingOne={t("Question")}
                    bodyOne={jobData?.interview?.first_interview?.question}
                    subHeadingTwo={t("Selection")}
                    bodyTwo={
                      jobData?.interview?.first_interview?.selection_point
                    }
                    subHeadingThree={t("Not good reason")}
                    bodyThree={jobData?.interview?.first_interview?.ng_reason}
                  />
                </>
              )}
            {jobData?.interview?.second_interview &&
              (jobData?.interview?.second_interview.question ||
                jobData?.interview?.second_interview.selection_point ||
                jobData?.interview?.second_interview.ng_reason) && (
                <>
                  <JobOfferPointBlock
                    headingOne={t("Second interview")}
                    hideRight={true}
                    subHeadingOne={t("Question")}
                    bodyOne={jobData?.interview?.second_interview?.question}
                    subHeadingTwo={t("Selection")}
                    bodyTwo={
                      jobData?.interview?.second_interview?.selection_point
                    }
                    subHeadingThree={t("Not good reason")}
                    bodyThree={jobData?.interview?.second_interview?.ng_reason}
                  />
                </>
              )}
            {jobData?.interview?.selection &&
              (jobData?.interview?.selection.question ||
                jobData?.interview?.selection.selection_point ||
                jobData?.interview?.selection.ng_reason) && (
                <>
                  <JobOfferPointBlock
                    headingOne={t("Third selection")}
                    hideRight={true}
                    subHeadingOne={t("Question")}
                    bodyOne={jobData?.interview?.selection?.question}
                    subHeadingTwo={t("Selection")}
                    bodyTwo={jobData?.interview?.selection?.selection_point}
                    subHeadingThree={t("Not good reason")}
                    bodyThree={jobData?.interview?.selection?.ng_reason}
                  />
                </>
              )}
            {jobData?.interview?.final_interview &&
              (jobData?.interview?.final_interview.question ||
                jobData?.interview?.final_interview.selection_point ||
                jobData?.interview?.final_interview.ng_reason) && (
                <JobOfferPointBlock
                  headingOne={t("Final interview")}
                  hideRight={true}
                  subHeadingOne={t("Question")}
                  bodyOne={jobData?.interview?.final_interview?.question}
                  subHeadingTwo={t("Selection")}
                  bodyTwo={jobData?.interview?.final_interview?.selection_point}
                  subHeadingThree={t("Not good reason")}
                  bodyThree={jobData?.interview?.final_interview?.ng_reason}
                />
              )}
          </div>
          <div data-html2canvas-ignore={true}>
            {jobData?.job_overview?.recruiter_comment && (
              <>
                <JobOfferPointBlock
                  headingOne={t("Recruiter Comment")}
                  bodyOne={jobData?.job_overview?.recruiter_comment}
                  hideRight={true}
                />
              </>
            )}
            {jobData?.job_overview?.looking_for_people_like_this && (
              <>
                <JobOfferPointBlock
                  headingOne={t("Looking for people like this")}
                  bodyOne={jobData?.job_overview?.looking_for_people_like_this}
                  hideRight={true}
                />
              </>
            )}
          </div>
        </>
      )}
    </Wrapper>
  )
}

export { JobOfferPoint }
