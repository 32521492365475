import { t } from "i18next"
export enum CompanyType {
  COMPANY = "company",
  AGENT_COMPANY = "agent_company",
}
export const chatFilterOptions = [
  { label: t("Search Agents"), value: "エージェントを検索" },
  {
    label: t("Recommended job postings"),
    value: "salesagentおすすめ求人配信",
  },
  { label: t("About Job"), value: "求人について" },
  { label: t("About candidates"), value: "候補者について" },
  { label: t("About systems"), value: "システム利用について" },
  { label: t("Others"), value: "その他" },
]
