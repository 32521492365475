import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { addhttp } from "../../../../utils"
import {
  CloseCircleFilled,
  CloseOutlined,
  DownloadOutlined,
  MenuOutlined,
} from "@ant-design/icons"
import { message, Tabs, Tag } from "antd"
import styled from "styled-components"
import { downloadPdfFile, getSignedObjectUrl, theme } from "../../.."
import dynamic from "next/dynamic"
import moment from "moment"
import { ArrayTranslate } from "../../molecules/JobDetail/TopSectionCard"
import { JobTypeTranslation } from "../../../../utils/jobTypeTranslation"

const Button = dynamic(
  () => import("../../atom/Button/index").then((mod) => mod.ButtonComponent),
  { ssr: false }
)

interface ChatListProps {
  activeTab?: string
  job?: any
  applicant?: any
  isCompany?: boolean
}

const HamburgerMenu = styled.div`
  position: fixed;
  z-index: 2;
  left: 20px;
  top: 9px;
  font-size: 20px;
  display: none;
  cursor: pointer;
  @media (max-width: 990px) {
    display: block;
  }
  .hidden {
    display: none;
  }
`
const Header = styled.div`
  display: flex;
  h3 {
    margin-top: 0 !important;
    margin-bottom: 0;
  }
  .dash {
    height: 26px;
    width: 5px;
    background-color: rgb(0, 22, 40);
    margin-right: 7px;
  }
`
const Wrapper = styled.div`
  background: #fff;
  padding: 20px 0 0 0;
  width: 415px;
  transform: translateX(0);
  transition: transform 0.3s linear 0s;
  border-right: 1px solid;
  @media (max-width: 990px) {
    width: 100%;
    padding: 0 20px 0 20px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 47px;
    &.hidden {
      transform: translateX(-100%);
    }
  }
  .ant-tabs-tab {
    padding: 15px 48px 6px 45px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .ant-tabs-content-holder {
    height: calc(100vh - 136px);
    overflow: auto;
  }
  .ant-tabs-content {
    padding: 1rem;
    .title-header {
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      &:first-child {
        margin-top: 0;
      }
    }
    .less-margin {
      margin-top: 1rem;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      padding-right: 10px;
      margin-top: 1rem;
    }
    .tag-wrapper {
      margin-top: 10px;
    }
    p {
      word-break: break-word;
      .label {
        color: #ccc;
        display: block;
      }
      .d-block {
        display: block;
      }
    }
  }
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }
  .info-wrapper {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .info {
      display: flex;
      align-items: baseline;
      .label {
        width: 121px;
        max-width: 121px;
        min-width: 121px;
      }
      .value {
        margin-left: 10px;
        white-space: break-spaces;
        word-wrap: break-word;
        word-break: break-word;
        button {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 200px;
        }
      }
    }
  }
  .value_title {
    font-weight: bold;
    padding-top: 10px;
  }
`

export const ShowMore = styled.span`
  color: #3271e3;
  cursor: pointer;
`

const { TabPane } = Tabs
const JobChatList: React.FC<ChatListProps> = ({
  activeTab,
  job,
  applicant,
  isCompany,
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [activeChatTab, setActiveChatTab] = useState("applicant")
  const onChatMenuClick = () => {
    setIsOpen(!isOpen)
  }

  const onTabChange = (tab) => {
    setActiveChatTab(tab)
  }

  const handleDownloadClick = async (fileUrl, fileName) => {
    try {
      const signedUrl = await getSignedObjectUrl(fileUrl)
      downloadPdfFile(signedUrl, fileName)
    } catch (error) {
      message.error({
        content:
          error?.message ||
          error?.data?.error?.message ||
          "Something went wrong",
        key: "37",
        icon: <CloseCircleFilled onClick={() => message.destroy("37")} />,
      })
    }
  }

  useEffect(() => {
    if (activeTab) {
      setActiveChatTab(activeTab)
    }
  }, [activeTab])
  const qualification_requirement =
    job?.job_offer_point?.qualification_requirement

  const referral = job?.referral

  return (
    <>
      <HamburgerMenu onClick={onChatMenuClick}>
        <MenuOutlined className={isOpen ? "hidden" : ""} />
        <CloseOutlined className={isOpen ? "" : "hidden"} />
      </HamburgerMenu>
      <Wrapper className={isOpen ? "" : "hidden"}>
        <Tabs activeKey={activeChatTab} onChange={onTabChange}>
          <TabPane
            tab={<div className="title">求職者情報</div>}
            key="applicant"
          >
            <Header className="title-header">
              <div className="dash"></div> <h3> {t("Basic information")}</h3>
            </Header>
            <div className="info-wrapper">
              <div className="info">
                <div className="label">{t("Name")}</div>
                <div className="value">{applicant?.name}</div>
              </div>
              <div className="info">
                <div className="label">{t("Email")}</div>
                <div className="value">
                  {!applicant?.deleted_at ? applicant?.email : "-"}
                </div>
              </div>
              <div className="info">
                <div className="label">{t("Gender")}</div>
                <div className="value">{applicant?.gender}</div>
              </div>
              <div className="info">
                <div className="label">{t("Age")}</div>
                <div className="value">
                  {moment(applicant?.date_of_birth).isValid()
                    ? `${moment().diff(
                        moment(applicant?.date_of_birth),
                        "years",
                        false
                      )} 歳`
                    : `${applicant?.age} 歳` || "-"}
                </div>
              </div>
              <div className="info">
                <div className="label">{t("Desired prefecture")}</div>
                <div className="value">
                  {applicant?.desired_prefecture &&
                  applicant?.desired_prefecture.length
                    ? String(applicant?.desired_prefecture)
                    : "-"}
                </div>
              </div>
            </div>
            <Header className="title-header">
              <div className="dash"></div> <h3>{t("Material downloads")}</h3>
            </Header>
            <div className="info-wrapper">
              <div className="info">
                <div className="label">{t("Resume")}</div>
                <div className="value">
                  {applicant?.resume ? (
                    <Button
                      background="#fff"
                      color={theme.blue2}
                      icon={<DownloadOutlined />}
                      onClick={() =>
                        handleDownloadClick(
                          applicant?.resume,
                          `${applicant?.name}-${applicant?.resume_name}`
                        )
                      }
                    >
                      {t("Download resume")}
                    </Button>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="info">
                <div className="label">{t("Work history")}</div>
                <div className="value">
                  {applicant?.work_history ? (
                    <Button
                      background="#fff"
                      color={theme.blue2}
                      icon={<DownloadOutlined />}
                      onClick={() =>
                        handleDownloadClick(
                          applicant?.work_history,
                          `${applicant?.name}-${applicant?.work_history_name}`
                        )
                      }
                    >
                      {t("Download work history")}
                    </Button>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
            <Header className="title-header">
              <div className="dash"></div> <h3> {t("Records")}</h3>
            </Header>
            {applicant?.free_entry.length > 0 ? (
              <div className="info-wrapper">
                <div className="info">
                  {/* <div className="label">{t("Free Entry")}</div> */}
                  <div className="value">
                    {applicant?.free_entry ? applicant?.free_entry : "-"}
                  </div>
                </div>
              </div>
            ) : (
              <div className="info-wrapper">
                <div className="info">
                  {/* <div className="label">{t("Typical format")}</div> */}
                  <div className="value">
                    <div className="value_title">{t("Summary of duties")}</div>
                    {applicant?.typical_format?.summary_of_duties
                      ? applicant?.typical_format?.summary_of_duties
                      : "-"}
                    <div className="value_title">
                      {t("Personality characteristics/guessing points")}
                    </div>
                    {applicant?.typical_format?.guessing_point
                      ? applicant?.typical_format?.guessing_point
                      : "-"}
                    <div className="value_title">{t("Career Background")}</div>
                    {applicant?.typical_format?.career_background
                      ? applicant?.typical_format?.career_background
                      : "-"}
                  </div>
                </div>
              </div>
            )}
            <Header className="title-header">
              <div className="dash"></div> <h3> {t("Agent information")}</h3>
            </Header>
            <div className="info-wrapper">
              <div className="info">
                <div className="label">{t("Company name")}</div>
                <div className="value">{applicant?.agent.company_name}</div>
              </div>
              {applicant?.agent.company_name !=
                applicant?.agent_member?.name && (
                <div className="info">
                  <div className="label">{t("Agent's Name")}</div>
                  <div className="value">{applicant?.agent_member?.name}</div>
                </div>
              )}
            </div>
          </TabPane>
          {!isCompany ? (
            <TabPane tab={<div className="title">求人情報</div>} key="job">
              {job?.job_type && <Tag color="purple">{job?.job_type}</Tag>}
              <div className="title">{job?.title}</div>
              <div className="company-url">
                {(job?.company?.url || job?.agent_company?.url) && (
                  <a
                    href={
                      job?.company_id
                        ? addhttp(job?.company?.url)
                        : addhttp(job?.agent_company?.url)
                    }
                    target="_blank"
                  >
                    {job?.company_id
                      ? job?.company?.url
                      : job?.agent_company?.url}
                  </a>
                )}

                {job?.industry_type?.length ||
                job?.industry_type_new?.length ? (
                  <span className="industry-type">
                    (
                    {ArrayTranslate(
                      job?.industry_type_new?.length
                        ? job?.industry_type_new
                        : job?.industry_type
                    )}
                    )
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="tag-wrapper">
                {job?.contract_type && (
                  <Tag color="cyan">{job?.contract_type}</Tag>
                )}
                {job?.occupation_type_new ? (
                  job?.occupation_type_new?.map((item, index) => {
                    return (
                      <Tag color="cyan" key={index}>
                        {t(item)}
                      </Tag>
                    )
                  })
                ) : job?.occupation_type ? (
                  <Tag color="cyan">
                    {job?.occupation_type_new || job?.occupation_type}
                  </Tag>
                ) : null}
              </div>
              <Header className="title-header less-margin">
                <div className="dash"></div>
                <h3> {t("Qualification requirements")}</h3>
              </Header>
              <div className="info-wrapper">
                <div className="info">
                  <div className="label">{t("Qualification requirements")}</div>
                  <div className="value">
                    {qualification_requirement?.info || "-"}
                  </div>
                </div>
                <div className="info">
                  <div className="label">{t("Perspective")}</div>
                  <div className="value">
                    {qualification_requirement?.perspective || "-"}
                  </div>
                </div>
                <div className="info">
                  <div className="label">{t("Skill")}</div>
                  <div className="value">
                    {qualification_requirement?.skill || "-"}
                  </div>
                </div>
                <div className="info">
                  <div className="label">{t("Experience")}</div>
                  <div className="value">
                    {qualification_requirement?.experience || "-"}
                  </div>
                </div>
                <div className="info">
                  <div className="label">{t("Work style")}</div>
                  <div className="value">
                    {qualification_requirement?.workstyle || "-"}
                  </div>
                </div>
                <div className="info">
                  <div className="label">{t("Others")}</div>
                  <div className="value">
                    {qualification_requirement?.others || "-"}
                  </div>
                </div>
              </div>

              <Header className="title-header">
                <div className="dash"></div>{" "}
                <h3> {t("Agent related Information")}</h3>
              </Header>
              <div className="info-wrapper">
                <div className="info">
                  <div className="label">{t("Reward Points")}</div>
                  <div className="value">{referral?.reward_points || "-"}</div>
                </div>
                <div className="info">
                  <div className="label">{t("Payment time")}</div>
                  <div className="value">{referral?.payment_time || "-"}</div>
                </div>
                <div className="info">
                  <div className="label">{t("Refund policy")}</div>
                  <div className="value">{referral?.refund_policy || "-"}</div>
                </div>
                <div className="info">
                  <div className="label">{t("Explain annual income")}</div>
                  <div className="value">{referral?.annual_income || "-"}</div>
                </div>
              </div>
              <Header className="title-header">
                <div className="dash"></div>
                <h3> {t("Recruiting company information")}</h3>
              </Header>
              <div className="info-wrapper">
                <div className="info">
                  <div className="label">{t("Company name")}</div>
                  <div className="value">
                    {job?.agent.company_name || job?.company.name}
                  </div>
                </div>
                <div className="info">
                  <div className="label">{t("Person in charge")}</div>
                  <div className="value">
                    {job?.agent?.person_in_charge ||
                      job?.company?.person_in_charge}
                  </div>
                </div>
              </div>
            </TabPane>
          ) : (
            <TabPane
              tab={<div className="title">{t("Agent Features")}</div>}
              key="job"
            >
              {job?.job_type && (
                <Tag color="purple">{JobTypeTranslation[job?.job_type]}</Tag>
              )}
              <div className="title">
                {job?.agent_company?.name
                  ? job?.agent_company?.name
                  : job?.company?.name}
                {job?.one_phrase}
              </div>
              <div className="company-url">
                {(job?.company?.url || job?.agent_company?.url) && (
                  <a
                    href={
                      job?.company_id
                        ? addhttp(job?.company?.url)
                        : addhttp(job?.agent_company?.url)
                    }
                    target="_blank"
                  >
                    {job?.company_id
                      ? job?.company?.url
                      : job?.agent_company?.url}
                  </a>
                )}
                {(job?.industry_type_new || job?.industry_type) && (
                  <span className="industry-type">
                    (
                    {job?.industry_type_new?.toString() ||
                      job?.industry_type?.toString()}
                    )
                  </span>
                )}
              </div>
              <Header className="title-header">
                <div className="dash"></div>
                <h3>{t("Features of Recruitment Agencies")}</h3>
              </Header>
              <div className="info-wrapper">
                <div className="info">
                  <div className="value">{applicant?.agent?.feature}</div>
                </div>
              </div>
            </TabPane>
          )}
        </Tabs>
      </Wrapper>
    </>
  )
}

export { JobChatList }
