import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { JobMessageItem } from "./JobMessageItem"
import InfiniteScroll from "react-infinite-scroll-component"
import { t } from "i18next"
import {
  IJobRoomMessage,
  JobRoomMessageState,
  updateJobRoomMessageState,
} from "../../../../services/jobChat"

interface JobMessageListProps {
  roomId?: string
  messages: IJobRoomMessage[]
  messageBoxHeight?: number
  chatHeaderHeight?: number
  isSearch?: boolean
  searchMsgCount?: number
  isGuest?: boolean
  selfId: string
  senderType: string
  hasMore: boolean
  paginateEnable?: boolean
  handleFetchMoreMessages: () => void
  handleDeletedMessage: (id: string) => void
  memberNames?: any
}

const Wrapper = styled.div<{ isGuest: boolean }>`
  width: 100%;
  background-color: #fff;
`

const Container = styled.div<{
  mainHeight: number
  messageBoxHeight: number
  chatHeaderHeight: number
  isGuest: boolean
}>`
  height: ${(props) =>
    "calc(" +
    props.mainHeight +
    "px - " +
    (props.messageBoxHeight + 136) +
    "px)"};
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 20px;
  & .msg-container {
    display: flex;
    gap: 15px;
    flex-direction: column-reverse;
  }
`

const LoaderWrapper = styled.div`
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
`

const JobMessageList: React.FC<JobMessageListProps> = ({
  roomId,
  messages,
  messageBoxHeight,
  chatHeaderHeight,
  isGuest,
  selfId,
  handleFetchMoreMessages,
  hasMore,
  handleDeletedMessage,
  paginateEnable,
  senderType,
  memberNames,
}) => {
  const [showMoreMessages, setShowMoreMessages] = useState(false)
  const [mainHeight, setMainHeight] = useState(window.innerHeight)

  function checkDimensions() {
    setMainHeight(window.innerHeight)
  }
  useEffect(() => {
    window.addEventListener("resize", checkDimensions)
    return () => {
      window.removeEventListener("resize", checkDimensions)
    }
  })

  const Loader = () => {
    return <LoaderWrapper>{t("Loading...")}</LoaderWrapper>
  }

  const NoData = () => {
    return <LoaderWrapper>{t("You are all caught up")}</LoaderWrapper>
  }

  useEffect(() => {
    const msgContainer = document.querySelector(".msg-container")
    const scrollableDiv = document.querySelector("#scrollableDiv")
    setShowMoreMessages(
      msgContainer?.clientHeight > scrollableDiv?.clientHeight
    )
  })

  const infiniteScrollRef = useRef(null)
  useEffect(() => {
    if (infiniteScrollRef.current.scrollTop < 0) return
    infiniteScrollRef.current.scrollTop = -1
    const timer = setTimeout(() => {
      infiniteScrollRef.current.scrollTop = 0
    })
    if (messages?.length > 0) {
      if (messages?.[0].sender_id !== selfId) {
        setTimeout(async () => {
          await updateJobRoomMessageState(
            roomId,
            JobRoomMessageState.DefaultState
          )
        }, 1000)
      }
    }
    return () => {
      clearTimeout(timer)
    }
  }, [messages?.length])

  return (
    <Wrapper isGuest={isGuest}>
      <Container
        id="scrollableDiv"
        mainHeight={mainHeight}
        messageBoxHeight={messageBoxHeight}
        chatHeaderHeight={chatHeaderHeight}
        isGuest={isGuest}
        ref={infiniteScrollRef}
      >
        {messages?.length > 0 ? (
          <InfiniteScroll
            className="msg-container"
            scrollableTarget="scrollableDiv"
            dataLength={messages?.length}
            next={paginateEnable && handleFetchMoreMessages}
            hasMore={hasMore}
            inverse={true}
            loader={paginateEnable ? <Loader /> : <LoaderWrapper />}
            endMessage={showMoreMessages && <NoData />}
          >
            {messages.map((message) => {
              return (
                <JobMessageItem
                  key={message.id}
                  selfId={selfId}
                  roomMessage={message}
                  senderType={senderType}
                  roomId={roomId}
                  handleDeletedMessage={handleDeletedMessage}
                  memberNames={memberNames}
                />
              )
            })}
          </InfiniteScroll>
        ) : null}
      </Container>
    </Wrapper>
  )
}

export { JobMessageList }
