import { firebase, db } from "../../firebase"
import { chatPageSize, chatRoomSize } from "../../constants"
import { getUserInfo, getUserList } from "../chat/userInfo"
import { API } from "../../api"
import { JobRoomMessageState } from "../../../services/jobChat"

export interface IUser {
  id?: string
  name: string
  email: string
  type: "hiring-company" | "applicant"
  avatar?: string
  status?: string
  person_in_charge?: string
  created_at?: firebase.firestore.FieldValue
  updated_at?: firebase.firestore.FieldValue
}

export enum RoomMessageState {
  AgentRepliedGuestSeen = "AGENT_REPLIED_GUEST_SEEN",
  AgentRepliedGuestNotSeen = "AGENT_REPLIED_GUEST_NOT_SEEN",
  GuestRepliedAgentSeen = "GUEST_REPLIED_AGENT_SEEN",
  GuestRepliedAgentNotSeen = "GUEST_REPLIED_AGENT_NOT_SEEN",
}

export interface IRoom {
  id?: string
  user_type: "hiring-company" | "applicant"
  admin_id: string
  room_code: string
  guest_id: string
  message_state?: string
  is_user_blocked?: boolean
  updated_at?: firebase.firestore.FieldValue
}

export interface IMessage {
  sender_id: string
  is_seen: boolean
  content: string
  file_url: string
  file_name: string
  created_at?: firebase.firestore.FieldValue
}

export const getMessages = async (room_id: string, size: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const query = db
        .collection("rooms")
        .doc(room_id)
        .collection("messages")
        .orderBy("created_at", "desc")
        .limit(size)
      const querySnapshot = await query.get()
      const messageList = []
      for (const doc of querySnapshot.docs) {
        if (doc.exists) {
          messageList.push({
            id: doc.id,
            sender_id: doc.data().sender_id,
            content: doc.data().content,
            file_url: doc.data().file_url,
            file_name: doc.data().file_name,
            created_at: doc.data()?.created_at?.toDate().toISOString(),
          })
        }
      }
      resolve(messageList)
    } catch (error) {
      reject(error)
    }
  })
}

export const getMessageListPromise = async (room_id: string, size: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const query = db
        .collection("rooms")
        .doc(room_id)
        .collection("messages")
        .orderBy("created_at", "desc")
        .limit(size)
      const querySnapshot = await query.get()
      const messageList = []
      for (const doc of querySnapshot.docs) {
        if (doc.exists) {
          messageList.push({
            id: doc.id,
            sender_id: doc.data().sender_id,
            content: doc.data().content,
            file_url: doc.data().file_url,
            file_name: doc.data().file_name,
            created_at: doc.data()?.created_at?.toDate().toISOString(),
          })
        }
      }
      resolve({ id: room_id, data: messageList })
    } catch (error) {
      reject(error)
    }
  })
}

export const chatMessageDeleteListener = async (
  room_id,
  deleteMessageHandler: (id: string) => void
) => {
  const query = db.collection("rooms").doc(room_id).collection("messages")
  return query.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "removed") {
        deleteMessageHandler(change.doc.id)
      }
    })
  })
}

export const registerNewRoomListener = async (
  actions,
  dispatch,
  user_id: string
) => {
  const query = db
    .collection("rooms")
    .where("admin_id", "==", user_id)
    .orderBy("updated_at", "desc")
    .limit(1)
  return query.onSnapshot(async (querySnapshot) => {
    for (const change of querySnapshot.docChanges()) {
      if (change.type === "added" || change.type === "modified") {
        for (const doc of querySnapshot.docs) {
          if (!doc.metadata.hasPendingWrites) {
            const messages = await getMessages(doc.id, chatPageSize)
            const guest = await getUserInfo(doc.data().guest_id)
            guest.status = guest?.status || ""
            guest.person_in_charge = guest?.person_in_charge || ""
            const newRoom = {
              id: doc.id,
              admin_id: doc.data().admin_id,
              room_code: doc.data().room_code,
              user_type: doc.data().user_type,
              guest: guest,
              messages: messages,
              message_state: doc.data().message_state,
              is_user_blocked: doc.data().is_user_blocked,
              updated_at: doc.data().updated_at.toDate().toISOString(),
            }
            if (doc.data()?.is_archived === true) {
              dispatch(actions.removeRoom(doc.id))
            } else {
              dispatch(actions.addRoom(newRoom))
            }
          }
        }
      }
    }
  })
}

export const findRoomById = async (room_id: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const doc = await db.collection("rooms").doc(room_id).get()
      if (!doc.exists) {
        throw new Error("Chat room not found")
      }
      const messages = await getMessages(doc.id, chatPageSize)
      const guest = await getUserInfo(doc.data().guest_id)
      guest.status = guest?.status || ""
      guest.person_in_charge = guest?.person_in_charge || ""
      const room = {
        id: doc.id,
        admin_id: doc.data().admin_id,
        room_code: doc.data().room_code,
        user_type: doc.data().user_type,
        guest: guest,
        messages: messages,
        message_state: doc.data().message_state,
        is_user_blocked: doc.data().is_user_blocked,
        updated_at: doc.data().updated_at?.toDate(),
      }
      resolve(room)
    } catch (error) {
      reject(error)
    }
  })
}

export const findRoomByUserId = async (
  admin_id: string,
  guest_id: string
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const query = db
        .collection("rooms")
        .where("admin_id", "==", admin_id)
        .where("guest_id", "==", guest_id)
        .where("is_archived", "==", false)
        .limit(1)
      const querySnapshot = await query.get()
      for (const doc of querySnapshot.docs) {
        const messages = await getMessages(doc.id, chatPageSize)
        const guest = await getUserInfo(doc.data().guest_id)
        guest.status = guest?.status || ""
        guest.person_in_charge = guest?.person_in_charge || ""
        resolve({
          id: doc.id,
          admin_id: doc.data().admin_id,
          room_code: doc.data().room_code,
          user_type: doc.data().user_type,
          guest: guest,
          messages: messages,
          message_state: doc.data().message_state,
          is_user_blocked: doc.data().is_user_blocked,
          updated_at: doc.data().updated_at.toDate().toISOString(),
        })
      }
      resolve({})
    } catch (error) {
      reject(error)
    }
  })
}

export const findAllRooms = async (
  admin_id: string,
  user_type: string,
  lastRoomID: string
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      let query = db
        .collection("rooms")
        .where("user_type", "==", user_type)
        .where("is_archived", "==", false)
        .where("message_state", "in", [
          "AGENT_REPLIED_GUEST_SEEN",
          "AGENT_REPLIED_GUEST_NOT_SEEN",
          "GUEST_REPLIED_AGENT_SEEN",
          "GUEST_REPLIED_AGENT_NOT_SEEN",
        ])
        .where("admin_id", "==", admin_id)
        .orderBy("updated_at", "desc")
        .limit(chatRoomSize)
      if (lastRoomID) {
        const lastDoc = await db.collection("rooms").doc(lastRoomID).get()
        query = query.startAfter(lastDoc)
      }
      const querySnapshot = await query.get()
      const roomList = []
      const guestMemberList = []
      const messagePromiseList = []
      for (const doc of querySnapshot.docs) {
        messagePromiseList.push(getMessageListPromise(doc.id, 15))
        const guest_id = doc.data().guest_id
        guestMemberList.push(guest_id?.replace(`${user_type}_`, ""))
        roomList.push({
          id: doc.id,
          admin_id: doc.data().admin_id,
          room_code: doc.data().room_code,
          user_type: doc.data().user_type,
          is_active: doc.data().is_active,
          guest_id: guest_id,
          messages: [],
          message_state: doc.data().message_state,
          is_user_blocked: doc.data().is_user_blocked,
          updated_at: doc.data().updated_at.toDate().toISOString(),
        })
      }
      const promiseOne: Promise<{ id: string; data: any }> = new Promise(
        async (resolve, reject) => {
          try {
            const roomMessageObj = {}
            const results = await Promise.allSettled(messagePromiseList)
            if (results.length > 0) {
              for (const result of results) {
                if (result.status === "fulfilled") {
                  roomMessageObj[result.value.id] = result.value.data
                }
              }
            }
            resolve({ id: "messageList", data: roomMessageObj })
          } catch (error) {
            reject(error)
          }
        }
      )
      const promiseTwo: Promise<{ id: string; data: any }> = new Promise(
        async (resolve, reject) => {
          try {
            const userList = await getUserList(user_type, guestMemberList)
            resolve({ id: "userList", data: userList })
          } catch (error) {
            reject(error)
          }
        }
      )
      let userList = []
      let roomMessageObj = {}
      const results = await Promise.allSettled([promiseOne, promiseTwo])
      if (results.length > 0) {
        for (const result of results) {
          if (result.status === "fulfilled") {
            if (result.value.id === "messageList") {
              roomMessageObj = result.value.data
            } else {
              userList = result.value.data
            }
          }
        }
      }
      const finalRoomList = []
      for (const room of roomList) {
        const guest_id = room.guest_id.replace(`${user_type}_`, "")
        if (userList?.[guest_id]) {
          finalRoomList.push({
            id: room.id,
            admin_id: room.admin_id,
            room_code: room.room_code,
            user_type: room.user_type,
            messages: roomMessageObj?.[room.id] || [],
            is_active: room.is_active,
            message_state: room.message_state,
            is_user_blocked: room.is_user_blocked,
            udpated_at: room.udpated_at,
            guest: {
              id: `${user_type}_${userList?.[guest_id].id}`,
              name: userList?.[guest_id].name,
              email: userList?.[guest_id].email,
              type: user_type,
              avatar: userList?.[guest_id].avatar,
              status: userList?.[guest_id]?.status || "",
              person_in_charge: userList?.[guest_id]?.person_in_charge || "",
            },
          })
        }
      }
      resolve(finalRoomList)
    } catch (error) {
      reject(error)
    }
  })
}

export const sendMessage = async (
  room_id: string,
  message: IMessage
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      message.created_at = firebase.firestore.FieldValue.serverTimestamp()
      const documentRef = await db
        .collection("rooms")
        .doc(room_id)
        .collection("messages")
        .add(message)
      const newMessage = await documentRef.get()
      let message_state = ""
      const userType = newMessage.data().sender_id.split("_")[0]
      if (userType === "agent") {
        message_state = "AGENT_REPLIED_GUEST_NOT_SEEN"
      } else if (userType === "hiring-company" || userType === "applicant") {
        message_state = "GUEST_REPLIED_AGENT_NOT_SEEN"
      }
      await db.collection("rooms").doc(room_id).set(
        {
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          message_state: message_state,
        },
        { merge: true }
      )
      resolve({
        id: newMessage.id,
        sender_id: newMessage.data().sender_id,
        content: newMessage.data().content,
        file_url: newMessage.data().file_url,
        file_name: newMessage.data().file_name,
        created_at: newMessage.data().created_at.toDate().toISOString(),
      })
    } catch (error) {
      reject(error)
    }
  })
}

export const registerNewMessageListener = async (
  actions,
  dispatch,
  room_id: string
) => {
  const query = db
    .collection("rooms")
    .doc(room_id)
    .collection("messages")
    .orderBy("created_at", "desc")
    .limit(1)

  return query.onSnapshot((snapshot) => {
    snapshot.forEach((doc) => {
      if (!doc.metadata.hasPendingWrites) {
        const payload = {
          room_id,
          message: {
            id: doc.id,
            sender_id: doc.data().sender_id,
            content: doc.data().content,
            file_url: doc.data().file_url,
            file_name: doc.data().file_name,
            created_at: doc.data().created_at.toDate().toISOString(),
          },
        }
        dispatch(actions.addMessage(payload))
      }
    })
  })
}

export const deleteFirestoreDocument = async ({
  collection_name,
  room_id,
  id,
}) => {
  return new Promise((resolve, reject) => {
    const query = db
      .collection(collection_name)
      .doc(room_id)
      .collection("messages")
    query
      .doc(id)
      .delete()
      .then(() => resolve(true))
      .catch((error) => reject(error))
  })
}

export const findAllMessages = async (
  room_id: string,
  lastMessageID: string
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      let query = db
        .collection("rooms")
        .doc(room_id)
        .collection("messages")
        .orderBy("created_at", "desc")
        .limit(chatPageSize)
      if (lastMessageID) {
        const lastDoc = await db
          .collection("rooms")
          .doc(room_id)
          .collection("messages")
          .doc(lastMessageID)
          .get()
        query = query.startAfter(lastDoc)
      }
      const querySnapshot = await query.get()
      const messageList = []
      for (const doc of querySnapshot.docs) {
        messageList.push({
          id: doc.id,
          sender_id: doc.data().sender_id,
          content: doc.data().content,
          file_url: doc.data().file_url,
          file_name: doc.data().file_name,
          created_at: doc.data().created_at.toDate().toISOString(),
        })
      }
      resolve(messageList)
    } catch (error) {
      reject(error)
    }
  })
}

export const updateRoomSession = ({ currentRoomId, isActive }) => {
  return new Promise(async (resolve, reject) => {
    try {
      currentRoomId &&
        (await db.collection("rooms")?.doc(currentRoomId).update({
          is_active: isActive,
        }))
      resolve(true)
    } catch (error) {
      reject(error)
    }
  })
}

export const updateRoomMessageState = (
  room_id: string,
  new_state: RoomMessageState
) => {
  return new Promise(async (resolve, reject) => {
    try {
      room_id &&
        (await db.collection("rooms")?.doc(room_id).update({
          message_state: new_state,
        }))
      resolve(true)
    } catch (error) {
      reject(error)
    }
  })
}

export const saveMessageInDB = async ({
  id,
  room_id,
  sender_id,
  content,
  file_name,
  file_url,
  created_at,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const reqBody = {
        id,
        room_id,
        sender_id,
        content,
        file_name,
        file_url,
        created_at,
      }
      const response = await API.post("/chat-messages/", reqBody)
      if (response?.data) {
        resolve(response.data)
      } else reject(new Error("Something went wrong"))
    } catch (error) {
      reject(error)
    }
  })
}

export const deleteMessageFromDB = async (room_id, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.delete(`/chat-messages/${room_id}/${id}`)
      if (response?.status === 200) {
        resolve(true)
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const searchMessages = async (
  room_id: string,
  searchKey: string,
  page: number
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        page: page,
        pageSize: 15,
        keyword: searchKey,
      }
      const response: any = await API.get(`/chat-messages/${room_id}`, {
        params,
      })
      if (response?.data) {
        resolve({ count: response.count, messages: response.data })
      } else {
        reject(new Error("Something went wrong"))
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const registerUnseenMessageListener = async (
  actions,
  dispatch,
  user_id: string
) => {
  const query = db
    .collection("rooms")
    .where("admin_id", "==", user_id)
    .where("is_archived", "==", false)
    .where("message_state", "==", "GUEST_REPLIED_AGENT_NOT_SEEN")
  return query.onSnapshot((snapshot) => {
    dispatch(actions.setMessageCount({ key: "unseen", count: snapshot.size }))
  })
}

export const registerUnrepliedMessageListener = async (
  user_id: string,
  onChangeCount: (count: number) => void
) => {
  const query = db
    .collection("rooms")
    .where("admin_id", "==", user_id)
    .where("is_archived", "==", false)
    .where("message_state", "in", ["GUEST_REPLIED_AGENT_NOT_SEEN"])
  return query.onSnapshot((snapshot) => {
    onChangeCount(snapshot.size)
  })
}

export const registerUnrepliedMessageListenerForCompany = async (
  company_id: string,
  onChangeCount: (count: number) => void
) => {
  const query = db
    .collection("job_rooms")
    .where("company_id", "==", company_id)
    .where("message_state", "==", JobRoomMessageState.CompanyNotSeen)

  return query.onSnapshot((snapshot) => {
    onChangeCount(snapshot.size)
  })
}

export const updateUserBlockStatus = (room_id: string, new_status: boolean) => {
  return new Promise(async (resolve, reject) => {
    try {
      room_id &&
        (await db.collection("rooms")?.doc(room_id).update({
          is_user_blocked: new_status,
        }))
      resolve(true)
    } catch (error) {
      reject(error)
    }
  })
}

export const registerRoomChangeListener = async (
  room_id: string,
  onChange: (room: any) => void
) => {
  const query = db.collection("rooms").doc(room_id)
  query.onSnapshot((doc) => {
    if (!doc.metadata.hasPendingWrites) {
      onChange({
        id: doc.id,
        admin_id: doc.data().admin_id,
        room_code: doc.data().room_code,
        user_type: doc.data().user_type,
        guest_id: doc.data().guest_id,
        message_state: doc.data().message_state,
        is_user_blocked: doc.data().is_user_blocked,
        updated_at: doc.data().updated_at.toDate().toISOString(),
      })
    }
  })
}

export const registerUnseenApplicantMessageListener = async (
  actions,
  dispatch,
  user_id: string
) => {
  const query = db
    .collection("rooms")
    .where("admin_id", "==", user_id)
    .where("is_archived", "==", false)
    .where("message_state", "==", "GUEST_REPLIED_AGENT_NOT_SEEN")
    .where("user_type", "==", "applicant")
  return query.onSnapshot((snapshot) => {
    dispatch(
      actions.setMessageCount({ key: "applicantUnseen", count: snapshot.size })
    )
  })
}

export const registerUnseenCompanyMessageListener = async (
  actions,
  dispatch,
  user_id: string
) => {
  const query = db
    .collection("rooms")
    .where("admin_id", "==", user_id)
    .where("is_archived", "==", false)
    .where("message_state", "==", "GUEST_REPLIED_AGENT_NOT_SEEN")
    .where("user_type", "==", "hiring-company")
  return query.onSnapshot((snapshot) => {
    dispatch(
      actions.setMessageCount({ key: "companyUnseen", count: snapshot.size })
    )
  })
}
