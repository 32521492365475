import React, { useEffect, useState } from "react"
import { StyleSheet, Image, View } from "@react-pdf/renderer"
import { PdfJobOfferPointBlock } from "./PdfJobOfferPointBlock"
import { t } from "i18next"
import { PdfJobSectionBlock } from "./PdfJobSectionBlock"
import { getSignedObjectUrl } from "../../src"

const PdfJobOfferPoint = ({ jobData, interviewImageObject }: any) => {
  const [signedObject, setSignedObject] = useState("")
  const [indexLabel, setIndexLabel] = useState<any>()
  const interview_image = jobData?.job_overview?.interview_image

  useEffect(() => {
    let index = 1
    const indexValue = {}
    if (
      jobData?.job_offer_point?.qualification_requirement?.info ||
      jobData?.job_offer_point?.qualification_requirement?.skill ||
      jobData?.job_offer_point?.qualification_requirement?.experience
    ) {
      indexValue["entry_requirement"] = index
      index = index + 1
    }
    if (
      jobData?.job_overview?.business_details ||
      jobData?.job_overview?.work_details
    ) {
      indexValue["work_content"] = index
      index = index + 1
    }

    if (
      jobData?.job_offer_point?.qualification_requirement?.workstyl ||
      jobData?.job_overview?.holidays_and_welfares ||
      (jobData?.job_overview?.work_time?.from &&
        jobData?.job_overview?.work_time?.to)
    ) {
      indexValue["employment_condition"] = index
      index = index + 1
    }
    if (
      jobData?.job_offer_point?.message_from_hr.info ||
      jobData?.job_offer_point?.message_from_hr.career_plan ||
      jobData?.job_offer_point?.informal_information ||
      jobData?.point_of_recommendation ||
      jobData?.job_overview?.interview ||
      jobData?.job_overview?.interview_image
    ) {
      indexValue["recruiting_company"] = index
      index = index + 1
    }

    setIndexLabel(indexValue)
    if (!interviewImageObject && interview_image) {
      ;(async () => {
        const imageUrl = await getSignedObjectUrl(interview_image)
        setSignedObject(imageUrl)
      })()
    }
  }, [jobData])
  return (
    <View style={styles.wrapper}>
      {/* {!isCompany && <InformationToAgent job={jobData} />} */}
      {indexLabel?.entry_requirement && (
        <PdfJobSectionBlock
          index={indexLabel?.entry_requirement}
          title={t("Entry requirements")}
        />
      )}
      <View style={styles.horizontalWrapper}>
        {jobData?.job_offer_point?.qualification_requirement?.info && (
          <PdfJobOfferPointBlock
            headingOne={t("Qualification requirements")}
            bodyOne={jobData?.job_offer_point?.qualification_requirement?.info}
          />
        )}
        {jobData?.job_offer_point?.qualification_requirement?.skill && (
          <PdfJobOfferPointBlock
            hideRight={true}
            headingOne={t("Skill")}
            bodyOne={jobData?.job_offer_point?.qualification_requirement?.skill}
          />
        )}
        {jobData?.job_offer_point?.qualification_requirement?.experience && (
          <PdfJobOfferPointBlock
            headingOne={t("Experience")}
            bodyOne={
              jobData?.job_offer_point?.qualification_requirement?.experience
            }
          />
        )}
      </View>
      {(jobData?.job_overview?.business_details ||
        jobData?.job_overview?.work_details) && (
        <PdfJobSectionBlock
          index={indexLabel?.work_content}
          title={t("Work/Business content")}
        />
      )}
      {jobData?.job_overview?.business_details && (
        <PdfJobOfferPointBlock
          headingOne={t("Business Detail")}
          hideRight={true}
          bodyOne={jobData?.job_overview?.business_details}
        />
      )}
      {jobData?.job_overview?.work_details && (
        <PdfJobOfferPointBlock
          headingOne={t("Work details")}
          hideRight={true}
          bodyOne={jobData?.job_overview?.work_details}
        />
      )}
      {indexLabel?.employment_condition && (
        <PdfJobSectionBlock
          index={indexLabel?.employment_condition}
          title={t("Employment conditions")}
        />
      )}
      {jobData?.job_offer_point?.qualification_requirement?.workstyle && (
        <PdfJobOfferPointBlock
          headingOne={t("Work style")}
          hideRight={true}
          bodyOne={
            jobData?.job_offer_point?.qualification_requirement?.workstyle
          }
        />
      )}
      {jobData?.job_overview?.holidays_and_welfares && (
        <PdfJobOfferPointBlock
          headingOne={t("Holidays and welfares")}
          hideRight={true}
          bodyOne={jobData?.job_overview?.holidays_and_welfares}
        />
      )}
      {indexLabel?.recruiting_company && (
        <PdfJobSectionBlock
          index={indexLabel?.recruiting_company}
          title={t("Information from recruiting Companies")}
        />
      )}

      <View style={styles.horizontalWrapper}>
        {jobData?.job_offer_point?.message_from_hr?.career_plan && (
          <PdfJobOfferPointBlock
            headingOne={t("Career plan")}
            hideRight={true}
            bodyOne={jobData?.job_offer_point?.message_from_hr?.career_plan}
          />
        )}
      </View>
      <View style={styles.interview}>
        <View style={styles.interviewDetails}>
          {jobData?.job_overview?.interview && (
            <PdfJobOfferPointBlock
              headingOne={t("Interview")}
              hideRight={true}
              bodyOne={jobData?.job_overview?.interview}
            />
          )}
        </View>
        {jobData?.job_overview?.interview_image && (
          <View style={styles.interviewImageWrapper}>
            <View style={styles.interviewImage}>
              <Image
                src={signedObject || interviewImageObject}
                style={styles.image}
              />
            </View>
          </View>
        )}
      </View>
      {indexLabel?.sales_info && (
        <PdfJobSectionBlock
          index={indexLabel?.sales_info}
          title={t("Sales agent limited information")}
        />
      )}
      {jobData?.job_offer_point?.message_from_hr?.selection_flow &&
      jobData?.job_offer_point?.message_from_hr?.selection_flow.length > 0 ? (
        <PdfJobOfferPointBlock
          headingOne={t("Selection flow")}
          tags={jobData?.job_offer_point?.message_from_hr?.selection_flow}
        />
      ) : null}
    </View>
  )
}
const styles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
    marginTop: 20,
    border: "1px solid #ececec",
    borderRadius: 5,
    padding: 8,
  },
  interview: {
    display: "flex",
    width: "100%",
  },
  interviewDetails: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 10,
    width: "100%",
  },
  interviewImage: {
    width: 200,
    backgroundColor: "#f0f2f5",
  },
  image: {
    width: "100%",
    height: 200,
    objectFit: "contain",
    objectPosition: "center",
  },
  horizontalWrapper: {
    display: "flex",
    justifyContent: "space-around",
  },
  interviewImageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
})

export { PdfJobOfferPoint }
