import { API } from "../src/api"

export const fileUpload = async (formData) => {
  return API.post("/utils/upload-file", formData)
}

export const moveUploadedFile = async (file_url) => {
  return API.post("/utils/move-uploaded-file", {
    file_url: file_url?.replace("default", "temp") || null,
  })
}
