import { BankOutlined } from "@ant-design/icons"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

interface TableSectionProps {
  icon?: any
  label: string
  value: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6px 4px;
  margin-bottom: 10px;
  border: 1px solid #ececec;
  border-radius: 4px;
  margin-left: 16px;
  margin-top: 3px;

  .overview {
    display: flex;
    margin-left: 10px;
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 6px;
    gap: 15px;
  }

  .item {
    display: flex;
    justify-content: flex-start;
    gap: 3.5rem;
    margin-left: 6px;
    margin-bottom: 6px;
    align-items: start;
    .icon-label {
      display: flex;
      align-items: center;
      gap: 5px;
      width: 28%;

      .label {
        font-weight: bold;
        font-size: 12px;
      }
    }
    .value {
      width: 50%;
      font-size: 12px;
    }
  }
`
const TableSection = ({ icon, label, value }: TableSectionProps) => {
  return (
    <div className="item">
      <div className="icon-label">
        <div className="icon">{icon}</div>
        <div className="label">{label}</div>
      </div>
      <div className="value">{value}</div>
    </div>
  )
}

const TopSectionCardSecond = ({ job }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <div className="overview">
        <div className="icon">
          <BankOutlined />
        </div>
        <div className="overview-title">{t("Company Overview")}</div>
      </div>
      <TableSection
        label={t("Address")}
        value={
          job?.company_id ? job?.company?.address : job?.agent_company?.address
        }
      />
      <TableSection
        label={t("Established")}
        value={
          job?.company_id
            ? job?.company?.established_date
            : job?.agent_company?.established_date
        }
      />
      <TableSection
        label={t("No. of employees")}
        value={
          job?.company_id
            ? job?.company?.number_of_employees
            : job?.agent_company?.number_of_employees
        }
      />
    </Wrapper>
  )
}

export { TopSectionCardSecond }
