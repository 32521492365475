import React from "react"
import { Pagination } from "antd"
import styled from "styled-components"

const StyledPaginationWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  .ant-pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
    justify-content: flex-end;
  }
  .ant-pagination-total-text {
    margin-right: auto;
    font-weight: bold;
  }
`
export const PaginationComponent = (props) => {
  return (
    <StyledPaginationWrapper>
      <Pagination {...props} />
    </StyledPaginationWrapper>
  )
}
