import React, { useState } from "react"
import styled from "styled-components"
import { ChatHeader } from "./ChatHeader"
import { MessageList } from "./MessageList"
import { MessageBox } from "./MessageBox"
import { IMessageItem } from "./MessageItem"

interface ChatModuleProps {
  title: string
  extra: React.ReactNode
  messages: IMessageItem[]
  isGuest?: boolean
  handleDeletedMessage?: (id: string) => void
}
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f8ff;
  & .chat-body {
    max-width: 100%;
  }
`
const ChatModule: React.FC<ChatModuleProps> = (props) => {
  const { title, messages, extra, isGuest, handleDeletedMessage } = props
  const [messageBoxHeight, setMessageBoxHeight] = useState(0)
  const [chatHeaderHeight, setChatHeaderHeight] = useState(0)
  return (
    <Wrapper>
      <div className="chat-body">
        <ChatHeader
          isGuest={isGuest}
          title={title}
          extra={extra}
          updateChatHeaderHeight={setChatHeaderHeight}
        />
        <MessageList
          messages={messages}
          messageBoxHeight={messageBoxHeight}
          chatHeaderHeight={chatHeaderHeight}
          isGuest={isGuest}
          handleDeletedMessage={handleDeletedMessage}
        />
        <MessageBox
          updateMessageBoxHeight={setMessageBoxHeight}
          isGuest={isGuest}
        />
      </div>
    </Wrapper>
  )
}

export { ChatModule }
