export const getUniqueList = (oldList, newList) => {
  const oldIdList = []
  const uniqueList = [...oldList]
  for (const item of oldList) {
    oldIdList.push(item.id)
  }
  for (const item of newList) {
    if (!oldIdList.includes(item.id)) uniqueList.push(item)
  }
  return uniqueList
}
