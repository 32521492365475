import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"
import router from "next/router"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import dynamic from "next/dynamic"
const Button = dynamic(
  () => import("../../../atom/Button/index").then((mod) => mod.ButtonComponent),
  { ssr: false }
)
const JobCard = dynamic(
  () => import("../../../molecules/JobCard").then((mod) => mod.JobCard),
  { ssr: false }
)
const SectionTitle = dynamic(
  () =>
    import("../../../atom/JobDetail/SectionTitle/index").then(
      (mod) => mod.SectionTitle
    ),
  { ssr: false }
)
interface iOtherJobSection {
  jobs?: any
  mutation?: any
  setPageSize?: any
  isCompany?: boolean
  pageSize?: number
  isJobLoading?: boolean
  isJobFetching?: boolean
}

const Wrapper = styled.div`
  box-shadow: 0 0 3px 3px #0000001a;
  border-radius: 5px;
  padding: 20px;
  margin-top: 30px;
  .section-title {
    margin-bottom: 20px;
  }
  .ant-ribbon.ant-ribbon-placement-end {
    right: -8px !important;
  }
`
const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 130px;
`
const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    height: 40px !important;
  }
`
const OtherJobSection: React.FC<iOtherJobSection> = ({
  jobs,
  mutation,
  setPageSize,
  isCompany,
  pageSize,
  isJobLoading,
  isJobFetching,
}) => {
  const { t } = useTranslation()

  const handleClickBtn = () => {
    isCompany ? router.push("/jobs") : setPageSize((prev) => prev + 2)
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  return (
    <Wrapper data-html2canvas-ignore={true}>
      <SectionTitle
        className="section-title"
        title={t("Other jobs from same company")}
      />
      {isJobFetching || isJobLoading ? (
        <LoaderWrapper>
          <Spin indicator={antIcon} size="large" />
        </LoaderWrapper>
      ) : (
        <>
          {jobs.data.map((job) => (
            <JobCard
              job={job}
              mutation={mutation}
              key={job?.id}
              showRibbon={false}
            />
          ))}
          {jobs.count > pageSize && (
            <BtnWrapper>
              <Button
                width="250px"
                onClick={handleClickBtn}
                loading={isJobFetching || isJobLoading}
              >
                {t("See more jobs")}
              </Button>
            </BtnWrapper>
          )}
        </>
      )}
    </Wrapper>
  )
}

export { OtherJobSection }
