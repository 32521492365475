import { CheckOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Checkbox, Modal, Space, Tabs } from "antd"
import React, { useState } from "react"
import styled from "styled-components"
import { Button as SharedButton } from "../../atom"
import { theme } from "../../../theme"
import { CheckboxChangeEvent, CheckboxGroupProps } from "antd/lib/checkbox"
import { t } from "i18next"

export interface IMultiSelectPopoverContent {
  title: string
  checkOptions: Array<string>
  countLabel?: string
}
interface IMultiSelectPopover extends CheckboxGroupProps {
  modalTitle: string
  buttonText: string
  required?: boolean
  label?: string
  marginBottom?: string
  content: Array<IMultiSelectPopoverContent>
  isVisibleOnlyToAgent?: boolean
  onClickCheckBox?: (e: CheckboxChangeEvent) => void
  isCountRequired?: boolean
  jobCount?: any
  breakWord?: boolean
  singleValue?: boolean
}

const { TabPane } = Tabs

const Wrapper = styled.div<any>`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "1rem"};
  .text-btn {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    min-width: 132px;
    height: 31.38px;
    svg {
      color: #3295e0;
    }
  }
`
const ReflectInPDFOnlyForAgent = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  font-family: "Noto Sans JP", sans-serif;
  color: #f5222d;
  min-width: 40px;
  height: 22px;
  justify-content: center;
  border: 1px solid #ffa39e;
  border-radius: 2px;
  align-items: center;
  margin: 2px 9px;
  text-align: center;
`
const TextFieldWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  & > input {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  width: 160px;
  .ant-input {
    &:focus,
    &:hover,
    &:active {
      border-color: #8b94a5;
    }
  }
`

const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  width: 165px;
  min-width: 165px;
  justify-content: space-between;
  padding-right: 10px;
`

const Label = styled.div`
  ${theme.typography.label};
  margin-right: 10px;
  font-weight: 500;
`
const CustomModal = styled(Modal)`
  width: 982px;
  height: 632px;
  .ant-modal-body {
    border: 1px solid #182a4b;
    padding: 0;
    margin-inline: 30px;
    min-height: 432px;
  }
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-reset-button {
    min-width: 150px;
    height: 50px;
    background-color: #d6d6d6;
    color: #000;
    border: none;
    margin-right: 1rem;
  }
`
const RequiredLabel = styled.div<any>`
  ${theme.typography.PackDesc};
  color: #f5222d;
  min-width: 40px;
  height: 22px;
  display: flex;
  justify-content: center;
  background: #fff1f0;
  border: 1px solid #ffa39e;
  border-radius: 2px;
  align-items: center;
`
const ButtonWrapper = styled.div`
  display: flex;
`
const DisplaySection = styled.div`
  display: flex;
  align-items: flex-start;
`
const StyledItemLabel = styled.div`
  display: flex;
  align-items: center;
  word-break: ${({ breakWord }: any) =>
    !breakWord ? "keep-all" : "break-word"};
  white-space: ${({ breakWord }: any) => (!breakWord ? "keep-all" : "nowrap")};
`

const MultiSelectedItem = styled.div`
  display: flex;
  margin-left: 20px;
  gap: 20px;
  width: ${({ breakWord }: any) =>
    !breakWord ? "inherit" : "calc(100% - 330px)"};
  flex-wrap: wrap;
`

const CustomTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    width: 293px;
    border: none;
    margin-left: -1px;
  }
  .ant-tabs-tabpane {
    padding: 20px 60px;
  }
  .ant-tabs-nav-wrap {
    border-bottom: none;
  }
  .ant-tabs-tab {
    margin: 0;
    margin-top: -1px !important;
    border: 1px solid #182a4b;
    text-align: center;
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: break-spaces;
  }
  .ant-tabs-tab-active {
    border-radius: 0;
    background-color: #182a4b;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #182a4b;
    border-color: #182a4b;
  }
  .ant-tabs-nav-more {
    display: none;
  }
`

const MultiSelectPopover: React.FC<IMultiSelectPopover> = ({
  buttonText,
  modalTitle,
  label,
  required,
  content,
  value,
  isVisibleOnlyToAgent,
  onClickCheckBox,
  isCountRequired,
  jobCount,
  marginBottom,
  breakWord = false,
  singleValue,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Wrapper marginBottom={marginBottom} breakWord={breakWord}>
      <DisplaySection>
        <ButtonWrapper>
          {label && (
            <TextFieldWrapperStyled>
              <LabelContainer>
                <Label>{t(label)}</Label>
                {required && <RequiredLabel>必須</RequiredLabel>}
              </LabelContainer>
              {isVisibleOnlyToAgent && (
                <ReflectInPDFOnlyForAgent>
                  エージェントのみ 閲覧可能
                </ReflectInPDFOnlyForAgent>
              )}
            </TextFieldWrapperStyled>
          )}
          <Button
            type="default"
            onClick={showModal}
            icon={<PlusOutlined />}
            className={"text-btn"}
          >
            {buttonText}
          </Button>
        </ButtonWrapper>
        {singleValue ? (
          <MultiSelectedItem>
            {!!value && (
              <StyledItemLabel>
                <CheckOutlined style={{ marginRight: "5px" }} />
                {t(String(value))}
              </StyledItemLabel>
            )}
          </MultiSelectedItem>
        ) : (
          <MultiSelectedItem>
            {value?.map((item: string, key: number) => (
              <StyledItemLabel key={key}>
                <CheckOutlined style={{ marginRight: "5px" }} /> {t(item)}
              </StyledItemLabel>
            ))}
          </MultiSelectedItem>
        )}
      </DisplaySection>
      <CustomModal
        title={modalTitle}
        visible={isModalOpen}
        closable={true}
        onCancel={handleCancel}
        footer={
          <SharedButton className="form-reset-button" onClick={handleCancel}>
            {"決定"}
          </SharedButton>
        }
        width={1024}
      >
        <CustomTabs
          defaultActiveKey="1"
          tabPosition={"left"}
          className="multiselectpopover"
        >
          {content.map((item) => {
            return (
              <TabPane
                tab={
                  <>
                    {item.title}
                    {isCountRequired && jobCount ? (
                      <span
                        style={{
                          color: "#DE7C22",
                          paddingLeft: "1em",
                          fontSize: "0.8em",
                        }}
                      >{`${jobCount?.[item.countLabel]}件`}</span>
                    ) : null}
                  </>
                }
                key={item.title}
              >
                <Checkbox.Group value={value} {...props}>
                  <Space direction="vertical">
                    {item.checkOptions.map((itm) => (
                      <Checkbox
                        value={itm}
                        key={itm}
                        onChange={onClickCheckBox}
                      >
                        {t(itm)}
                      </Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </TabPane>
            )
          })}
        </CustomTabs>
      </CustomModal>
    </Wrapper>
  )
}
export { MultiSelectPopover }
