import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
 
 .meet-notifications {
    right:590px !important;
    top:44px !important;
    width: 344px;
    max-height: 521px;
    overflow: auto;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: rgb(221 221 221) 0px 3px 6px;
    border-radius: 8px 0px 0px 8px;
    background: rgb(255, 255, 255);
    padding-right:0px;
       .ant-popover-title {
            font-size: 16px;
            padding-left: 8px;
            font-weight: bold;
            padding-top: 5px;
            padding-bottom: 5px;
        }
  
        .see-more{
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: center;
        }
        & .first-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        & .date {
            color: rgb(82, 73, 73);
        }

        .ant-popover-inner-content {
            padding: 0px;
        }
}
`

export { GlobalStyle }
