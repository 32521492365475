import React from "react"
import { View, Text, StyleSheet } from "@react-pdf/renderer"

const PdfTableSection = ({ label, value }) => {
  return (
    <View style={styles.item}>
      <Text style={styles.label}>
        {`${label}:   `}
        <Text style={styles.value}>{value || "-"}</Text>
      </Text>
    </View>
  )
}
const styles = StyleSheet.create({
  item: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: 6,
    marginBottom: 6,
    alignItems: "flex-start",
  },
  iconLabel: {
    display: "flex",
    alignItems: "center",
    width: "28%",
  },
  label: {
    fontWeight: "bold",
    fontSize: 8,
  },
  value: {
    width: "50%",
    fontSize: 8,
  },
})
export { PdfTableSection }
