import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { IMessageItem, MessageItem } from "./MessageItem"
import {
  findAllMessages,
  RoomMessageState,
  updateRoomMessageState,
} from "../../../chatServices/chat"
import { useChatContext } from "../../../contexts/chat/ChatContext"
import InfiniteScroll from "react-infinite-scroll-component"
import { message } from "antd"
import { CloseCircleFilled } from "@ant-design/icons"
import { t } from "i18next"

interface MessageListProps {
  messages: IMessageItem[]
  messageBoxHeight?: number
  chatHeaderHeight?: number
  isSearch?: boolean
  searchMsgCount?: number
  onPaginateSearch?: () => void
  isGuest?: boolean
  handleDeletedMessage: (id: string) => void
}

const Wrapper = styled.div<{ isGuest: boolean }>`
  width: 100%;
  margin-top: ${(props) => (props.isGuest ? 0 : "15px")};
  margin-bottom: ${(props) => (props.isGuest ? 0 : "15px")};
  background-color: #fff;
`

const Container = styled.div<{
  mainHeight: number
  messageBoxHeight: number
  chatHeaderHeight: number
  isGuest: boolean
}>`
  height: ${(props) =>
    "calc(" +
    props.mainHeight +
    "px - " +
    (props.messageBoxHeight + (props.isGuest ? props.chatHeaderHeight : 185)) +
    "px)"};
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 20px;
  & .msg-container {
    display: flex;
    gap: 15px;
    flex-direction: column-reverse;
  }
`

const LoaderWrapper = styled.div`
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
`

const MessageList: React.FC<MessageListProps> = ({
  messages,
  messageBoxHeight,
  chatHeaderHeight,
  isSearch,
  searchMsgCount,
  onPaginateSearch,
  isGuest,
  handleDeletedMessage,
}) => {
  const [hasMore, setHasMore] = useState(true)
  const { state, actions, dispatch } = useChatContext()
  const [showMoreMessages, setShowMoreMessages] = useState(false)
  const [mainHeight, setMainHeight] = useState(window.innerHeight)

  function checkDimensions() {
    setMainHeight(window.innerHeight)
  }
  useEffect(() => {
    window.addEventListener("resize", checkDimensions)
    return () => {
      window.removeEventListener("resize", checkDimensions)
    }
  })

  useEffect(() => {
    ;(async () => {
      const userType = state.user?.id?.split("_")[0]
      if (messages?.length > 0) {
        if (userType === "agent") {
          if (messages?.[0].sender_id.split("_")[0] !== "agent") {
            await updateRoomMessageState(
              state.currentRoomId,
              RoomMessageState.GuestRepliedAgentSeen
            )
            await dispatch(
              actions.updateRoom({
                id: state.currentRoomId,
                newState: {
                  message_state: "GUEST_REPLIED_AGENT_SEEN",
                },
              })
            )
          }
        } else {
          if (messages?.[0].sender_id.split("_")[0] === "agent") {
            await updateRoomMessageState(
              state.currentRoomId,
              RoomMessageState.AgentRepliedGuestSeen
            )
          }
        }
      }
    })()
  }, [messages.length])

  const paginateMessages = async () => {
    try {
      const currentMessageList = state?.rooms?.[state.currentRoomId]?.messages
      if (currentMessageList && currentMessageList.length > 0) {
        const lastMessage = currentMessageList[currentMessageList.length - 1]
        const messageList = await findAllMessages(
          state.currentRoomId,
          lastMessage.id
        )
        if (!messageList || messageList?.length < 1) {
          setHasMore(false)
          return
        }
        dispatch(
          actions.loadMessages({
            room_id: state.currentRoomId,
            messages: messageList,
          })
        )
      }
    } catch (error) {
      message.error({
        key: "31",
        content: error?.message || "Something went wrong",
        icon: <CloseCircleFilled onClick={() => message.destroy("31")} />,
      })
    }
  }

  const Loader = () => {
    return <LoaderWrapper>{t("Loading...")}</LoaderWrapper>
  }

  const NoData = () => {
    return <LoaderWrapper>{t("You are all caught up")}</LoaderWrapper>
  }

  useEffect(() => {
    const msgContainer = document.querySelector(".msg-container")
    const scrollableDiv = document.querySelector("#scrollableDiv")
    setShowMoreMessages(
      msgContainer?.clientHeight > scrollableDiv?.clientHeight
    )
  })

  const infiniteScrollRef = useRef(null)
  useEffect(() => {
    if (infiniteScrollRef.current.scrollTop < 0) return
    infiniteScrollRef.current.scrollTop = -1
    const timer = setTimeout(() => {
      infiniteScrollRef.current.scrollTop = 0
    })
    return () => {
      clearTimeout(timer)
    }
  }, [messages?.length])

  return (
    <Wrapper isGuest={isGuest}>
      <Container
        id="scrollableDiv"
        mainHeight={mainHeight}
        messageBoxHeight={messageBoxHeight}
        chatHeaderHeight={chatHeaderHeight}
        isGuest={isGuest}
        ref={infiniteScrollRef}
      >
        {messages?.length > 0 ? (
          <InfiniteScroll
            className="msg-container"
            scrollableTarget="scrollableDiv"
            dataLength={messages?.length}
            next={isSearch ? onPaginateSearch : paginateMessages}
            hasMore={isSearch ? messages?.length < searchMsgCount : hasMore}
            inverse={true}
            loader={<Loader />}
            endMessage={showMoreMessages && <NoData />}
          >
            {messages?.map((message) => {
              return (
                <MessageItem
                  key={message.id}
                  {...message}
                  handleDeletedMessage={handleDeletedMessage}
                />
              )
            })}
          </InfiniteScroll>
        ) : null}
      </Container>
    </Wrapper>
  )
}

export { MessageList }
