import { firebase } from "@project/shared"
import { createContext, useEffect, useState } from "react"
import { getMemberByUid } from "../services/member"

interface IUserState {
  result: firebase.User
  role: string
  uid: string
}
interface IGoogleUserState {
  name?: string
  email?: string
  accessToken?: string
  clientId?: string
  imageUrl?: string
  tokenId?: string
}

interface IAuthState {
  user: IUserState
  guser: IGoogleUserState
  currentUser: any
}
interface IAuthContextValue {
  loading: boolean
  result: firebase.User
  state: IAuthState
  dispatch: ({ type, payload }: { type: string; payload?: any }) => void
}
interface IAuthContextProps {
  value: IAuthContextValue
  state: IAuthState
  dispatch: ({
    type,
    payload,
  }: {
    type: string
    payload?: IUserState | IGoogleUserState | any
  }) => void
  agentInfo: any
}

export const initialState: IAuthState = {
  user: null,
  guser: null,
  currentUser: null,
}

export const authReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      }
    case "SET_GUSER":
      return {
        ...state,
        guser: action.payload,
      }
    case "SET_CURRENT_USER":
      return {
        ...state,
        currentUser: action.payload,
      }
    case "SET_SELECTION":
      return {
        ...state,
        user: {
          ...state.user,
          result: {
            ...state.user.result,
            selection: action.payload,
          },
        },
      }
    default:
      return { ...state }
  }
}

export const getUserMetadata = async (user: firebase.User | null) => {
  const metadata = await user?.getIdTokenResult()
  return {
    uid: metadata?.claims["user_id"] || "",
    role: metadata?.claims["role"] || "",
  }
}

export const AuthContext = createContext<IAuthContextProps | null>(null)

export const AuthProvider = ({
  value,
  children,
}: {
  value: IAuthContextValue
  children: React.ReactNode
}) => {
  //here value is destructured just to match the structure of previous setup
  const { state, dispatch } = value
  const [agentInfo, setAgentInfo] = useState(null)

  useEffect(() => {
    if (state?.user != null) {
      getMemberByUid({ queryKey: ["getMemberByUid", state?.user?.uid] })
        .then((res) => {
          setAgentInfo(res?.data?.agent)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    }
  }, [state?.user])

  return (
    <AuthContext.Provider value={{ value, state, dispatch, agentInfo }}>
      {children}
    </AuthContext.Provider>
  )
}
