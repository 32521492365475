import { Tag } from "antd"
import React from "react"
import styled from "styled-components"
import { t } from "i18next"

interface iJobOfferPointBlock {
  headingOne?: string
  headingTwo?: string
  bodyOne?: any
  bodyTwo?: any
  tags?: string[]
  isCenter?: boolean
  hideFull?: boolean
  hideRight?: boolean
  hideLeft?: boolean
  subHeadingOne?: string
  subHeadingTwo?: string
  subHeadingThree?: string
  bodyThree?: string
}

interface BodyProps {
  isCenter?: boolean
}

const Wrapper = styled.div`
  word-break: break-all;
  margin-bottom: 12px;
  width: 100%;
  padding: 10px;
  .head {
    height: 30px;
    display: flex;

    align-items: center;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    background-color: #f0f2f5;
    color: #000;
    padding-left: 10px;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
    .head-left {
      width: 100%;
    }
    .head-right {
      width: 100%;
    }
  }
  .sub-title {
    font-weight: bold;
  }
  .body {
    display: flex;
    width: 100%;
    font-size: 12px;
    gap: 2rem;
    .body-right {
      width: 50%;
      white-space: pre-wrap;
    }
  }
`
const BodyLeft = styled.div<BodyProps>`
  width: 50%;
  white-space: pre-wrap;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  text-align: ${({ isCenter }) => (isCenter ? "center" : null)};
  margin-left: ${({ isCenter }) => (isCenter ? "10%" : null)};

  .tags {
    margin-bottom: 10px;
    word-break: break-all;
    .ant-tag {
      font-size: 10px;
      word-break: break-all;
    }
  }
`
/* eslint-disable */
const Linkify = ({ children }) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g
  if (children[1]) {
    const words = children[1]?.split(urlPattern)
    const formattedChat = words?.map((word, index) => {
      if (word.match(urlPattern)) {
        // If the word is a URL, convert it to a clickable link
        return (
          <a key={index} href={word} target="_blank">
            {word}
          </a>
        )
      } else {
        return <span key={index}>{word}</span>
      }
    })
    return formattedChat
  }
  return children
}
const JobOfferPointBlock: React.FC<iJobOfferPointBlock> = ({
  headingOne,
  headingTwo,
  subHeadingOne,
  subHeadingTwo,
  subHeadingThree,
  bodyOne,
  bodyTwo,
  bodyThree,
  tags,
  isCenter,
  hideFull,
  hideLeft,
  hideRight,
}) => {
  return (
    <Wrapper data-html2canvas-ignore={hideFull}>
      <div className="head">
        <div className="head-left" data-html2canvas-ignore={hideLeft}>
          {headingOne}
        </div>
        {bodyTwo && (
          <div className="head-right" data-html2canvas-ignore={hideRight}>
            {headingTwo}
          </div>
        )}
      </div>
      <div className="body">
        {(bodyOne || tags) && (
          <BodyLeft
            style={!(bodyTwo || bodyThree) ? { width: "100%" } : {}}
            isCenter={isCenter}
            data-html2canvas-ignore={hideLeft}
          >
            <>
              {bodyOne && (
                <div
                  className="body-right sub-title"
                  data-html2canvas-ignore={hideRight}
                >
                  {subHeadingOne}
                </div>
              )}
              {tags && (
                <div className="tags">
                  {tags.map((item) => {
                    return (
                      <Tag key={item} color="cyan">
                        {t(item)}
                      </Tag>
                    )
                  })}
                </div>
              )}
              <Linkify> {bodyOne}</Linkify>
            </>
          </BodyLeft>
        )}
        {bodyTwo && (
          <div className="body-right" data-html2canvas-ignore={hideRight}>
            {subHeadingTwo && (
              <div className={"sub-title"} data-html2canvas-ignore={hideRight}>
                {subHeadingTwo}
              </div>
            )}
            <Linkify> {bodyTwo}</Linkify>
          </div>
        )}
        {bodyThree && (
          <div className="body-right" data-html2canvas-ignore={hideRight}>
            {subHeadingTwo && (
              <div className={"sub-title"} data-html2canvas-ignore={hideRight}>
                {subHeadingThree}
              </div>
            )}
            <Linkify> {bodyThree}</Linkify>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default JobOfferPointBlock
