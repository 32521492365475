import {
  ClockCircleOutlined,
  DollarCircleOutlined,
  DotChartOutlined,
  GlobalOutlined,
  InboxOutlined,
  SolutionOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { convertGenderStringInverse } from "../../../../utility"
import { t } from "i18next"
import moment from "moment"

interface TableSectionProps {
  icon?: any
  label: string
  value: string
  hideInPDF?: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 6px;
  margin-bottom: 14px;
  border: 1px solid #ececec;
  border-radius: 4px;
  margin-left: 16px;

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3.5rem;
    margin-left: 10px;
    margin-bottom: 4px;

    .icon-label {
      display: flex;
      align-items: center;
      gap: 5px;
      width: 28%;

      .label {
        font-weight: bold;
        font-size: 12px;
        color: "#1990FF" !important;
      }
    }
    .value {
      width: 50%;
      font-size: 12px;
      overflow: auto;
      word-wrap: break-word;
    }
  }
`
const TableSection = ({ icon, label, value, hideInPDF }: TableSectionProps) => {
  return (
    <div className="item" data-html2canvas-ignore={hideInPDF}>
      <div className="icon-label">
        <div className="icon">{icon}</div>
        <div className="label">{label}</div>
      </div>
      <div className="value">{value}</div>
    </div>
  )
}

export const ArrayTranslate = (industryType: string[]) => {
  if (industryType?.length) {
    let translatedvalue = ""
    industryType.map((value, index) => {
      translatedvalue +=
        t(value) + (industryType?.length - 1 !== index ? ", " : "")
    })
    return translatedvalue
  }
  return undefined
}

const TopSectionCard = ({ job }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <TableSection
        icon={
          <DotChartOutlined
            style={{
              color: "purple",
            }}
          />
        }
        label={t("Industry")}
        value={
          job?.industry_type_new?.length || job?.industry_type?.length
            ? ArrayTranslate(
                job?.industry_type_new?.length
                  ? job?.industry_type_new
                  : job?.industry_type
              )
            : "-"
        }
      />
      <TableSection
        icon={
          <DollarCircleOutlined
            style={{
              color: "magenta",
            }}
          />
        }
        label={t("Annual Income")}
        value={`${job?.annual_income?.from}万円〜${job?.annual_income?.to}万円`}
      />
      <TableSection
        icon={
          <GlobalOutlined
            style={{
              color: "green",
            }}
          />
        }
        label={t("Area")}
        value={job?.areas.toString() || "-"}
      />
      <TableSection
        icon={<SolutionOutlined />}
        label={t("Education")}
        value={job?.education_history}
      />
      <div data-html2canvas-ignore={true}>
        <TableSection
          icon={<TagOutlined />}
          label={t("Age")}
          value={`${job?.age?.from}歳〜${job?.age?.to}歳`}
        />
      </div>
      <TableSection
        icon={<InboxOutlined />}
        label={t("Contract type")}
        value={job?.contract_type}
      />
      <div data-html2canvas-ignore={true}>
        <TableSection
          icon={<UserOutlined />}
          label={t("Desired gender")}
          value={convertGenderStringInverse(job?.job_overview?.desired_gender)}
        />
      </div>
      <TableSection
        icon={<ClockCircleOutlined />}
        label={t("Work time")}
        value={
          job?.job_overview?.work_time?.from && job?.job_overview?.work_time?.to
            ? `${moment(job?.job_overview?.work_time?.from).format(
                "HH:mm"
              )}-${moment(job?.job_overview?.work_time?.to).format("HH:mm")}`
            : "-"
        }
      />
    </Wrapper>
  )
}

export { TopSectionCard }
