import React, { useEffect, useState } from "react"
import { Upload, message, notification, Spin, Tooltip } from "antd"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { fileUpload } from "../../../../services/fileupload"
import {
  DeleteOutlined,
  CloseCircleFilled,
  LinkOutlined,
} from "@ant-design/icons"
import { clearMetaFromPDF } from "../../../utility"

interface ImageUploadProps {
  onChange?: (fileName?: any, value?: any) => void
  fireStoreFile: any
  updateFileToFireStore: any
  sent: boolean
  disabled?: boolean
  updateSent: (isSent: boolean) => void
}

const Wrapper = styled.div`
  margin: 10px 0;
  .anticon-link {
    border-radius: 50%;
    background-color: #e3e3e3;
    cursor: pointer;
    padding: 10px;
  }
  .ant-spin {
    width: 34px;
    height: 34px;
    padding-top: 6px;
  }
  .delete-btn-wrapper {
    display: flex;
    align-items: center;
    .anticon-close {
      padding: 10px;
      background-color: #f5222d;
      color: #fff;
      border-radius: 50%;
    }
    p {
      margin-bottom: 0;
      margin-left: 12px;
    }
  }
  .delete-btn-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    margin-left: 2px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    outline: none;
    border: none;
    cursor: pointer;
    & svg {
      fill: #cf1322;
    }
  }
`

const JobChatFile: React.FC<ImageUploadProps> = ({
  onChange,
  fireStoreFile,
  updateFileToFireStore,
  sent,
  updateSent,
  disabled,
}) => {
  const [file, setFile] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (sent) {
      setFile(null)
      updateSent(false)
    }
  }, [sent])

  const { mutate, isLoading: uploadLoading } = useMutation(
    "fileUpload",
    fileUpload,
    {
      onSuccess: (result) => {
        onChange && onChange(null, result?.data)
        setFile({
          url: result?.data,
        })
        updateFileToFireStore({ ...fireStoreFile, url: result?.data })
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg ? t(msg) : t("An error has occurred"),
        })
      },
    }
  )

  const handleDeleteClick = () => {
    updateFileToFireStore({})
    setFile(null)
    onChange && onChange(null, null)
  }

  const beforeUpload = (file) => {
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png"
    ];
    const isAllowedType = allowedTypes.includes(file.type);
    const isMax10M = file.size / 1024 / 1024 <= 10;

    if (!isAllowedType) {
      message.error({
        content: t("Please upload a valid file type"),
        key: "fileType",
        icon: <CloseCircleFilled onClick={() => message.destroy("fileType")} />,
      });
    }
    if (!isMax10M) {
      message.error({
        content: t("File must be smaller than 10 MB"),
        key: "fileSize",
        icon: <CloseCircleFilled onClick={() => message.destroy("fileSize")} />,
      });
    }
    return isAllowedType && isMax10M;
  }

  const handleChange = async (info) => {
    if (disabled) return
    updateFileToFireStore({ ...fireStoreFile, name: info?.file?.name })
    if (info.file.status === "done") {
      const file = await clearMetaFromPDF(info?.file?.originFileObj)
      const bodyFormData = new FormData()
      bodyFormData.append("file", file)
      bodyFormData.append("model", "temp")
      mutate(bodyFormData)
    }
  }

  return (
    <Wrapper>
      {!file &&
        (uploadLoading ? (
          <Spin />
        ) : (
          <Tooltip
            title={t("You can only upload valid file type up to 10MB")}
            placement="bottomLeft"
          >
            <Upload
              disabled={disabled}
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/jpeg, image/png"
            >
              <LinkOutlined />
            </Upload>
          </Tooltip>
        ))}
      {file && (
        <div className="delete-btn-wrapper">
          <Tooltip title={t("Delete uploaded file")} placement="bottomLeft">
            <div className="delete-btn-pdf" onClick={handleDeleteClick}>
              <DeleteOutlined />
            </div>
          </Tooltip>
          <p>{fireStoreFile?.name}</p>
        </div>
      )}
    </Wrapper>
  )
}

export { JobChatFile }
