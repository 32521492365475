import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FormOutlined,
  QuestionCircleOutlined,
  SnippetsOutlined,
} from "@ant-design/icons"
import { handlingChargeOptions } from "../constants"

export const getTagColor = (code: string) => {
  switch (code) {
    case "draft":
      return "lime"
    case "submitted":
      return "cyan"
    case "returned":
      return "red"
    case "approved":
      return "green"
    case "end_job_request":
      return "cyan"
    case "ended":
      return "red"
    default:
      return "white"
  }
}
export const getTagIcon = (code: string) => {
  switch (code) {
    case "draft":
      return <SnippetsOutlined />
    case "submitted":
      return <QuestionCircleOutlined />
    case "end_job_request":
      return <QuestionCircleOutlined />
    case "returned":
      return <CloseCircleOutlined />
    case "ended":
      return <CloseCircleOutlined />
    case "approved":
      return <CheckCircleOutlined />
    default:
      return <FormOutlined />
  }
}

export const getBadgeValue = (job) => {
  let result
  if (job?.job_type == "shared-job") {
    if (job?.referral?.handling_charge?.type == "理論年収の") {
      const rate =
        (job?.referral?.handling_charge?.value2 / 100) *
        (job?.referral?.handling_charge?.value / 100)

      result = (job?.referral?.handling_charge?.value / 100 - rate) * 100
      result = Math.floor(result)
      result = `${job?.referral?.handling_charge?.type}${result}%`
    } else {
      result =
        job?.referral?.handling_charge?.value -
        job?.referral?.handling_charge?.value2

      result = `${job?.referral?.handling_charge?.type}${result}万円`
    }
  } else {
    if (
      job?.referral?.handling_charge?.type === handlingChargeOptions[0]?.value
    ) {
      return `${job?.referral?.handling_charge?.type}${job?.referral?.handling_charge?.value}%`
    } else if (
      job?.referral?.handling_charge?.type === handlingChargeOptions[1]?.value
    ) {
      return `${job?.referral?.handling_charge?.type}${job?.referral?.handling_charge?.value}万円`
    }
  }
  return result
}
