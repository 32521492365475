import React, { useEffect, useState } from "react"
import styled from "styled-components"

import {
  ConfigProvider,
  Pagination,
  Table,
  Result,
  notification,
  Popconfirm,
} from "antd"
import jaJP from "antd/lib/locale/ja_JP"
import { useTranslation } from "react-i18next"

import { i18n } from "../../../i18n"
import { theme } from "../../../theme"
import { SearchBar, Select } from "../../atom"
import { fetchJobSeeker, referJobSeeker } from "../../../../services/jobseeker"
import { useDebounce } from "../UseDebounce"
import { useMutation, useQuery } from "react-query"
import { fetchMember } from "../../../../services/member"
import dynamic from "next/dynamic"

const ButtonComponent = dynamic(
  () => import("../../atom/Button/index").then((mod) => mod.ButtonComponent),
  { ssr: false }
)

const Container = styled.div``

const PageTop = styled.div`
  display: flex;
  gap: 12px;
  & .ant-btn--light {
    border: 1px solid ${theme.borderColorBase};
  }
  & .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    padding-top: 9px;
  }
  & .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 5px !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px !important;
  }
`

const TableWrapper = styled.div`
  margin-top: 26px;
`

const PaginationWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  height: 120px;
`

interface IJobSeekersProps {
  jobID: string
  isAgentAdmin?: boolean
  loginId?: string | null
}

const JobSeekers: React.FC<IJobSeekersProps> = ({
  jobID,
  isAgentAdmin,
  loginId,
}) => {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState("")
  const debouncedSearch = useDebounce(searchQuery, 400)

  const [applicantQueryParams, setApplicantQueryParams] = useState({
    page: 1,
    size: 10,
    keyword: debouncedSearch,
    job_id: jobID,
    agentMemberId: isAgentAdmin ? loginId : null,
    all: isAgentAdmin ? 1 : 0,
  })

  const { mutate, isLoading: isLoadingReferJobSeeker } = useMutation(
    referJobSeeker,
    {
      onSuccess: () => {
        notification.success({
          message: t("Applicant referred successfully"),
        })
        refetch()
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )

  const {
    isLoading,
    isFetching,
    isError,
    data: searchData,
    refetch,
  } = useQuery(["fetchjobseeker", applicantQueryParams], fetchJobSeeker, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    select: (response) => {
      return {
        data:
          response?.data?.map((data, index) => {
            return {
              key: index,
              id: data.id,
              name: data.name || "-",
              email_address: data.email || "-",
              current_occupation_type: data.current_occupation_type || "-",
              expected_occupation_type: data.expected_occupation_type || "-",
              experienced_industry_new: data?.experienced_industry_new || [],
              desired_industry_new: data?.desired_industry_new || [],
              experienced_occupation_new:
                data?.experienced_occupation_new || [],
              desired_occupation_new: data?.desired_occupation_new || [],
              desired_prefecture: data?.desired_prefecture || [],
              date_of_birth: data?.date_of_birth || null,
              gender: data?.gender || null,
              email: data?.email || "",
              resume: data?.resume || "",
              work_history: data?.work_history || "",
              job_summary: data?.job_summary || "",
              free_entry: data?.free_entry || "",
              agent_comment: data?.agent_comment || "",
              typical_format: data?.typical_format || {},
            }
          }) || [],
        count: response?.count || 0,
      }
    },
  })
  const handleMemberChange = (_, option) => {
    setApplicantQueryParams((prev) => {
      if (option?.label === "All") {
        return {
          ...prev,
          page: 1,
          all: option.value,
          agentMemberId: null,
        }
      }
      return {
        ...prev,
        page: 1,
        agentMemberId: option?.value,
        all: 0,
      }
    })
  }

  const {
    data: memberData,
    isLoading: memberLoading,
    isFetching: memberFetching,
  } = useQuery(["fetchMembers", { size: Infinity }], fetchMember, {
    enabled: !!isAgentAdmin,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    select: ({ data }) => {
      return [
        ...data.map((resdata) => {
          return {
            label: resdata.name,
            value: resdata.id,
          }
        }),
        {
          label: "All",
          value: 1,
        },
      ]
    },
  })

  useEffect(() => {
    setApplicantQueryParams((prev) => {
      return {
        ...prev,
        page: debouncedSearch ? 1 : prev.page,
        keyword: debouncedSearch,
      }
    })
  }, [debouncedSearch])

  const getSearchData = async () => {
    refetch()
  }

  const handleSearchChange = (e) => {
    setSearchQuery(e.target?.value)
  }

  const referApplicant = (id: string, values: any) => {
    //checking for condition if Workplace(desired industry), experience occupation,desired occupation, age,gender,resume,agent recommendations etc are not empty
    if (
      values?.desired_prefecture?.length > 0 &&
      values?.experienced_industry_new?.length > 0 &&
      values?.desired_industry_new?.length > 0 &&
      values?.experienced_occupation_new?.length > 0 &&
      values?.desired_occupation_new?.length > 0 &&
      values?.date_of_birth !== null &&
      values?.resume !== "" &&
      values?.work_history !== "" &&
      values?.email !== "" &&
      values?.gender !== null &&
      values?.job_summary !== "" &&
      values?.agent_comment !== "" &&
      ((values?.typical_format?.career_background !== "" &&
        values?.typical_format?.guessing_point !== "" &&
        values?.typical_format?.summary_of_duties !== "") ||
        values?.free_entry !== "")
    ) {
      mutate({
        job_id: jobID,
        applicant_id: id,
      })
    } else {
      notification.error({
        message: (
          <div>
            求人に応募するには求職者の下記情報の登録が必要です。
            <br />
            年齢、性別、メールアドレス、 経験/希望業種、経験/希望職種、
            希望勤務地、履歴書、職務経歴書、担当Agent推薦文、職務要約、
            エージェントからの一言
          </div>
        ),
      })
    }
  }

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      width: "200px",
      key: "name",
    },

    {
      title: t("Email address"),
      dataIndex: "email_address",
      key: "email_address",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
    },
    {
      title: t("Current occupation type"),
      dataIndex: "current_occupation_type",
      key: "current_occupation_type",
    },
    {
      title: t("Expected occupation type"),
      dataIndex: "expected_occupation_type",
      key: "expected_occupation_type",
    },
    {
      title: t("Action"),
      dataIndex: "id",
      key: "action",
      render: (id, items) => {
        return (
          <Popconfirm
            okButtonProps={{ loading: isLoadingReferJobSeeker }}
            cancelText={t("No")}
            okText={t("Yes")}
            title={t("Are you sure to refer this job?")}
            onConfirm={() => referApplicant(id, items)}
          >
            <ButtonComponent>{t("Refer")}</ButtonComponent>
          </Popconfirm>
        )
      },
    },
  ]

  const handlePageSizeChange = (_current, size) => {
    setApplicantQueryParams((prev) => {
      return {
        ...prev,
        size,
      }
    })
  }

  const handlePageChange = (page) => {
    setApplicantQueryParams((prev) => {
      return {
        ...prev,
        page,
      }
    })
  }

  if (isError) {
    return <Result status="error" title={t("Error Occurred")} />
  }

  return (
    <>
      <Container>
        <PageTop>
          <SearchBar
            placeholder={`${t("Search by name or email address")}`}
            value={searchQuery}
            width={280}
            onSearch={getSearchData}
            onChange={handleSearchChange}
          />
          {isAgentAdmin && (
            <Select
              className="member-select"
              showSearch
              allowClear
              placeholder={t("Person in charge")}
              options={memberData}
              width={250}
              height={50}
              value={
                applicantQueryParams.agentMemberId ||
                (applicantQueryParams.all ? t("All") : null)
              }
              onChange={handleMemberChange}
              loading={memberLoading || memberFetching}
            />
          )}
        </PageTop>
        <TableWrapper>
          <ConfigProvider locale={i18n.language === "ja" && jaJP}>
            <Table
              loading={isLoading || isFetching || isLoadingReferJobSeeker}
              dataSource={searchData?.data || []}
              columns={columns}
              pagination={false}
              scroll={{ y: "calc(100vh - 450px)" }}
            />
          </ConfigProvider>
        </TableWrapper>
        <PaginationWrapper>
          <ConfigProvider locale={i18n.language === "ja" && jaJP}>
            <Pagination
              current={applicantQueryParams.page}
              total={searchData?.count}
              hideOnSinglePage={true}
              showSizeChanger={false}
              pageSize={applicantQueryParams.size}
              onChange={handlePageChange}
              onShowSizeChange={handlePageSizeChange}
              showTotal={(total, range) =>
                `${t("paginationKey", {
                  range: `${range[0]}-${range[1]}`,
                  total: `${total}`,
                })}`
              }
            />
          </ConfigProvider>
        </PaginationWrapper>
      </Container>
    </>
  )
}
export { JobSeekers }
