import { Checkbox } from "antd"
import React from "react"

export interface CheckboxOptions {
  label: string
  value: string
}

export interface CheckboxProps {
  name?: string
  onChange?: (e: any) => void
  options?: CheckboxOptions[]
  value?: string
  label?: string
  error?: string
}
const Checkboxes: React.FC<CheckboxProps> = ({
  name,
  onChange,
  options,
  error,
  label,
  value,
}: CheckboxProps) => {
  const checkboxes = []
  for (let id = 0; id < options.length; id++) {
    checkboxes.push(
      <Checkbox key={options[id].value} value={options[id].value}>
        {options[id].label}
      </Checkbox>
    )
  }

  return (
    <div className="checkbox-group__wrapper">
      {label && <p className="label"> {label} </p>}
      <div className="checkbox-group">
        <Checkbox.Group
          name={name}
          onChange={onChange}
          value={typeof value === "string" ? value.split(",") : value}
        >
          {checkboxes}
        </Checkbox.Group>
        {error && <p>{error}</p>}
      </div>
    </div>
  )
}

export { Checkboxes }
