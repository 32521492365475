import React from "react"
import styled from "styled-components"
import { Steps } from "antd"

const Wrapper = styled.div`
  font-weight: 400;
  .image-tag {
    &:last-child {
      display: none;
    }
  }
`

const SelectionFlowStatus = [
  "適性検査",
  "書類",
  "1次面接",
  "2次面接",
  "3次面接",
  "最終面接",
]

const SelectionFlow = ({ data }) => {
  const { Step } = Steps

  return (
    <Wrapper>
      <Steps direction="vertical" progressDot current={data.length}>
        {SelectionFlowStatus.map((item, index) => {
          if (data.includes(item)) {
            return <Step title={item} key={index} />
          }
        })}
      </Steps>
    </Wrapper>
  )
}

export { SelectionFlow }
