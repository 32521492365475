import React from "react"
import { View, Text, StyleSheet } from "@react-pdf/renderer"
import { ArrayTranslate } from "../../src/components/molecules/JobDetail/TopSectionCard"
import { t } from "i18next"
import moment from "moment"

const PdfTopCardSection = ({ jobData }: any) => {
  return (
    <View style={styles.wrapper}>
      <Text style={styles.label}>
        {t("Industry")}
        {":   "}
        <Text style={styles.value}>
          {jobData?.industry_type_new?.length || jobData?.industry_type?.length
            ? ArrayTranslate(
                jobData?.industry_type_new?.length
                  ? jobData?.industry_type_new
                  : jobData?.industry_type
              )
            : "-"}
        </Text>
      </Text>
      <Text style={styles.label}>
        {t("Annual Income")}
        {":   "}
        <Text style={styles.value}>
          {`${jobData?.annual_income?.from}万円〜${jobData?.annual_income?.to}万円`}
        </Text>
      </Text>
      <Text style={styles.label}>
        {t("Area")}
        {":   "}
        <Text style={styles.value}>{jobData?.areas?.toString() || "-"}</Text>
      </Text>

      <Text style={styles.label}>
        {t("Education")}
        {":   "}
        <Text style={styles.value}>{jobData?.education_history}</Text>
      </Text>

      <Text style={styles.label}>
        {t("Contract type")}
        {":   "}
        <Text style={styles.value}>{jobData?.contract_type}</Text>
      </Text>

      <Text style={styles.label}>
        {t("Work time")}
        {":   "}
        <Text style={styles.value}>
          {jobData?.job_overview?.work_time?.from &&
          jobData?.job_overview?.work_time?.to
            ? `${moment(jobData?.job_overview?.work_time?.from).format(
                "HH:mm"
              )}-${moment(jobData?.job_overview?.work_time?.to).format(
                "HH:mm"
              )}`
            : "-"}
        </Text>
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "8pt 6pt",
    marginBottom: "14pt",
    border: "1pt solid #ececec",
    borderRadius: "4pt",
    marginLeft: "16pt",
    width: "320px",
  },
  item: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "3.5rem",
    marginLeft: "10pt",
    marginBottom: "4pt",
  },

  iconLabel: {
    display: "flex",
    alignItems: "center",
    gap: "5pt",
    width: "28%",
  },
  label: {
    fontWeight: 800,
    fontSize: "8pt",
  },
  value: {
    fontWeight: 400,
    width: "50%",
    fontSize: "8pt",
    wordWrap: "break-word",
    paddingLeft: "10px",
  },
})
export { PdfTopCardSection }
