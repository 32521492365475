const checkSenderType = (
  idWithNames: any,
  senderType: string,
  senderId: string
) => {
  if (idWithNames?.[senderId]?.["type"] == senderType) {
    return true
  }
  return false
}

export default checkSenderType
