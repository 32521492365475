import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { SectionTitle } from "../../../atom/JobDetail"
import { TextContainer } from "../TextContainer"

const Wrapper = styled.div`
  border: 2px solid #182a4b;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
`

const InformationToAgent: React.FC<any> = ({ job }) => {
  const jobReferal = job?.referral

  const { t } = useTranslation()
  if (jobReferal) {
    return (
      <Wrapper data-html2canvas-ignore={true}>
        <SectionTitle title={t("Information to agent")} />
        <TextContainer
          title={t("Reward Points")}
          content={jobReferal.reward_points}
        />
        <TextContainer
          title={t("Payment time")}
          content={jobReferal.payment_time}
        />
        <TextContainer
          title="Refund policy"
          content={jobReferal.refund_policy}
        />
        <TextContainer
          title="Explain annual income"
          content={jobReferal.annual_income}
        />
      </Wrapper>
    )
  }
  return null
}

export { InformationToAgent }
