import { Modal } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import dynamic from "next/dynamic"

const Button = dynamic(
  () => import("../../atom/Button/index").then((mod) => mod.ButtonComponent),
  { ssr: false }
)
interface Props {
  body: string
  title: string
  isVisible: boolean
  onClose: () => void
}

const StyledWrapper = styled.div`
  .box-content {
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
  }
`

/* eslint-disable */
const Linkify = ({ children }) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g
  if (children[1]) {
    const words = children[1]?.split(urlPattern)
    const formattedChat = words?.map((word, index) => {
      if (word.match(urlPattern)) {
        // If the word is a URL, convert it to a clickable link
        return (
          <a key={index} href={word} target="_blank">
            {word}
          </a>
        )
      } else {
        return <span key={index}>{word}</span>
      }
    })
    return formattedChat
  }
  return children
}

const JobDetailModal: React.FC<Props> = ({
  body,
  title,
  isVisible,
  onClose,
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      title={title}
      visible={isVisible}
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button key="1" onClick={onClose}>
          {t("Close")}
        </Button>,
      ]}
    >
      <StyledWrapper>
        <p className="box-content">
          <Linkify> {body}</Linkify>
        </p>
      </StyledWrapper>
    </Modal>
  )
}

export { JobDetailModal }
