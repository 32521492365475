import { API } from "@project/shared"
export interface IReturnMemberList {
  count?: number
  data?: any
}

interface ISearchMemberQuery {
  page?: string
  size?: string
  keyword?: string
  exclude_admin?: number
}

export const fetchMember = async ({ queryKey }): Promise<IReturnMemberList> => {
  const params = {} as ISearchMemberQuery
  params.page = queryKey[1].page
  if (queryKey[1].pageSize) {
    params.size = queryKey[1].pageSize
  }
  params.keyword = queryKey[1].keyword
  params.exclude_admin = queryKey[1].excludeAdmin
  return API.get(`/agent-members`, { params })
}

export const fetchAllMember = async (): Promise<IReturnMemberList> => {
  return API.get(`/agent-members-all`)
}

export const getMember = async (id) => {
  return API.get(`/agent-members/${id}`)
}

export const getMemberByUid = async ({ queryKey }) => {
  return API.get(`/agent-members/${queryKey[1]}`, {
    params: { is_fb_uid: true },
  })
}

export const addMember = async (values): Promise<any> => {
  values.reward_amount = Number(values.reward_amount)
  return API.post(`/agent-members`, values)
}

export const updateMember = async (values) => {
  const id = values.id
  values.reward_amount = Number(values.reward_amount)
  return API.put(`/agent-members/${id}`, values)
}

export const deleteMember = async (id) => {
  return API.delete(`/agent-members/${id}`)
}
