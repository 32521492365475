import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Badge } from "antd"

import {
  JobOfferPoint,
  OtherJobSection,
  TopSection,
} from "../../molecules/JobDetail"

import { useQuery } from "react-query"
import { getJobStatusCount } from "../../../../services/jobStatusCount"
import { jobStatusListGraph } from "../../../constants"
import { getBadgeValue } from "../../../utility"

interface iJobDetailCard {
  jobData: any
  isCompany?: boolean
  isAgent?: boolean
  otherJobs?: any
  mutation?: any
  id?: string
  setPageSize?: any
  pageSize?: number
  isJobLoading?: boolean
  isJobFetching?: boolean
  isAgentAdmin?: boolean
  showRibbon?: boolean
}
const Container = styled.div`
  .ant-ribbon.ant-ribbon-placement-end {
    right: 12px;
    top: -10px;
    background-color: #6b0f1a;
    background-image: linear-gradient(315deg, #6b0f1a 0%, #b91372 74%);
  }
  .ant-ribbon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
  }
`

const Wrapper = styled.div`
  margin: 1.5rem;
  padding: 16px 25px;
  background-color: #fff;
`

const JobDetailCard: React.FC<iJobDetailCard> = ({
  jobData,
  isCompany,
  isAgent,
  mutation,
  otherJobs,
  setPageSize,
  pageSize,
  isJobLoading,
  isJobFetching,
  isAgentAdmin,
  showRibbon,
}) => {
  const [dashboardMenu, setDashboardMenu] = useState("")

  const job_data = jobData?.data
  const id = job_data?.job?.id || job_data?.id

  const { data: jobStatusCount } = useQuery<any, Error>(
    ["getJobStatusCount", id],
    () => getJobStatusCount(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: (data) => {
        const statusList = data?.data?.status_list
        const result = {}
        if (statusList.length > 0) {
          for (let i = 0; i < statusList.length; i++) {
            result[statusList[i].status] = statusList[i].count
          }
          jobStatusListGraph.forEach((val) => {
            if (!result[val]) result[val] = 0
          })
        }
        return result
      },
    }
  )

  useEffect(() => {
    setDashboardMenu(localStorage.getItem("dashboard"))
    return () => {
      setDashboardMenu("")
    }
  }, [])
  const jobOfferPoint = {
    agent_company_id: "",
    approval_status: "",
    one_phrase: "",
    areas: [],
    industry_type: [],
    occupation_type: [],
    image: "",
    occupation_type_new: [],
    industry_type_new: [],
    annual_income: {},
    education_history: "",
    age: {},
    contract_type: "",
    job_overview: {
      business_details: "",
      hiring_background: "",
      no_of_hire_per_period: {
        number: 0,
        period: 0,
        unit: "",
      },
      work_details: "",
      work_time: {
        from: "",
        to: "",
      },
      holidays_and_welfares: "",
      interview_image: "",
      interview: "",
      looking_for_people_like_this: "",
      desired_gender: "",
      recruiter_comment: "",
    },
    job_offer_point: {
      qualification_requirement: {
        info: "",
        personality: "",
        perspective: "",
        skill: "",
        experience: "",
        workstyle: "",
        others: "",
      },
      message_from_hr: {
        info: "",
        document_screening: "",
        important_items: [],
        interview_rejection: "",
        interview_preparation: "",
        reason_of_rejection: "",
        selection_flow: [],
        career_plan: "",
      },
      informal_information: "",
      point_of_recommendation: "",
    },
    topic: {
      recruitment_features: [],
      workplace_features: [],
      work_features: [],
      company_features: [],
      characteristics: [],
      thoughtfulness: [],
      company_type: [],
      working_type: [],
    },
    interview: {
      first_interview: {
        question: "",
        selection_point: "",
        ng_reason: "",
      },
      second_interview: {
        question: "",
        selection_point: "",
        ng_reason: "",
      },
      final_interview: {
        question: "",
        selection_point: "",
        ng_reason: "",
      },
      selection: {
        question: "",
        selection_point: "",
        ng_reason: "",
      },
      recuriter_comment: "",
      looking_for_people_like_this: "",
    },
  }
  const content = (
    <Wrapper id="main-wrapper">
      <TopSection
        job={jobData}
        // handlePDFDownload={handlePDFDownload}
        isAgent={isAgent}
        dashboardMenu={dashboardMenu}
        isAgentAdmin={isAgentAdmin}
        // isDownloading={isDownloading}
        isCompany={isCompany}
      />
      {(JSON.stringify(jobData?.data?.job_offer_point) !==
        JSON.stringify(jobOfferPoint) ||
        (jobStatusCount && Object?.keys(jobStatusCount).length > 0)) && (
        <JobOfferPoint
          jobData={job_data}
          isCompany={isCompany}
          //  jobStatusCount={jobStatusCount}
        />
      )}
      {/* {JSON.stringify(jobData?.data.job_offer_point) !==
        JSON.stringify(jobOfferPoint) && <JobOverview job={jobData} />} */}
      {otherJobs?.data.length > 0 && (
        <OtherJobSection
          data-html2canvas-ignore="true"
          jobs={otherJobs}
          mutation={mutation}
          setPageSize={setPageSize}
          isCompany={isCompany}
          pageSize={pageSize}
          isJobLoading={isJobLoading}
          isJobFetching={isJobFetching}
        />
      )}
    </Wrapper>
  )
  return (
    <Container>
      {showRibbon ? (
        <Badge.Ribbon color="red" text={getBadgeValue(job_data)}>
          {content}
        </Badge.Ribbon>
      ) : (
        content
      )}
    </Container>
  )
}

export { JobDetailCard }
