import React from "react"
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
  Font,
  Link,
} from "@react-pdf/renderer"
import { theme } from "../../src/theme"
import { addhttp } from "../addhttp"
import { ArrayTranslate } from "../../src/components/molecules/JobDetail/TopSectionCard"
import { t } from "i18next"
import {
  PdfJobOfferPoint,
  PdfTopCardSection,
  PdfTopSectionCardSecond,
} from "../PDFComponents"

const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size)
  const chunks = new Array(numChunks)

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size)
  }

  return chunks
}

interface PdfDownloadProps {
  jobData?: any
  signedObject?: any
  isCompany?: any
  interviewImageObject?: any
}

export const PdfDownload = ({
  jobData,
  signedObject,
  isCompany,
  interviewImageObject,
}: PdfDownloadProps) => {
  Font.register({
    family: "Noto Sans JP",
    src: "https://fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff",
  })
  Font.registerHyphenationCallback((word) => {
    if (word.length > 16) {
      return chunkSubstr(word, 14)
    }
    return [word]
  })
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.tags}>
          {jobData?.contract_type && (
            <Text style={styles.tagGreen}>{jobData?.contract_type}</Text>
          )}
        </View>
        <View style={styles.title}>
          <Text>
            {jobData?.agent_company?.name
              ? jobData?.agent_company.name
              : jobData?.company?.name}
            {` ${jobData?.one_phrase}`}
          </Text>
        </View>

        <View style={styles.companyUrl}>
          {jobData?.company?.url || jobData?.agent_company?.url ? (
            <Link
              style={styles.link}
              src={
                jobData?.company_id
                  ? addhttp(jobData?.company?.url)
                  : addhttp(jobData?.agent_company?.url)
              }
            >
              {jobData?.company_id
                ? jobData?.company?.url
                : jobData?.agent_company?.url}
            </Link>
          ) : null}
          {jobData?.industry_type?.length ||
          jobData?.industry_type_new?.length ? (
            <Text style={styles.industryType}>
              (
              {ArrayTranslate(
                jobData?.industry_type_new?.length
                  ? jobData?.industry_type_new
                  : jobData?.industry_type
              )}
              )
            </Text>
          ) : null}
        </View>
        <View style={styles.main}>
          <View style={styles.left}>
            <Image
              style={styles.img}
              src={signedObject || "/assets/icons/logo-job-placeholder.png"}
            />
            <View style={styles.jobDetail}>
              <Text style={styles.companyInfo}>
                {t("Recruiting company")}:
                {jobData?.company_id
                  ? jobData?.company?.name
                  : jobData?.agent_company?.agent?.company_name}
              </Text>
              {/* <Text style={styles.companyInfo}>
                求人最終更新日 :
                {moment(jobData?.updated_at).format("YYYY-MM-DD")}
              </Text> */}
            </View>
          </View>
          <View style={styles.right}>
            <PdfTopCardSection jobData={jobData} />
            <PdfTopSectionCardSecond jobData={jobData} />
          </View>
        </View>
        <PdfJobOfferPoint
          jobData={jobData}
          interviewImageObject={interviewImageObject}
          isCompany={isCompany}
        />
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Noto Sans JP",
    fontSize: 8,
    backgroundColor: "#fff",
    padding: "20pt",
  },
  tags: {
    paddingBottom: "6pt",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  tagPurple: {
    padding: "0 2pt",
    color: "#531dab",
    backgroundColor: "#f9f0ff",
    border: "1pt solid #d3adf7",
    borderRadius: "2pt",
    fontSize: 6,
  },
  tagGreen: {
    padding: "0 2pt",
    color: "#08979c",
    backgroundColor: "#e6fffb",
    border: "1pt solid #87e8de",
    borderRadius: "2pt",
    fontSize: 6,
  },
  title: {
    fontSize: "10pt",
    fontWeight: "bold",
    paddingRight: "10pt",
  },
  companyUrl: {
    flexDirection: "row",
    gap: "9pt",
    marginBottom: "15pt",
  },
  industryType: {
    color: "#7d857c",
  },
  main: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "20pt",
  },
  left: {
    flexDirection: "column",
    marginRight: "10pt",
  },
  img: {
    backgroundColor: "#f0f2f5",
    objectFit: "contain",
    objectPosition: "center",
    height: "150pt",
    width: "200pt",
  },
  jobDetail: {
    flexDirection: "column",
    marginTop: "10pt",
  },
  companyInfo: {
    marginBottom: "10pt",
    fontSize: 8,
  },
  link: {
    color: theme.blue2,
    textDecoration: "none",
  },
  right: {},
})
