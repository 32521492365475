import React from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { iJobSectionBlock } from "../../src/components/molecules/JobDetail/JobSectionBlock"

const styles = StyleSheet.create({
  wrapper: {
    marginLeft: 5,
    marginTop: 6,
    marginBottom: 6,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 4,
  },
  badge: {
    backgroundColor: "#182a4b",
  },
  title: {
    fontSize: 10,
    fontWeight: "bold",
    marginLeft: 10,
  },
  content: {
    fontSize: 8,
    wordBreak: "break-all",
    whiteSpace: "pre-wrap",
  },
  tags: {
    marginBottom: 10,
    marginTop: 5,
  },
  tag: {
    fontSize: 6,
    backgroundColor: "cyan",
    marginRight: 5,
    marginBottom: 5,
    padding: 2,
  },
})

const PdfJobSectionBlock: React.FC<iJobSectionBlock> = ({
  title,
  index,
  content,
  important_items,
}) => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.header}>
        <View style={styles.badge}>
          <Text>{index}</Text>
        </View>
        <Text style={styles.title}>{title}</Text>
      </View>
      {important_items && (
        <View style={styles.tags}>
          {important_items.map((item, itemIndex) => (
            <Text key={itemIndex} style={styles.tag}>
              {item}
            </Text>
          ))}
        </View>
      )}
      <Text style={styles.content}>{content}</Text>
    </View>
  )
}
export { PdfJobSectionBlock }
