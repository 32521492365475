import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { getSignedObjectUrl } from "../../../../utility"

const Wrapper = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-left: 8px;
`
const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }

  .label {
    width: 20%;
    font-weight: bold;
    font-size: 14px;
  }
  .value {
    width: 80%;
    display: flex;
    white-space: pre-wrap;
    word-wrap: wrap;
    font-size: 12px;
    word-break: break-all;
  }
`

const Block = ({ label, value, hideInPDF = false }) => {
  if (hideInPDF) {
    return (
      <Container data-html2canvas-ignore={true}>
        <div className="label">{label}</div>
        <div className="value">{value}</div>
      </Container>
    )
  }
  return (
    <Container>
      <div className="label">{label}</div>
      <div className="value">{value}</div>
    </Container>
  )
}

const ImageBlock = ({ value }) => {
  return (
    <Container>
      <div className="label"></div>
      <div className="value">
        {value ? (
          <img
            src={value}
            height={250}
            width={309}
            alt="job-interview-image"
            style={{ marginBottom: "12px" }}
          />
        ) : (
          ""
        )}
      </div>
    </Container>
  )
}

const JobDetailTextBlock = ({ job }) => {
  const { t } = useTranslation()
  const jobData = job?.data
  const [signedObject, setSignedObject] = useState("")
  useEffect(() => {
    if (jobData?.job_overview?.interview_image) {
      ;(async () => {
        const imageUrl = await getSignedObjectUrl(
          jobData?.job_overview?.interview_image
        )
        setSignedObject(imageUrl)
      })()
    }
  }, [jobData])
  return (
    <Wrapper>
      <Block
        label={t("Business Detail")}
        value={jobData?.job_overview?.business_details}
      />
      <Block label={t("Interview")} value={jobData?.job_overview?.interview} />
      <ImageBlock value={signedObject} />
      <Block
        label={t("Hiring Background")}
        value={jobData?.job_overview?.hiring_background}
      />
      <Block
        hideInPDF={true}
        label={t("No. of hires")}
        value={`${jobData?.job_overview?.no_of_hire_per_period.number} 人`}
      />
      <Block
        hideInPDF={true}
        label={t("Hiring period")}
        value={`${jobData?.job_overview?.no_of_hire_per_period.period} ${
          jobData?.job_overview?.no_of_hire_per_period.unit === "Month"
            ? "ヶ月"
            : "年"
        } `}
      />
      <Block
        label={t("Work details")}
        value={jobData?.job_overview?.work_details}
      />
      <Block
        label={t("Work time")}
        value={
          jobData?.job_overview?.work_time?.from
            ? `${moment(jobData?.job_overview?.work_time?.from).format(
                "HH:mm"
              )} 〜 ${moment(jobData?.job_overview?.work_time?.to).format(
                "HH:mm"
              )}`
            : "-"
        }
      />
      <Block
        label={t("Holidays and welfares")}
        value={jobData?.job_overview?.holidays_and_welfares}
      />
      <Block
        label={t("Looking for people like this")}
        value={jobData?.job_overview?.looking_for_people_like_this}
        hideInPDF={true}
      />
      <Block
        label={t("Recruiter Comment")}
        value={jobData?.job_overview?.recruiter_comment}
        hideInPDF={true}
      />
    </Wrapper>
  )
}

export { JobDetailTextBlock }
