import { API } from "../../api"

export const getUserInfo = async (userID: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const userType = userID.split("_")[0]
      const id = userID.replace(`${userType}_`, "")
      switch (userType) {
        case "agent": {
          const response = await API.get(`/agent-members/${id}`)
          if (response?.data) {
            resolve({
              id: `agent_${response?.data?.id}`,
              name: response?.data?.name,
              company_name: response?.data?.agent?.company_name,
              email: response?.data?.email,
              type: "agent",
              avatar: response?.data?.avatar || "",
            })
          } else {
            throw new Error("Error getting user info")
          }
          break
        }
        case "hiring-company": {
          const response = await API.get(`/companies/${id}`)
          if (response?.data) {
            resolve({
              id: `hiring-company_${response?.data?.id}`,
              name: response?.data?.name,
              email: response?.data?.email,
              type: "hiring-company",
              avatar: response?.data?.avatar || "",
              person_in_charge: response?.data?.person_in_charge || "",
            })
          } else {
            throw new Error("Error getting user info")
          }
          break
        }
        case "applicant": {
          const response = await API.get(`/applicants/${id}`)
          if (response?.data) {
            resolve({
              id: `applicant_${response?.data?.id}`,
              name: response?.data?.name,
              email: response?.data?.email,
              type: "applicant",
              avatar: response?.data?.avatar || "",
              status: response?.data?.status,
            })
          } else {
            throw new Error("Error getting user info")
          }
          break
        }
        default: {
          throw new Error("Invalid user type")
        }
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const getUserList = async (
  userType: string,
  idList: string[]
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      switch (userType) {
        case "hiring-company": {
          const response = await API.put(`/company-list/`, {
            list: idList,
          })
          if (response?.data) {
            resolve(response?.data)
          } else {
            throw new Error("Error getting user info")
          }
          break
        }
        case "applicant": {
          const response = await API.put(`/applicant-list/`, {
            list: idList,
          })
          if (response?.data) {
            resolve(response?.data)
          } else {
            throw new Error("Error getting user info")
          }
          break
        }
        default: {
          throw new Error("Invalid user type")
        }
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const sendNotificationEmail = async (user) => {
  if (user.type === "hiring-company") {
    const userId = user.id.split("hiring-company_")?.[1]
    return API.post(`/send-email-company/${userId}`)
  } else if (user.type === "applicant") {
    const userId = user.id.split("applicant_")?.[1]
    return API.post(`/send-email-applicant/${userId}`)
  }
}
